


















import Vue from "vue";
import Component from "vue-class-component";

@Component({
  name: "LadingVerify",
})
export default class LoadingVerify extends Vue {}
