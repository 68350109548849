





import { Component, Vue } from "vue-property-decorator";

@Component({
  name: "ForeignPassport",
})
export default class ForeignPassport extends Vue {}
