





import { Component, Vue, Prop } from "vue-property-decorator";

@Component({
  name: "ConsentInput",
})
export default class ConsentInput extends Vue {
  @Prop() value: boolean;

  @Prop() consentText: string;

  emitConsent(value: any) {
    this.$emit("input", value);
  }
}
