import { UIBlueprint, DynamicUIComponent } from "@frankieone/shared";
import { Context } from "./manual-blueprint";

export function mkGenericAddressBlueprint(context: Context): UIBlueprint {
  type MkInputComponent = { tag?: string; field: string; key: string };
  const { getPhrase, getBuffer, setBuffer, setCompleteAddress } = context;

  const address = getBuffer();

  const getLabelForField = (f: string, country: string | null): string => {
    const isMandatory = getRequiredForField(f, country);
    if (f === "unitNumber")
      return getPhrase("address_manual_input_screen.unit_label", {
        isMandatory,
      });
    if (f === "streetNumber")
      return getPhrase("address_manual_input_screen.street_number_label", {
        isMandatory,
      });
    if (f === "streetName")
      return getPhrase("address_manual_input_screen.street_name_label", {
        isMandatory,
      });
    if (f === "town")
      return getPhrase("address_manual_input_screen.town_label", {
        isMandatory,
      });
    if (f === "suburb")
      return getPhrase("address_manual_input_screen.suburb_label", {
        isMandatory,
      });
    if (f === "state")
      return getPhrase("address_manual_input_screen.state_label", {
        isMandatory,
      });
    if (f === "postalCode")
      return getPhrase("address_manual_input_screen.postcode_label", {
        isMandatory,
      });
    return "";
  };
  const getPlaceholderForField = (f: string): string => {
    if (f === "unitNumber")
      return getPhrase("address_manual_input_screen.unit_placeholder");
    if (f === "streetNumber")
      return getPhrase("address_manual_input_screen.street_number_placeholder");
    if (f === "streetName")
      return getPhrase("address_manual_input_screen.street_name_placeholder");
    if (f === "town")
      return getPhrase("address_manual_input_screen.town_placeholder");
    if (f === "suburb")
      return getPhrase("address_manual_input_screen.suburb_placeholder");
    if (f === "state")
      return getPhrase("address_manual_input_screen.state_placeholder");
    if (f === "postalCode")
      return getPhrase("address_manual_input_screen.postcode_placeholder");
    return "";
  };
  const getClassnameForField = (f: string): string => {
    if (f === "unitNumber") return "unit-number";
    if (f === "streetNumber") return "st-number";
    if (f === "streetName") return "st-name";
    if (f === "suburb") return "suburb";
    if (f === "state") return "state";
    if (f === "postalCode") return "postal-code";
    if (f === "town") return "town";
    return "";
  };
  const getAttrsForField = (f: string) => {
    if (f === "state") {
      return {
        dropUp: true,
        filterable: true,
        foreign: true,
        removeSpaces: true,
      };
    }
    if (f === "postalCode") {
      return {
        type: "tel",
        mask: "############",
      };
    }

    return {};
  };
  const getValueForField = (f: string): string => {
    return address[f] ?? "";
  };
  const setValueForField = (f: string, v: string) => {
    address[f] = v;
    setBuffer(address);
    if (!address.isIncomplete) setCompleteAddress(address);
  };
  const mkInputComponentWithKey = ({
    tag = "generic-input",
    field,
  }: MkInputComponent) => {
    return {
      [field]: new DynamicUIComponent({
        tag,
        classes: [getClassnameForField(field), "ff-input"],
        attrs: {
          label: getLabelForField(field, address.country),
          value: getValueForField(field),
          placeholder: getPlaceholderForField(field),
          tabindex: 0,
          ...getAttrsForField(field),
        },
        listeners: {
          input: (v: string) => setValueForField(field, v),
        },
      }),
    };
  };

  return new UIBlueprint({
    root: new DynamicUIComponent({
      tag: "div",
      classes: ["ff-address-input-fields"],
    }),
    children: {
      // using object destructor so children.keys are the same as field names
      ...mkInputComponentWithKey({ field: "unitNumber", key: "unit-number" }),
      ...mkInputComponentWithKey({ field: "streetNumber", key: "st-number" }),
      ...mkInputComponentWithKey({ field: "streetName", key: "st-name" }),
      ...mkInputComponentWithKey({ field: "town", key: "town" }),
      ...mkInputComponentWithKey({ field: "suburb", key: "suburb" }),
      ...mkInputComponentWithKey({ field: "state", key: "state" }),
      ...mkInputComponentWithKey({ field: "postalCode", key: "postal-code" }),
    },
  });
}

export function getRequiredForField(
  f: string,
  country: string | null
): boolean {
  if (!country) return false;
  const REQUIRE_FIELD_FOR_COUNTRY = {
    AUS: ["streetNumber", "streetName", "town", "state", "postalCode"],
    SGP: ["streetNumber", "streetName", "postalCode"],
    NZL: ["streetNumber", "streetName", "postalCode"],
    THA: ["streetName", "town"],
    IDN: ["streetName", "town"],
  };
  const requiredFields: string[] = REQUIRE_FIELD_FOR_COUNTRY[country] || [];
  return requiredFields.includes(f);
}
