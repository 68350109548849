import { DynamicUIComponent } from "@frankieone/shared";
import { getApplicantNameForCountryId } from "@/utils/personalDetails";
import { mkGenericBlueprint } from "./generic-blueprint";
import { Context } from "./blueprint";

export function mkIDNBlueprint(context: Context) {
  const genericBlueprint = mkGenericBlueprint(context);

  const { getPhrase, getApplicant, setApplicant } = context;

  const applicant = getApplicant();

  const setValue = (value: string) => {
    const formattedName = getApplicantNameForCountryId("IDN", value);
    applicant.name = formattedName;
    setApplicant(applicant);
  };

  const mkGenericInput = () => {
    return new DynamicUIComponent({
      tag: "generic-input",
      classes: ["ff-national-id-name"],
      attrs: {
        label: getPhrase("document.type_national_id.name", {
          isMandatory: true,
        }),
        value: applicant.displayName ?? "",
        placeholder: getPhrase("document.type_national_id.name"),
        tabindex: 0,
        mask: "",
      },
      listeners: {
        input: (v: string) => setValue(v),
      },
    });
  };

  genericBlueprint.setChild("name", mkGenericInput());

  return genericBlueprint;
}
