



import { Component, Vue } from "vue-property-decorator";

@Component({
    name: "NZLLicence",
})
export default class NZLLicence extends Vue { }
