


























import {
  FileUploadClient,
  Scan,
  SupportingDocument,
  ALLOWED_MIME_TYPES,
  ALLOWED_MIME_TYPES_STRING,
} from "@frankieone/shared";
import moment from "moment";
import { Vue, Prop, Component, Watch } from "vue-property-decorator";
import AddFileIcon from "@/components/icons/AddFileIcon.vue";
import RemoveFileIcon from "@/components/icons/RemoveFileIcon.vue";
import readBase64Document from "@/utils/readBase64Document";

@Component({
  name: "FileUpload",
  components: {
    AddFileIcon,
    RemoveFileIcon,
  },
})
export default class FileUpload extends Vue {
  uploadPercentage = 0;

  localImageURL: string;

  isLoading = false;

  uploadError = "";

  isPdf = false;

  isFileUploaded = false;

  allowedMimeTypes = ALLOWED_MIME_TYPES_STRING;

  @Prop(Object) fileUploadClient: FileUploadClient;

  @Prop() disabled: boolean;

  @Prop() value: SupportingDocument;

  get fileName() {
    if (this.isFileUploaded) return this.value.file?.scanName;
    return "No file name";
  }

  @Watch("value", { deep: true, immediate: true })
  updateFromValue(value) {
    const file = value?.file as any;
    if (file) {
      this.isPdf = !!file.mimeType?.includes("pdf");
      this.isFileUploaded = true;
    } else {
      this.isPdf = false;
      if (this.$refs["file"])
        (this.$refs["file"] as HTMLInputElement).value = "";
    }
  }

  fileUploadTrigger() {
    if (this.isFileUploaded) {
      this.removeUpload();
    } else {
      this.triggerAttachFile();
    }
  }

  triggerAttachFile() {
    (this.$refs.file as any).click();
  }

  createImageURL(fileData) {
    const mimeType = fileData.type;
    if (mimeType.includes("image")) {
      this.localImageURL = URL.createObjectURL(fileData);
    } else {
      this.isPdf = true;
    }
  }

  removeUpload() {
    this.isFileUploaded = false;
    this.$emit("remove");
  }

  async uploadFile(files: File[]) {
    this.uploadError = "";
    const fileData = files[0];
    if (!fileData || !fileData.type) {
      this.uploadError = "document_uploads.generic_error";
      return;
    }
    const mimeType = fileData.type || "";
    if (!ALLOWED_MIME_TYPES.includes(mimeType)) {
      this.uploadError = "document_uploads.unsupported_file_type";
      return;
    }

    this.isLoading = true;

    if (!fileData || !this.fileUploadClient) return;

    this.createImageURL(fileData);

    try {
      const response = await this.fileUploadClient.uploadFile(fileData);
      const mimeType = fileData.type;
      const scan = new Scan({
        scanId: null,
        fileUploadUuid: (response as any).id,
        file: null,
        mimeType,
        side: "F",
        scanCreated: moment().toISOString(),
        scanName: fileData.name,
      });
      readBase64Document({
        localImageFile: fileData,
        mimeType,
      }).then(({ base64Encoded }) => {
        scan.file = base64Encoded;
        this.$emit("input", scan);
      });

      if (!scan || !scan.fileUploadUuid) throw new Error();
      this.isFileUploaded = true;
    } catch (e) {
      console.error(e);
      this.uploadError = "document_uploads.generic_error";
      this.$emit("fileUploadError");
    }

    this.isLoading = false;
  }
}
