



















































import { Vue, Component } from "vue-property-decorator";
import { Applicant, Document } from "@frankieone/shared";
import { type } from "@/utils/configurationParser";
import DocumentReview from "@/forms/DocumentReviewForm.vue";
import DocumentForm from "@/forms/DocumentForm.vue";
import OutcomeMessage from "@/components/OutcomeMessage.vue";
import PersonalForm from "@/forms/PersonalForm.vue";
import { getNameFields } from "../utils/personalDetails";
import {
  getDocumentInnerStepsForIdType,
  updatePrefillingOrLoopStepsAfterIdTypeSelection,
} from "../store/modules/system/routingEngine";

@Component({
  components: {
    DocumentReview,
    DocumentForm,
    OutcomeMessage,
    PersonalForm,
  },
})
export default class PartialMatch extends Vue {
  get currentView() {
    return this.$store.direct.getters.currentView;
  }

  get ctaText(): string {
    return "Check your ID information";
  }

  get applicant(): Applicant {
    return this.$store.direct.state.personal.applicant;
  }

  get documents(): Document[] {
    return this.$store.direct.state.documents.documentsList;
  }

  get hasMedicareDoc(): boolean {
    return !!this.$store.direct.getters.getDocumentOfType("NATIONAL_HEALTH_ID");
  }

  get acceptedAgeRange(): IWidgetConfiguration["ageRange"] {
    const ageRange = this.$store.direct.getters.config(
      "ageRange",
      type.array(type.number)
    ) as IWidgetConfiguration["ageRange"];
    return ageRange;
  }

  async updateMedicareMiddleName(value) {
    const medicareDoc =
      this.$store.direct.getters.getDocumentOfType("NATIONAL_HEALTH_ID");
    if (medicareDoc) {
      const medicareDocIndex = this.documents.findIndex(
        (d) => d.idType === "NATIONAL_HEALTH_ID"
      );
      medicareDoc.extraData.display_middle_name = value;
      await this.updateDocument(medicareDoc, medicareDocIndex);
    }
  }

  get nameInputFieldNames(): TFieldNames[] {
    const nationalIdDoc =
      this.$store.direct.getters.getDocumentOfType("NATIONAL_ID");

    return getNameFields(nationalIdDoc);
  }

  async updateDocument(document: Document, index = 0): Promise<void> {
    return this.$store.direct.dispatch.updateDocument({ document, index });
  }

  get displayMiddleNameValue(): string | null {
    const medicareDoc =
      this.$store.direct.getters.getDocumentOfType("NATIONAL_HEALTH_ID");
    return (medicareDoc?.extraData?.display_middle_name as string) ?? null;
  }

  get acceptedCountries(): Document["country"][] {
    return this.$store.direct.getters.countriesList;
  }

  get acceptedDocuments(): TypedDocConfig[] {
    const { config } = this.$store.direct.getters;
    return config("documentTypes", type.array(type.object)) as TypedDocConfig[];
  }

  get existingDocumentTypes(): IWidgetConfiguration["documentTypes"] {
    const possibleRepetitions = this.documents
      .map((d) => d.idType)
      .filter(Boolean);
    const set = new Set(possibleRepetitions);
    const types = Array.from(set);
    return types as IWidgetConfiguration["documentTypes"];
  }

  nextStep() {
    return this.$store.direct.dispatch.nextStep();
  }

  get documentsWithType(): Document[] {
    return this.documents.filter((d) => Boolean(d.idType));
  }

  get currentStep() {
    return this.$store.direct.getters.currentStep;
  }

  get firstUnverifiedDocument(): Document | null {
    const index = this.documents.findIndex((d) => !d.verified.manual);
    const unverified = this.documents?.[index] ?? null;
    return unverified;
  }

  set firstUnverifiedDocument(document: Document | null) {
    if (document) {
      const documents = this.documents.map((d) => d.clone());
      const index = documents.findIndex((d) => !d.verified.manual);
      documents.splice(index, 1, document);
      this.updateDocuments(documents);
    }
  }

  get isInDocumentReview() {
    const parentSlug = (
      this.currentStep.parent
        ? this.currentStep.parent.slug
        : this.currentStep.slug
    ) as COMPONENT;
    return [this.IDS.FORM_DOCUMENT].includes(parentSlug as COMPONENT);
  }

  get hasMoreDocumentsToAdd() {
    const count = this.acceptedDocuments.length;
    return this.documentsWithType.length < count;
  }

  addNewDocument(document: Document) {
    this.$store.direct.dispatch.addNewDocument(document);
  }

  updateDocuments(documents: Document[]) {
    documents.forEach((doc, i) => {
      this.$store.direct.dispatch.updateDocument({ document: doc, index: i });
    });
  }

  updateApplicant(applicant: Applicant) {
    return this.$store.direct.dispatch.updateApplicant(applicant);
  }

  updateUnverfiedDocument(document: Document) {
    this.firstUnverifiedDocument = document;
  }

  onDocumentReviewed(d: Document | false) {
    if (d === false) {
      // hasnt changed
      this.setChecked();
      const hasMoreDocumentsToReview = this.unverifiedDocuments.length > 0;
      if (hasMoreDocumentsToReview) {
        this.addDocumentReview();
      } else {
        this.addNewDocument(new Document());
        this.addDocumentForm();
      }
    } else {
      // has changed
      this.firstUnverifiedDocument = d;
    }
    this.$nextTick(() => {
      this.nextStep();
    });
  }

  setChecked() {
    if (this.firstUnverifiedDocument) {
      const document: Document = this.firstUnverifiedDocument.clone();
      document.verified.manual = true;
      this.firstUnverifiedDocument = document;
    }
  }

  get unverifiedDocuments(): Document[] {
    return this.documents.filter((d) => !d.verified.manual);
  }

  // keep this as its used in routing engine logic
  get acceptedDocumentTypes(): Document["idType"][] {
    return this.acceptedDocuments.map(
      (doc) => doc.type
    ) as Document["idType"][];
  }

  onDocumentTypeSelected(idType) {
    this.onIdTypeChanged(idType);
    this.nextStep();
  }

  onIdTypeChanged(idType: Document["idType"]) {
    // on id type selected

    const { FORM_DOCUMENT } = this.IDS;
    this.$store.direct.dispatch.updateInnerSteps({
      innerSteps: () => {
        return getDocumentInnerStepsForIdType({
          idType,
          acceptedDocumentTypes: this.acceptedDocumentTypes,
          isMobile: this.isMobile,
        });
      },
    });
    // I don't see any situation idType would be false, but rn don't want to clean this up
    // as it might be here to avoid some edge case where the idType is missing
    if (!idType) {
      const isStepDocumentForm = (s) => s.slug === FORM_DOCUMENT;
      const viewIndex = this.$store.direct.state.system.routeIndex.view;
      const stepIndex =
        this.$store.direct.getters.currentView.steps?.findIndex(
          isStepDocumentForm
        );
      if (!stepIndex || stepIndex === -1) return;
      this.$store.direct.dispatch.gotoStep({
        view: viewIndex,
        step: stepIndex,
      });
    } else {
      // effects on other steps based on selected id type, such as adding name input after selection for medicare
      // as well as preventing name input to be displayed after non medicare selection
      // REMINDER: This is only valid for prefilling and for partial match flows
      this.$store.direct.dispatch.updateSteps({
        steps: (steps) => {
          const { step: stepIndex } =
            this.$store.direct.state.system.routeIndex;
          return updatePrefillingOrLoopStepsAfterIdTypeSelection({
            steps,
            stepIndex,
            selectedIdType: idType,
          });
        },
      });
    }
  }

  // eslint-disable-next-line consistent-return
  addDocumentForm() {
    if (!this.hasMoreDocumentsToAdd) return this.showTooManyTriesView();
    this.$store.direct.dispatch.updateSteps({
      steps: (steps) => {
        const newSteps = [
          ...steps,
          {
            slug: this.IDS.FORM_DOCUMENT,
            label: "New Document",
            inner: [
              { slug: this.IDS.INPUT_DOCUMENT_TYPE, label: "Document Type" },
            ],
          },
        ];

        return newSteps;
      },
    });
  }

  showTooManyTriesView() {
    this.$store.direct.dispatch.addView("too-many-tries");
    this.nextStep();
  }

  gotoSummary() {
    this.addView("summary");
    this.$store.direct.dispatch.nextView();
  }

  addView(slug) {
    this.$store.direct.dispatch.addView(slug);
  }

  addDocumentReview() {
    this.$store.direct.dispatch.updateSteps({
      steps: (steps) => {
        const newSteps = [
          ...steps,
          { slug: this.IDS.FORM_DOC_REVIEW, label: "Document Review" },
        ];

        return newSteps;
      },
    });
  }

  addSteps() {
    if (this.hasNoUnverifiedDocuments && this.hasMoreDocumentsToAdd) {
      this.addNewDocument(new Document());
      this.addDocumentForm();
      this.addView("summary");
    } else if (this.hasNoUnverifiedDocuments && !this.hasMoreDocumentsToAdd) {
      this.showTooManyTriesView();
    } else {
      this.addDocumentReview();
      this.addView("summary");
    }
  }

  get hasNoUnverifiedDocuments(): boolean {
    return this.firstUnverifiedDocument === null;
  }

  created() {
    // console.log("created");
    this.addSteps();
  }
}
