














import { Vue, Component, Prop } from "vue-property-decorator";

@Component({})
export default class TheHeader extends Vue {
  @Prop() disabled: boolean;

  @Prop() percentage: number;

  @Prop() nominal: boolean;

  @Prop() path: RouteObject[];

  @Prop() currentStep: CurrentStepObject;

  isCurrentStep(step: NavigationItem) {
    const {
      index: { view: theViewIndex, flattenedInnerStepIndex: theStepIndex },
      count,
    } = step;
    const { view: currentViewIndex, step: currentStepIndex } =
      this.currentStep.viewIndex;
    const minStep = theStepIndex;
    const maxStep = theStepIndex + count - 1;

    return (
      theViewIndex === currentViewIndex &&
      currentStepIndex >= minStep &&
      currentStepIndex <= maxStep
    );
  }
}
