





import { Component, Vue, Prop } from "vue-property-decorator";

type TSize = {
  height: number;
  width: number;
};
@Component({
  name: "Browser",
})
export default class Loading extends Vue {
  @Prop({ default: { width: 170, height: 145 } }) size: TSize;
}
