import { DynamicUIComponent, UIBlueprint } from '@frankieone/shared';
import { Callback, Context, Field, mkCountryInput, mkLicenceNumberInput } from './blueprint';
import { getCountryName } from '@/utils/country';
import { feature } from '@/store/features';

/**
 * The current context is invalid when the country code does not match with a real country from country data
 * @param c Context object
 * @returns Boolean if the current state is invalid
 */
const hasInvalidCountry = (c: Context): boolean => {
  const document = c.getCompleteDriverLicence();
  const selectedCountry = <string>document.country;
  const countryOptions = c.getCountryOptions();
  if (selectedCountry === null) return true;

  return countryOptions.some((option) => !getCountryName(option));
};

export function mkGenericDriversLicenceBlueprint(context: Context, getSetterFor: (f: Field) => Callback): UIBlueprint {
  const { getCountryOptions } = context;

  const countryOptions = getCountryOptions();

  const children = {};

  // always display dropdown if more than 1 option or options contain invalid country
  if (countryOptions.length > 1 || hasInvalidCountry(context))
    children['country'] = mkCountryInput(context, getSetterFor('country'));

  const enhancedValidation = feature('enhancedValidation');
  children['idNumber'] = mkLicenceNumberInput(
    {
      ...context,
      validation: {
        mask: 'X'.repeat(99),
        regex: enhancedValidation ? /^[a-z\d]{1,99}$/i : undefined,
      },
    },
    getSetterFor('idNumber'),
  );

  return new UIBlueprint({
    root: new DynamicUIComponent({
      tag: 'div',
      classes: ['ff-drivers-licence-input'],
    }),
    children,
  });
}
