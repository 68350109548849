










































import { Document, Applicant, SupportingDocument } from "@frankieone/shared";
import { Component, Vue, Watch } from "vue-property-decorator";
import DocumentForm from "@/forms/DocumentForm.vue";
import PersonalForm from "@/forms/PersonalForm.vue";
import DocumentUploadsForm from "@/forms/DocumentUploadsForm.vue";
import {
  getDocumentInnerStepsForIdType,
  getInitialPathForThisState,
  getStepsForSpecifiedDocumentCountry,
  updatePrefillingOrLoopStepsAfterIdTypeSelection,
} from "../store/modules/system/routingEngine";
import { getNameFields } from "../utils/personalDetails";
import { type } from "../utils/configurationParser";

@Component({
  name: "InitialDataGathering",
  components: {
    DocumentForm,
    PersonalForm,
    DocumentUploadsForm,
  },
})
export default class InitialDataGathering extends Vue {
  isLoading = false;

  get currentView() {
    return this.$store.direct.getters.currentView;
  }

  get hasDualName(): boolean {
    return (
      this.currentView.steps?.some(
        (s: Step) => s.slug === "INPUT_NAME_EXTRA"
      ) ?? false
    );
  }

  get currentStep() {
    return this.$store.direct.getters.currentStep;
  }

  get activeForm(): COMPONENT {
    return (this.currentStep.parent?.slug ??
      this.currentStep.slug) as COMPONENT; // need to convert definition of "slug" from string to COMPONENT asap
  }

  get activeStep() {
    return this.currentStep.slug;
  }

  get activeFormIsPersonal(): boolean {
    return [
      this.IDS.INPUT_NAME,
      this.IDS.INPUT_NAME_EXTRA,
      this.IDS.INPUT_DOB,
      this.IDS.INPUT_ADDRESS_WRAPPER,
    ].includes(this.activeForm);
  }

  get dobType(): TDOBTypeSupport {
    return (
      this.$store.direct.getters.config("dateOfBirth")?.type ?? "gregorian"
    );
  }

  // this.activeInput = this.\IDS.INPUT_DOCUMENT_TYPE;
  get hasGoogleApi(): boolean {
    return this.$store.direct.state.session.isGoogleApiLoaded;
  }

  get documents(): Document[] {
    return this.$store.direct.state.documents.documentsList;
  }

  get supportingDocuments(): (SupportingDocument | null)[] {
    return this.$store.direct.state.documents.supportingDocuments;
  }

  get supportingDocumentUploadFields(): DocumentUpload[] {
    return this.$store.direct.getters.supportingDocumentUploadFields;
  }

  get acceptedCountries(): Document["country"][] {
    return this.$store.direct.getters.countriesList;
  }

  get acceptedDocuments(): TypedDocConfig[] {
    const { config } = this.$store.direct.getters;
    return config("documentTypes", type.array(type.object)) as TypedDocConfig[];
  }

  get acceptedDocTypes(): Document["idType"][] {
    const { acceptedDocumentTypes } = this.$store.direct.getters;
    return acceptedDocumentTypes;
  }

  get acceptedAgeRange(): IWidgetConfiguration["ageRange"] {
    const ageRange = this.$store.direct.getters.config(
      "ageRange",
      type.array(type.number)
    ) as IWidgetConfiguration["ageRange"];
    return ageRange;
  }

  get applicant(): Applicant {
    return this.$store.direct.state.personal.applicant;
  }

  get requestAddress(): boolean {
    return this.$store.getters.config("requestAddress", type.bool);
  }

  get requestDocumentUploads(): boolean {
    return !!this.$store.getters.config("documentUploads", type.bool);
  }

  get lazyIDCheck(): boolean {
    return this.$store.getters.config("lazyIDCheck", type.bool);
  }

  get requestID(): boolean {
    return this.$store.getters.config("requestID", type.bool);
  }

  get hasMedicareDoc(): boolean {
    return !!this.$store.direct.getters.getDocumentOfType("NATIONAL_HEALTH_ID");
  }

  async updateMedicareMiddleName(value) {
    const medicareDoc =
      this.$store.direct.getters.getDocumentOfType("NATIONAL_HEALTH_ID");
    if (medicareDoc) {
      const medicareDocIndex = this.documents.findIndex(
        (d) => d.idType === "NATIONAL_HEALTH_ID"
      );
      medicareDoc.extraData.display_middle_name = value;
      await this.updateDocument(medicareDoc, medicareDocIndex);
    }
  }

  get displayMiddleNameValue(): string | null {
    const medicareDoc =
      this.$store.direct.getters.getDocumentOfType("NATIONAL_HEALTH_ID");
    return (medicareDoc?.extraData?.display_middle_name as string) ?? null;
  }

  @Watch("firstDocument.country", { immediate: true })
  onCountryChanged(country) {
    if (!country) return;
    const { firstDocument } = this;
    const { idType } = firstDocument;
    const { isPreloaded } = this.$store.direct.state.session;
    const documentStepConfig = this.$store.direct.getters.documentConfiguration(
      idType as TSupportedDocuments,
      country
    );
    this.$store.direct.dispatch.updateSteps({
      steps: (steps) => {
        return getStepsForSpecifiedDocumentCountry({
          isPreloaded,
          document: firstDocument,
          documentConfiguration: documentStepConfig,
          existingStepsOfCurrentView: steps,
        });
      },
    });
  }

  onDocumentTypeSelected(idType) {
    this.onIdTypeChanged(idType);
    this.nextStep();
  }

  getFirstDocument(): Document {
    return this.documents[0];
  }

  get firstDocument(): Document {
    return this.documents[0];
  }

  async updateDocument(document: Document, index = 0): Promise<void> {
    return this.$store.direct.dispatch.updateDocument({ document, index });
  }

  async updateApplicant(applicant: Applicant) {
    return this.$store.direct.dispatch.updateApplicant(applicant);
  }

  async supportingDocumentRemoved(index: number) {
    this.$store.direct.dispatch.removeSupportingDocument(index);
  }

  async updateSupportingDocuments(document: SupportingDocument) {
    this.$store.direct.dispatch.addSupportingDocument({ document });
  }

  get nameInputFieldNames(): TFieldNames[] {
    const nationalIdDoc =
      this.$store.direct.getters.getDocumentOfType("NATIONAL_ID");

    return getNameFields(nationalIdDoc);
  }

  async nextStep() {
    return this.$store.direct.dispatch.nextStep();
  }

  getAddressSteps() {
    const autocomplete = {
      slug: this.IDS.INPUT_ADDR_AUTO,
      label: "Address Autocomplete",
    };
    const manual = {
      slug: this.IDS.INPUT_ADDR_MANUAL,
      label: "Address Manual",
    };

    const theNewSteps = [manual];
    if (this.hasGoogleApi) theNewSteps.splice(0, 0, autocomplete);
    return theNewSteps;
  }

  onIdTypeChanged(idType: Document["idType"]) {
    // creates first document if still doesnt exist
    this.getFirstDocument();
    const { FORM_DOCUMENT } = this.IDS;
    // update document form inner steps
    this.$store.direct.dispatch.updateInnerSteps({
      innerSteps: () => {
        return getDocumentInnerStepsForIdType({
          idType,
          acceptedDocumentTypes: this.acceptedDocumentTypes,
          isMobile: this.isMobile,
        });
      },
      stepSlug: FORM_DOCUMENT,
    });

    const { isPreloaded } = this.$store.direct.state.session;
    // if not prefilled, there's nothing else to be done
    if (!isPreloaded) return;
    // effects on other steps based on selected id type, such as adding name input after selection for medicare
    // as well as preventing name input to be displayed after non medicare selection
    // REMINDER: This is only valid for prefilling and for partial match flows
    this.$store.direct.dispatch.updateSteps({
      steps: (steps) => {
        const { step: stepIndex } = this.$store.direct.state.system.routeIndex;
        return updatePrefillingOrLoopStepsAfterIdTypeSelection({
          steps,
          stepIndex,
          selectedIdType: idType,
        });
      },
    });
  }

  @Watch("hasGoogleApi", { immediate: false })
  onGoogleApiResolved() {
    this.$store.direct.dispatch.updateInnerSteps({
      innerSteps: () => this.getAddressSteps(),
      stepSlug: this.IDS.INPUT_ADDRESS_WRAPPER,
    });
  }

  // keep this as its used in routing engine logic
  get acceptedDocumentTypes(): Document["idType"][] {
    return this.acceptedDocuments.map(
      (doc) => doc.type
    ) as Document["idType"][];
  }

  setRouting() {
    this.$store.direct.dispatch.updateSteps({
      steps: (steps) => {
        steps = getInitialPathForThisState({
          acceptedDocumentTypes: this.acceptedDocumentTypes,
          documents: this.$store.direct.getters.selectedDocuments,
          applicant: this.applicant,
          addressRequired: this.requestAddress,
          IDRequired: this.requestID,
          lazyID: this.lazyIDCheck,
          isMobile: this.isMobile,
          isPreloaded: this.$store.direct.state.session.isPreloaded,
          usesGoogleApi: this.$store.direct.state.session.isGoogleApiLoaded,
          requestDocumentUploads: this.requestDocumentUploads,
          supportingDocuments: this.supportingDocuments,
        });

        this.$nextTick(async () => {
          if (steps.length === 0) await this.nextStep();
        });
        return steps;
      },
    });
  }

  mounted() {
    this.setRouting();
  }
}
