import Vue from "vue";
import Vuex from "vuex";
import { createDirectStore } from "direct-vuex";
import SystemModule, { flattenInnerSteps } from "./modules/system";
import DocumentsModule from "./modules/documents";
import PersonalModule from "./modules/personal";
import SessionModule from "./modules/session";
import ChecksModule from "./modules/checks";

Vue.use(Vuex);

// custom plugin to log actions, workaround for easier development
function actionLoggerPlugin(store) {
  store.subscribeAction(({ type, payload }, state) => {
    console.log(type, payload, state);
  });
}

const { store, rootActionContext, moduleActionContext, moduleGetterContext } =
  createDirectStore({
    modules: {
      system: SystemModule,
      documents: DocumentsModule,
      personal: PersonalModule,
      session: SessionModule,
      checks: ChecksModule,
    },
    plugins: process.env.NODE_ENV !== "production" ? [actionLoggerPlugin] : [],
    actions: {
      resetData() {
        store.dispatch.resetSystem();
        store.dispatch.resetDocuments();
        store.dispatch.resetPersonal();
      },
    },
  });
(window as any).ffIdTypes = {
  PASSPORT: "PASSPORT",
  MEDICARE: "NATIONAL_HEALTH_ID",
  DRIVERSL: "DRIVERS_LICENCE",
};

type RoutingDetails = { slug: string; viewIndex: number; stepIndex: number };
const ffJumpTo = async (stepSlug: string): Promise<RoutingDetails> => {
  const { view: currentIndex } = store.getters.routeIndex;
  const { viewPath } = store.getters;

  const norm = (s?: string) => s?.toLowerCase();
  const equals = (s1?: string, s2?: string) =>
    s1 && s2 && norm(s1) === norm(s2);
  const extractStepRoutingDetails =
    (viewIndex: number) =>
    (step: Step, stepIndex: number): RoutingDetails => ({
      viewIndex,
      stepIndex,
      slug: step.slug,
    });
  const extractFlattenedRoutingDetails = (view: RouteObject, viewIndex) => {
    const extractor = extractStepRoutingDetails(viewIndex);
    const flattenedViewSteps: Step[] = view.steps?.reduce(
      flattenInnerSteps,
      []
    ) ?? [view];
    const stepsSlugAndIndex = flattenedViewSteps.map(extractor);
    return stepsSlugAndIndex;
  };

  // extract flat list of all steps/inner steps + their global routing details { viewIndex, stepIndex }
  const flattenedRoutingDetails: RoutingDetails[] = viewPath.flatMap(
    extractFlattenedRoutingDetails
  );
  // find step by slug, posterior to current step
  const target: RoutingDetails = flattenedRoutingDetails.find(
    (v, i) => i >= currentIndex && equals(v.slug, stepSlug)
  )!;

  if (target) {
    await store.dispatch.gotoStep({
      view: target.viewIndex,
      step: target.stepIndex,
    });
  }
  return target;
};

(window as any).ffJumpTo = ffJumpTo;
if (process.env.NODE_ENV === "development") (window as any).store = store;
// Export the direct-store instead of the classic Vuex store.
export default store;
// The following exports will be used to enable types in the
// implementation of actions.
export { rootActionContext, moduleActionContext, moduleGetterContext };
// The following lines enable types in the injected store '$store'.
export type AppStore = typeof store;
declare module "vuex" {
  interface Store<S> {
    direct: AppStore;
  }
}
