import { Document } from "@frankieone/shared";
import CreditHeaderFailure from "@/views/CreditHeaderFailure.vue";
import CreditHeaderSuccess from "@/views/CreditHeaderSuccess.vue";
import DetailsSummary from "@/views/DetailsSummary.vue";
import FailureView from "@/views/FailureView.vue";
import ErrorView from "@/views/ErrorView.vue";
import InitialDataGathering from "@/views/InitialDataGathering.vue";
import NoMatchView from "@/views/NoMatchView.vue";
import OutcomeView from "@/views/Outcome.vue";
import SuccessView from "@/views/SuccessView.vue";
import WelcomeView from "@/views/Welcome.vue";
import ExternalIdv from "@/views/ExternalIDV.vue";
import PartialMatchView from "@/views/PartialMatch.vue";
import TooManyTries from "@/views/TooManyTriesView.vue";
import PendingSuccessView from "@/views/PendingSuccessView.vue";

export const COMPONENTS: { [k: string]: COMPONENT } = {
  VIEW_INITIAL: "INITIAL",
  VIEW_NO_MATCH: "NO_MATCH",
  VIEW_PARTIAL_MATCH: "PARTIAL_MATCH",

  FORM_DOC_REVIEW: "FORM_DOC_REVIEW",
  FORM_PERSONAL: "FORM_PERSONAL",
  FORM_DOCUMENT: "FORM_DOCUMENT",
  INPUT_DOCUMENT_TYPE: "INPUT_DOCUMENT_TYPE",
  INPUT_DOCUMENT_DATA: "INPUT_DOCUMENT_DATA",
  INPUT_PASSPORT: "INPUT_PASSPORT_DATA",
  INPUT_DRIVERS_STATE: "INPUT_DRIVERS_STATE",
  INPUT_DRIVERS: "INPUT_DRIVERS_DATA",
  INPUT_MEDICARE: "INPUT_MEDICARE_DATA",
  INPUT_ADDRESS_WRAPPER: "ADDRESS_MAIN",
  INPUT_ADDRESS_EXTRA_WRAPPER: "ADDRESS_EXTRA",
  INPUT_ADDR_AUTO: "INPUT_ADDRESS_AUTO",
  INPUT_ADDR_MANUAL: "INPUT_ADDRESS_MANUAL",
  INPUT_ADDR_ADD_EXTRA: "ADD_ADDRESS_EXTRA",
  INPUT_DOB: "INPUT_DATE_OF_BIRTH",
  INPUT_NAME: "INPUT_NAME",
  INPUT_NAME_EXTRA: "INPUT_NAME_EXTRA",
  INPUT_REVIEW_DOC: "REVIEW_DOC",

  WARN_PARTIAL: "WARNING_PARTIAL",
  WARN_NOMATCH: "WARNING_NOMATCH",
  DOCUMENT_UPLOADS: "DOCUMENT_UPLOADS",
};
export function getSlugForDocumentDetailsStepOfType(
  idType: Document["idType"]
): Values<typeof COMPONENTS> {
  switch (idType) {
    case "DRIVERS_LICENCE":
      return COMPONENTS.INPUT_DRIVERS;
    case "NATIONAL_HEALTH_ID":
      return COMPONENTS.INPUT_MEDICARE;
    case "PASSPORT":
      return COMPONENTS.INPUT_PASSPORT;
    default:
      return COMPONENTS.INPUT_DOCUMENT_DATA;
  }
}
export default function getRoute(routeSlug: string): RouteObject {
  const allRoutes = {
    "external-idv": {
      slug: "external-idv",
      label: "Identity Verification",
      component: ExternalIdv,
      removeHeader: true,
    },
    welcome: {
      slug: "welcome",
      label: "Welcome",
      component: WelcomeView,
      showNavigationBar: false,
    },
    summary: {
      slug: "summary",
      label: "Review",
      component: DetailsSummary,
      showNavigationBar: false,
    },
    outcome: {
      slug: "outcome",
      label: "Outcome",
      component: OutcomeView,
      showNavigationBar: false,
    },
    success: {
      slug: "success",
      label: "Success",
      component: SuccessView,
      showNavigationBar: false,
    },
    "pending-success": {
      slug: "pending-success",
      label: "Pending",
      component: PendingSuccessView,
      showNavigationBar: false,
    },
    failure: {
      slug: "failure",
      label: "Failure",
      component: FailureView,
      showNavigationBar: false,
    },
    error: {
      slug: "error",
      label: "Error",
      component: ErrorView,
      showNavigationBar: false,
    },
    "credit-header-success": {
      slug: "credit-success",
      label: "Credit Header Warning",
      component: CreditHeaderSuccess,
      showNavigationBar: false,
    },
    "credit-header-failure": {
      slug: "credit-failure",
      label: "Credit Header Warning",
      component: CreditHeaderFailure,
      showNavigationBar: false,
    },
    "too-many-tries": {
      slug: "too-many-tries",
      label: "Too Many Tries",
      component: TooManyTries,
      showNavigationBar: false,
    },
    initial: {
      slug: "initial",
      label: "Initial",
      component: InitialDataGathering,
      showNavigationBar: true,
      steps: [],
    },
    "no-match": {
      slug: "no-match",
      label: "No Match",
      component: NoMatchView,
      showNavigationBar: false,
      steps: [
        {
          slug: COMPONENTS.WARN_NOMATCH,
          label: "Oops",
          showNavigationBar: false,
        },
      ],
    },
    "partial-match": {
      slug: "partial-match",
      label: "Partial Match",
      component: PartialMatchView,
      showNavigationBar: false,
      steps: [
        {
          slug: COMPONENTS.WARN_PARTIAL,
          label: "Oops",
          showNavigationBar: false,
        },
      ],
    },
  };

  const mkCopy = (obj) => JSON.parse(JSON.stringify(obj));
  const copy = (() => {
    const object = allRoutes[routeSlug];
    if (!object) throw new Error(`Non existing route object ${routeSlug}`);
    const { component } = object;
    const theCopy = mkCopy(object);
    theCopy.component = component;
    return theCopy;
  })();
  return copy;
}
