import { DocumentType } from "@frankieone/shared";
import { cond, isBoolean, stubTrue, isObject, stubFalse, isNull } from "lodash";

const typeIsStringOrObject = (docType) =>
  docType in DocumentType || docType.type in DocumentType;
const validateConfigObject = (value: DocumentUploadConfig | any) =>
  value.uploads &&
  value.uploads.length &&
  value.uploads.every(
    (item) =>
      item.title && item.types.length && item.types.every(typeIsStringOrObject)
  );

const validator = cond([
  [isBoolean, stubTrue],
  [isNull, stubTrue],
  [isObject, validateConfigObject],
  [stubTrue, stubFalse],
]);

export default validator;
