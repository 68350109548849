
















import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { Applicant, Document } from "@frankieone/shared";
import ReviewInput from "@/components/inputs/ReviewInput.vue";
import { snapshot } from "@/utils/stringify";

@Component({
  components: {
    ReviewInput,
  },
})
export default class DocumentReviewForm extends Vue {
  @Prop({ required: true }) value: Document;

  @Prop({ required: true }) applicant: Applicant;

  @Prop({ required: true }) acceptedCountries: Document["country"][];

  buffer: Document = new Document();

  isEditMode = false;

  get hasChanged() {
    return snapshot(this.value) !== snapshot(this.buffer);
  }

  get docTypeName(): string {
    const { idType } = this.buffer;
    switch (idType) {
      case "DRIVERS_LICENCE":
        return "Driver's Licence";
      case "PASSPORT":
        return "Passport";
      case "NATIONAL_HEALTH_ID":
        return "Medicare";
      default:
        return "";
    }
  }

  updateBuffer(d: Document) {
    this.buffer = d;
  }

  confirmReview() {
    // if document was not changed and therefore
    // --> THE PARENT SHOULD:: add a new step for document form IF there is still doc types available, OTHERWISE fails
    // --> HERE:: clear document buffer, append to list of existing documents, update list in the parent and warn about need to switch to form for document creation
    // if document was changed and update list and warn that document was changed
    this.$emit("reviewed", this.hasChanged && this.buffer);
  }

  @Watch("value", { deep: true, immediate: true })
  onValueChanged() {
    this.buffer = this.value.clone();
  }
}
