import {
  breakDateToObject,
  DynamicUIComponent,
  serialiseDateObject,
  UIBlueprint,
  transformDateToGregorian,
  DateObject,
} from "@frankieone/shared";
import { Context, padValue } from "./blueprint";

export function mkBuddhistDOBBlueprint(context: Context): UIBlueprint {
  type FieldNames = "day" | "month" | "year";
  type ObjFieldNames = "dd" | "mm" | "yyyy";
  type DayComponentFactory = { f: FieldNames };

  const { getPhrase, getBuffer, getData, setData, setBuffer } = context;

  const applicant = getData().applicant.clone();
  const setCompleteDateOfBirth = (dob: string | null, buddhist?: string) => {
    applicant.dateOfBirth = dob;
    if (buddhist) applicant.extraData["dob.Buddhist"] = buddhist;
    else delete applicant.extraData["dob.Buddhist"];

    setData({ applicant });
  };

  const dateObject = breakDateToObject(getBuffer());

  const isComplete = (): boolean => {
    if (!dateObject) return false;
    const { yyyy } = dateObject;
    const length = yyyy?.length ?? 0;

    if (length < 4) return false;

    return true;
  };
  const getDateObjectFieldFor = (field: FieldNames): ObjFieldNames => {
    if (field === "day") return "dd";
    if (field === "month") return "mm";
    return "yyyy";
  };
  const getMaskFor = (field: FieldNames): string => {
    if (field === "day") return "##";
    if (field === "month") return "##";
    return "####";
  };
  const getPadLengthFor = (field: FieldNames): number =>
    field === "year" ? 0 : 2;
  const serialiseDate = (
    dateObject: DateObject,
    replaceThis: string,
    withThis: string
  ): string => {
    const { yyyy, mm, dd } = dateObject;
    const replace = (theValue: string | null) =>
      !theValue || theValue === replaceThis ? withThis : theValue;
    return serialiseDateObject({
      yyyy,
      mm: replace(mm),
      dd: replace(dd),
    });
  };
  const setValue = (field: FieldNames, value: string) => {
    const objField = getDateObjectFieldFor(field);
    dateObject[objField] = value;

    const serialised = serialiseDate(dateObject, "", "00");
    setBuffer(serialised);
    if (isComplete()) {
      const converted = transformDateToGregorian(
        serialiseDate(dateObject, "00", "01"),
        "buddhist"
      );
      setCompleteDateOfBirth(converted, serialised);
    } else {
      setCompleteDateOfBirth(null);
    }
  };
  const getFieldValue = (field: FieldNames): string => {
    const objField = getDateObjectFieldFor(field);
    const value = dateObject[objField];
    if (value === "00") return "";
    return value ?? "";
  };
  const getPadFieldValue = (field: FieldNames) => {
    const padLength = getPadLengthFor(field);
    const value = getFieldValue(field);
    const padded = padValue(value, padLength);
    return padded;
  };

  const mkDayInputComponent = ({ f }: DayComponentFactory) =>
    new DynamicUIComponent({
      tag: "generic-input",
      classes: [`ff-${f}`],
      attrs: {
        mask: getMaskFor(f),
        type: "tel",
        noTopPadding: true,
        placeholder: getPhrase(`date_of_birth_input_screen.${f}_label`),
        value: getFieldValue(f),
      },
      listeners: {
        input: (v: string) => setValue(f, v),
        blur: () => setValue(f, getPadFieldValue(f)),
      },
    });

  return new UIBlueprint({
    root: new DynamicUIComponent({
      tag: "label-wrapper",
      classes: ["ff-dob-inputs", "ff-dob-inputs-additional"],
      attrs: {
        label: context.getPhrase(
          "date_of_birth_input_screen.full_date_label_buddhist"
        ),
      },
    }),
    children: {
      day: mkDayInputComponent({ f: "day" }),
      month: mkDayInputComponent({ f: "month" }),
      year: mkDayInputComponent({ f: "year" }),
    },
  });
}
