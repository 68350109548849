








import { Component, Vue } from "vue-property-decorator";
import OutcomeMessage from "@/components/OutcomeMessage.vue";
import { type } from "../utils/configurationParser";

@Component({
  name: "Failure",
  components: {
    OutcomeMessage,
  },
})
export default class Failure extends Vue {
  get ctaText(): string {
    const ctaText = this.$store.direct.getters.config(
      "failureScreen.ctaText",
      type.string
    );
    return ctaText;
  }

  get ctaUrl(): string | null {
    const ctaUrl = this.$store.direct.getters.config(
      "failureScreen.ctaUrl",
      type.nString
    );
    return ctaUrl;
  }

  get currentView() {
    return this.$store.direct.getters.currentView;
  }

  get creditHeaderIssue() {
    return this.currentView.data.issue;
  }

  redirect() {
    if (!this.ctaUrl) return;
    window.location.href = this.ctaUrl;
  }

  async mounted() {
    await this.dispatchEvent({ eventName: "SCREEN:FAILURE" });
  }
}
