




























import { Component, Vue, Prop } from "vue-property-decorator";
import { Applicant, Document } from "@frankieone/shared";
import DocumentForm from "@/forms/DocumentForm.vue";
import ReviewInput from "@/components/inputs/ReviewInput.vue";
import { snapshot } from "@/utils/stringify";
import { ID_TYPES } from "../utils/documentFactory";

@Component({
  components: {
    ReviewInput,
    DocumentForm,
  },
})
export default class ReviewOrCreateDocument extends Vue {
  @Prop({ required: true }) step: CurrentStepObject;

  @Prop({ required: true }) documents: Document[];

  @Prop({ required: true }) applicant: Applicant;

  @Prop({ required: true }) acceptedDocTypes: Document["idType"][];

  @Prop({ required: true }) acceptedCountries: Document["country"][];

  isEditMode = false;

  buffer: Document = new Document();

  get isDocumentForm() {
    const slug = this.step.parent ? this.step.parent.slug : this.step.slug;
    return slug === this.IDS.FORM_DOCUMENT;
  }

  get buttonText(): string {
    const saveButtonCta = this.getPhrase("common.save_button_cta");
    const confirmButtonCta = this.getPhrase("common.confirm_button_cta");
    return this.hasChanged ? saveButtonCta : confirmButtonCta;
  }

  get lastDocument(): Document {
    return this.documents[this.documents.length - 1];
  }

  get hasChanged(): boolean {
    return snapshot(this.lastDocument) !== snapshot(this.buffer);
  }

  get existingDocTypes(): Document["idType"][] {
    return this.documents.map((d) => d.idType).filter(Boolean);
  }

  get checkTitle(): string {
    switch (this.buffer?.idType) {
      case ID_TYPES.DRIVERS_LICENCE:
        return this.getPhrase("drivers_licence_input_screen.title_loop");
      case ID_TYPES.PASSPORT:
        return this.getPhrase("passport_input_screen.title_loop");
      case ID_TYPES.NATIONAL_HEALTH_ID:
        return this.getPhrase("medicare_input_screen.title_loop");
      case ID_TYPES.NATIONAL_ID:
        return this.getPhrase("national_id_input_screen.title_loop");
      default:
        return "document";
    }
  }

  updateDocumentBuffer(document: Document) {
    this.buffer = document;
  }

  onDocumentTypeSelected(idType: Document["idType"]) {
    this.$emit("documentForm:id-type-selected", idType);
  }

  submitDocumentForm() {
    this.$emit("documentForm:submitted");
  }

  confirmReview() {
    if (!this.hasChanged) {
      // document was not changed and therefore
      // --> THE PARENT SHOULD:: add a new step for document form IF there is still doc types available, OTHERWISE fails
      // --> HERE:: clear document buffer, append to list of existing documents, update list in the parent and warn about need to switch to form for document creation
      this.updateDocumentBuffer(new Document());
      this.appendBufferToDocuments();
      this.$emit("documentCreated");
    } else {
      // document was changed and update list and warn that document was changed
      this.replaceLastDocumentWithBuffer();
      this.$emit("documentReviewed");
    }
  }

  replaceLastDocumentWithBuffer() {
    const documents = this.documents.map((d) => d.clone());
    const lastIndex = documents.length - 1;
    documents.splice(lastIndex, 1, this.buffer);
    this.emitDocuments(documents);
  }

  appendBufferToDocuments() {
    const documents = this.documents.map((d) => d.clone());
    documents.push(this.buffer);
    this.emitDocuments(documents);
  }

  emitDocumentFormSubmit() {
    this.$emit("newDocumentSubmitted", this.buffer);
  }

  emitDocuments(documents) {
    this.$emit("update:documents", documents);
  }

  setIsEditMode(value) {
    this.isEditMode = value;
    this.$emit("update:isEditMode", value);
  }

  created() {
    this.buffer = this.lastDocument.clone();
  }

  // get liveDocumentSnapshot(): string {
  //   return snapshot(this.lastDocument);
  // }

  // get lastDocument(): Document | null {
  //   const { length } = this.documents;
  //   if (length === 0) return null;
  //   return this.documents[length - 1].clone();
  // }

  // get isLastDocumentTypeDefined(): boolean {
  //   if (!this.lastDocument) return false;
  //   return !!this.lastDocument.idType;
  // }

  // get isLastDocumentChecked(): boolean {
  //   if (!this.lastDocument) return true; // if for some reason there's no documents, say it's true so new document is used (should never happen)
  //   return !!this.lastDocument.verified.manual;
  // }

  // editBufferDocument(document: Document) {
  //   this.bufferDocument = documen // }

  // updateSnapshot(document?: Document) {
  //   let value;
  //   if (!document) {
  //     value = null;
  //   } else {
  //     value = snapshot(document);
  //   }
  //   this.initialDocumentSnapshot = value;
  // }

  // bufferNewDocument() {
  //   if (this.acceptedDocTypes.length === this.existingDocTypes.length) {
  //     this.$emit("emptyDocumentTypes");
  //   }
  //   const newDocument = new Document();
  //   this.bufferDocument = newDocument;
  //   this.updateSnapshot(newDocument);
  // }

  // @Watch("documents.length")
  // onDocumentCountChanged(newCount) {
  //   if (!this.lastDocument) return;
  //   this.updateSnapshot(this.lastDocument);
  // }

  // created() {
  //   if (this.isLastDocumentChecked) {
  //     this.bufferNewDocument();
  //   } else {
  //     this.updateSnapshot(this.lastDocument!);
  //   }
  // }
}
