






import { Component, Vue } from "vue-property-decorator";

@Component({
  name: "AddNewAddressInput",
  components: {},
})
export default class AddNewAddressInput extends Vue {
  emitValue(value: boolean) {
    this.$emit("input", value);
  }
}
