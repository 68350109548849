import {
  Document,
  UIBlueprint,
  DynamicUIComponent,
  GetPhraseFunction,
} from "@frankieone/shared";
import { medicareOptions } from "@/utils/medicare";
import {
  getInFormattedExpiry,
  getOutFormattedIdExpiry,
  checkDateIsValid,
} from "@/utils/date";

export type Context = {
  getDocument: () => Document;
  setDocument: (document: Document | null) => void;
  getPhrase: GetPhraseFunction;
  setExpiryBuffer: ($event) => void;
  getExpiryBuffer: () => string;
};
type TMedicareInputFields = "idExpiry" | "idNumber" | "idSubType";
type MedicareFieldNames = "number" | "position" | "validity";
type MedicareInputComponents = {
  field: TMedicareInputFields | MedicareFieldNames;
};

export function mkMedicareBlueprint(context: Context) {
  const {
    getPhrase,
    getDocument,
    setDocument,
    setExpiryBuffer,
    getExpiryBuffer,
  } = context;
  const value = getDocument();
  const isGreenMedicare = value?.idSubType === "G";
  const buffer = getExpiryBuffer();

  const mkMedicareFieldsInput = () => {
    return new UIBlueprint({
      root: new DynamicUIComponent({
        tag: "div",
        classes: ["ff-medicare-fields"],
      }),
      children: {
        colour: mkMedicareSelectInput(),
        number: mkMedicareInput({ field: "number" }),
        position: mkMedicareInput({ field: "position" }),
        validity: mkMedicareInput({ field: "validity" }),
      },
    });
  };

  const mkMedicareSelectInput = () => {
    return new UIBlueprint({
      root: new DynamicUIComponent({
        tag: "label-wrapper",
        classes: ["ff-medicare-colour"],
        attrs: {
          label: context.getPhrase("document.type_medicare.colour", {
            isMandatory: true,
          }),
        },
      }),
      children: {
        "icon-radio-input": mkIconSelectComponent(),
      },
    });
  };

  const mkIconSelectComponent = () =>
    new DynamicUIComponent({
      tag: "icon-radio-input",
      classes: [`ff-medicare-card-select`],
      attrs: {
        options: medicareOptions,
        value: getValueForField("idSubType"),
      },
      listeners: {
        input: (v: string) => setValueForField("idSubType", v),
      },
    });

  const mkMedicareInput = ({ field }: MedicareInputComponents) =>
    new DynamicUIComponent({
      tag: "generic-input",
      classes: [`ff-medicare-${field}`],
      attrs: {
        mask: getMaskForField(field),
        type: "tel",
        placeholder: getPlaceholderForField(field),
        label: getPhraseForField(field),
        value: getValueForField(field),
        ...getAttrsForField(field),
      },
      listeners: {
        input: (v: string) => setValueForField(field, v),
      },
    });

  const getPhraseForField = (
    field: MedicareFieldNames | TMedicareInputFields
  ): string => {
    const getPhraseWithAsterisk = (key: string) =>
      getPhrase(key, { isMandatory: true });
    if (field === "validity")
      return getPhraseWithAsterisk(`document.type_medicare.expiry`);
    return getPhraseWithAsterisk(`document.type_medicare.${field}`);
  };
  const getPlaceholderForField = (
    field: MedicareFieldNames | TMedicareInputFields
  ): string => {
    if (field === "validity") return isGreenMedicare ? "MM/YYYY" : "DD/MM/YY";
    return getPhrase(`document.type_medicare.${field}`);
  };

  const getMaskForField = (
    field: MedicareFieldNames | TMedicareInputFields
  ): string => {
    if (field === "number") return "#### ##### #";
    if (field === "position") return "#";
    if (field === "validity") return isGreenMedicare ? "##/####" : "##/##/##";
    return "####";
  };
  const getValueForField = (
    f: TMedicareInputFields | MedicareFieldNames
  ): string => {
    if (f === "idNumber" || f === "number") return value?.idNumber ?? "";
    if (f === "idSubType") return value?.idSubType ?? "";
    if (f === "position") return (value?.extraData?.reference as string) ?? "";
    if (f === "validity") {
      return (
        getInFormattedExpiry(
          value?.idExpiry ?? "",
          buffer,
          getInputFormat(value.idSubType),
          getOutputFormat(value.idSubType)
        ) ?? ""
      );
    }

    return "";
  };

  const getInputFormat = (type) => {
    switch (type) {
      case "G":
        return "MM/YYYY";
      case "Y":
      case "B":
        return "DD/MM/YY";
      default:
        return "DD/MM/YYYY";
    }
  };

  const getOutputFormat = (type) => {
    switch (type) {
      case "G":
        return "YYYY-MM-01";
      default:
        return "YYYY-MM-DD";
    }
  };

  const setValueForField = (
    f: TMedicareInputFields | MedicareFieldNames,
    v: string
  ) => {
    const document = value.clone();
    if (f === "number") {
      document["idNumber"] = v;
      setDocument(document);
    } else if (f === "position") {
      document.extraData.reference = v;
      setDocument(document);
    } else if (f === "validity") {
      const expiry = getOutFormattedIdExpiry(
        v,
        getInputFormat(document.idSubType),
        getOutputFormat(document.idSubType)
      );
      setExpiryBuffer(v);
      if (checkDateIsValid(expiry, getInputFormat(document.idSubType))) {
        document["idExpiry"] = expiry;
        setDocument(document);
      }
    } else if (f === "idSubType") {
      document.idSubType = v;
      document.idExpiry = "";
      document.extraData.reference = "";
      setDocument(document);
      setExpiryBuffer(document.idExpiry);
    }
  };
  const getAttrsForField = (f) => {
    if (f === "position" || f === "validity") {
      return {
        removeSpaces: true,
      };
    }
    return {};
  };

  const children = {};
  children["medicare-fields"] = mkMedicareFieldsInput();

  return new UIBlueprint({
    root: new DynamicUIComponent({
      tag: "div",
      classes: ["ff-medicare-input"],
    }),
    children: {
      ...children,
    },
  });
}
