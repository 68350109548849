








import { Component, Vue } from "vue-property-decorator";
import { Failure } from "../assets/svgs";

@Component({
  components: { Failure },
})
export default class ConfigurationError extends Vue {
  get size() {
    const { isDesktop, isTablet } = this;
    if (isDesktop || isTablet) {
      return {
        width: 240,
        height: 205,
      };
    }
    return {
      width: 170,
      height: 145,
    };
  }
}
