import { SupportingDocument } from "@frankieone/shared";

type MatchedTypeConfig = Exclude<TDocumentUploadType, string> & {
  typesIndex: number;
};
type Matcher = (
  d: SupportingDocument,
  uploadConfiguration: DocumentUpload
) => MatchedTypeConfig | null;
// We need to check if the supporting document could have been selected by any of the config types
const isObjectType = (
  t: TDocumentUploadType
): t is Exclude<TDocumentUploadType, string> =>
  typeof (t as any).type === "string";
const norm = (v: any) => String(v).toLowerCase();
const type = (t: TDocumentUploadType) =>
  (isObjectType(t) ? t.type : t) as TDocumentType;
const checkHasSameLabel = (
  doc: SupportingDocument,
  { label }: Exclude<TDocumentUploadType, string>
) => norm(doc.label) === norm(label);
// accept list of types both as string or as { type } object, then normalise types before comparing
const attemptToMatchType = (
  doc: SupportingDocument,
  { types }: DocumentUpload
): MatchedTypeConfig | null => {
  const normalisedTypes: string[] = types.map(type).map(norm);
  const normalisedTargetType: string = norm(doc.type);
  const foundIndex = normalisedTypes.findIndex(
    (t) => normalisedTargetType === t
  );
  if (foundIndex < 0) return null;
  const typeConfig = types[foundIndex];
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const isObj = (v: any): v is object => typeof typeConfig === "object";
  return {
    label: isObj(typeConfig) ? typeConfig.label : typeConfig,
    type: isObj(typeConfig) ? typeConfig.type : typeConfig,
    typesIndex: foundIndex,
  };
};

export default function mapSupportingDocsToConfig(
  config: DocumentUpload[],
  supportingDocuments: SupportingDocument[]
): (SupportingDocument | null)[] {
  // The order coming back from the api is not guaranteed so we fix it here
  // descructuring original array to prevent side effects ("sort" changes original array)
  const order = (o: number | null): number =>
    o === null ? Infinity : Number(o);
  const typeAndLabelMatcher: Matcher = (
    doc: SupportingDocument,
    config: DocumentUpload
  ) => {
    const matchedType = attemptToMatchType(doc, config);
    if (!matchedType) return null;
    const hasSameLabel = checkHasSameLabel(doc, matchedType);
    if (!hasSameLabel) return null;
    return matchedType;
  };
  // then by type only
  const typeOnlyMatcher: Matcher = (
    doc: SupportingDocument,
    config: DocumentUpload
  ) => {
    const matchedType = attemptToMatchType(doc, config);
    if (!matchedType) return null;
    const hasSameLabel = checkHasSameLabel(doc, matchedType);
    if (hasSameLabel) return null;
    return matchedType;
  };
  const fixOrder = [...supportingDocuments].sort(
    ({ smartUiDocOrder: a }, { smartUiDocOrder: b }) => order(a) - order(b)
  );
  const mapConfigToSupportingDocumentByTypeAndLabelFirst = (
    _: DocumentUpload | null,
    index: number
  ): SupportingDocument | null => {
    return mapCorrespondingDocumentToConfiguration(
      fixOrder,
      typeAndLabelMatcher,
      config[index],
      index
    );
  };
  const mapConfigToSupportingDocumentByTypeIfNotFoundYet = (
    el: SupportingDocument | null,
    index: number
  ): SupportingDocument | null => {
    if (el === null)
      return mapCorrespondingDocumentToConfiguration(
        fixOrder,
        typeOnlyMatcher,
        config[index],
        index
      );
    return el;
  };
  return (
    config
      // map configuration items to preloaded documents
      // by type and label, first
      // or null when no match occurred
      .map(mapConfigToSupportingDocumentByTypeAndLabelFirst)
      .map(mapConfigToSupportingDocumentByTypeIfNotFoundYet)
      // Finally update the smartUiDocOrder in case they changed to preserve them for this session
      .map((doc, idx) => {
        if (doc !== null) doc.smartUiDocOrder = idx;
        return doc;
      })
  );
}

const mapCorrespondingDocumentToConfiguration = (
  fixOrder: (SupportingDocument | null)[],
  matcher: Matcher,
  uploadConfiguration: DocumentUpload,
  idx: number
): SupportingDocument | null => {
  // fixOrder needs to be a reference to the original array, as side effects are expected

  // attempts to match supportingDocument with configuration according to "matcher" and prioritizing order stabilished in smartUiDocOrder
  // whenever a match is successful, remove document from list, so it doesn't match other configuration items again
  // if no match occurred, make that supportingDocument "null", to flag there's no document preloaded for that position of the configuration
  // --> idx is the index of the current configuration item and
  // --> documentIdx is the preload document corresponding to that index
  const documentIdx = fixOrder[idx];
  type MatchedDocument = {
    documentIndex: number;
    matchedConfig: MatchedTypeConfig;
  } | null;
  const matchIdxFirstThenAnyOther = (matcher: Matcher): MatchedDocument => {
    const idxConfigMatch =
      documentIdx && matcher(documentIdx, uploadConfiguration);
    if (idxConfigMatch) {
      return {
        documentIndex: idx,
        matchedConfig: idxConfigMatch,
      };
    }
    const foundIndex = fixOrder.findIndex(
      (d) => d && matcher(d, uploadConfiguration)
    );
    if (foundIndex > -1) {
      return {
        documentIndex: foundIndex,
        matchedConfig: matcher(fixOrder[foundIndex]!, uploadConfiguration)!,
      };
    }
    return null;
  };

  const match = matchIdxFirstThenAnyOther(matcher);
  // if no match occureed return null
  if (!match) return null;
  const { documentIndex, matchedConfig } = match;
  // if a match occurred, replace it with null on the list and and return matched document
  const document = fixOrder.splice(documentIndex, 1, null)[0]!;
  document.label = matchedConfig.label;

  return document;
};
