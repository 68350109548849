import { CheckSummary, Nullable } from "@frankieone/shared";
import { PersonalStoreModule } from "@/store/modules/personal";

export type ViewOutcome = {
  slug: string;
  issue?: string | null;
  data?: { [k: string]: any };
  nextView?: ViewOutcome;
};
export type ViewObject = { routeName: string; routeData: any };
export type ArgumentFindViewExtra = {
  isPersonalInfoReviewed: boolean;
  attemptCount: number;
  maxAttemptCount: number;
  hasAvailableDocumentsToReview: boolean;
  externalIdv: boolean;
  isCheckingIDs: boolean;
  isCheckingAddress: boolean;
  creditHeaderWarning: boolean;
  personalInfoReviewed: PersonalStoreModule["state"]["personalDetailsChecked"];
};

export function mkViewFromViewOutcome(viewOutcome: ViewOutcome): ViewObject {
  const view: {
    routeName: string;
    routeData: {
      issue?: Nullable<string>;
      nextView?: Nullable<ViewOutcome>;
    };
  } = {
    routeName: viewOutcome.slug,
    routeData: {
      ...viewOutcome.data,
      issue: viewOutcome.issue,
    },
  };
  const { nextView } = viewOutcome;
  if (nextView) {
    view.routeData.nextView = nextView;
  }
  return view;
}

export function mkSuccessViewOutcome(): ViewOutcome {
  return {
    slug: "success",
    issue: null,
    data: {},
  };
}

export function mkPendingViewOutcome(): ViewOutcome {
  return {
    slug: "pending-success",
    issue: null,
    data: {},
  };
}

export function mkExternalIdvViewOutcome(nextView: ViewOutcome): ViewOutcome {
  return {
    slug: "external-idv",
    nextView,
  };
}

export function findViewForCheckResult(
  checkSummary: CheckSummary,
  extra: ArgumentFindViewExtra
): ViewOutcome {
  const {
    isPersonalInfoReviewed: isPersonalReviewed,
    hasAvailableDocumentsToReview: hasDocsAvailable,
    attemptCount: attempts,
    maxAttemptCount: maxAttempts,
    externalIdv,
    isCheckingIDs,
    isCheckingAddress,
    personalInfoReviewed,
  } = extra;

  const hasAlert = (alrt: string) =>
    !!checkSummary.alertList.find((a) => a.term === alrt);
  const hasStatus = (st: string) => checkSummary.status.type === st;
  const hasIssue = (iss: string) => checkSummary?.issues?.[iss];

  const is404And = (condition = true) => hasAlert("404") && condition;
  const isPartialAnd = (condition = true) => hasAlert("partial") && condition;
  const isPassedAnd = (condition = true) =>
    (hasStatus("passed") || hasStatus("pass_manual")) && condition;
  const isSuccess = (slug: string) =>
    ["success", "pending-success"].includes(slug);
  const isFinal = (slug: string) =>
    ["too-many-tries", "success", "pending-success"].includes(slug);

  const isDuplicate = hasAlert("duplicate");
  const isBlacklist = hasAlert("blacklist");
  const overTried = attempts >= maxAttempts;
  const hadTooManyTries = hasAlert("too-many") || overTried;
  const hasIdsToCheck = isCheckingIDs && hasDocsAvailable;

  let slug: string | null = null;
  let issue: string | null = null;
  let data: ViewOutcome["data"] = {};

  // Status
  if (isPassedAnd()) slug = "success";
  else if (isDuplicate || isBlacklist) slug = "too-many-tries";
  else if (
    is404And(!isPersonalReviewed) ||
    isPartialAnd(
      !hasIdsToCheck && isCheckingAddress && !personalInfoReviewed.extraAddress
    )
  )
    slug = "no-match";
  else if (
    isPartialAnd(hasIdsToCheck) ||
    is404And(isPersonalReviewed && hasIdsToCheck)
  )
    slug = "partial-match";
  else if (
    hasAlert("too-many") ||
    isPartialAnd(!hasIdsToCheck && !isCheckingAddress) ||
    isPartialAnd(
      !hasIdsToCheck && isCheckingAddress && personalInfoReviewed.extraAddress
    ) ||
    is404And(isPersonalReviewed && !hasIdsToCheck)
  )
    slug = "too-many-tries";
  else slug = "pending-success";

  // Tried too many times and still didnt pass
  if (hadTooManyTries && !isSuccess(slug)) {
    slug = "too-many-tries";
  }
  // Credit header
  if (hasIssue("creditHeader")) {
    issue = "credit-header-failure";
  }
  // partial + no id + address
  if (isPartialAnd(!hasIdsToCheck && isCheckingAddress)) {
    data = { only: { secondAddress: true } };
  }
  // External idv
  if (externalIdv && isFinal(slug) && !isDuplicate && !isBlacklist) {
    return mkExternalIdvViewOutcome({
      data,
      slug: isSuccess(slug) ? "pending-success" : slug,
      issue,
    });
  }
  return { slug, issue, data };
}
