import { getTypedDocConfigDefaults } from "@/utils/documentFactory";
import validationSchema from "@/utils/validators/validationSchema";
import { DeepPartial, Nullable, countryData, mkHtmlSanitiser } from "@frankieone/shared";
import { cloneDeep, get, mergeWith, set } from "lodash";
import validatejs from "validate.js";
import { DOCUMENT_UPLOADS_DEFAULT, configDefaults } from "./configDefaults";

const htmlParser = require("node-html-parser").parse;

export function stripOutScriptTagFromString(content: string) {
  const extraAllowedTags = ["img"];
  const allowedTags = mkHtmlSanitiser.defaults.allowedTags.concat(extraAllowedTags);
  const sanitiser = mkHtmlSanitiser({
    allowedTags,
    allowedAttributes: false,
  });
  return sanitiser(content);
}
function clearHtmlDotProperty<T extends object>(path: string, userConfiguration: T): T {
  // clears the html string contained in a property of "userConfiguration"
  // referenced using "path" in dot notation
  let htmlContent = get(userConfiguration, path) as string;
  if (typeof htmlContent !== "string") return userConfiguration;

  htmlContent = stripOutScriptTagFromString(htmlContent);
  htmlContent = mkAnchorsTargetBlank(htmlContent);

  set(userConfiguration, path, htmlContent);
  return userConfiguration;
}

export function validate(config: DeepPartial<IWidgetConfiguration>): DeepPartial<IWidgetConfiguration> {
  const validationErrors = validatejs(config, validationSchema);
  if (validationErrors) {
    throw new Error(`Config object is invalid, see below.\n${JSON.stringify(validationErrors, null, 4)}`);
  }
  return config;
}
const merger = (objValue, sourceValue) => {
  if (Array.isArray(sourceValue)) return [...sourceValue]; // if is array, return a new reference to the same array
  return undefined; // otherwise let default algorithm resolve it
};
export function assignDefaults(config: DeepPartial<IWidgetConfiguration>, defaultConfig: IWidgetConfiguration): IWidgetConfiguration {
  return mergeWith(defaultConfig, config, merger);
}
function parseAcceptedCountries(userConfiguration: DeepPartial<IWidgetConfiguration>) {
  const allCountries = countryData.map((c) => c.alpha3Code);
  if (!userConfiguration) return userConfiguration;
  else if (typeof userConfiguration?.acceptedCountries === "undefined") userConfiguration.acceptedCountries = ["AUS"];
  else if (userConfiguration.acceptedCountries === null) userConfiguration.acceptedCountries = allCountries;
  else if (userConfiguration.acceptedCountries === "ALL") userConfiguration.acceptedCountries = allCountries;
  return userConfiguration;
}
export default function (
  userConfiguration: DeepPartial<IWidgetConfiguration> | string // in case configuration is serialised from url. Used to simplify testing with support portal and not documented publicly
): IWidgetConfiguration {
  const deSerialise = (c: string) => JSON.parse(decodeURI(c)) as DeepPartial<IWidgetConfiguration>;
  userConfiguration = typeof userConfiguration === "string" ? deSerialise(userConfiguration) : userConfiguration;
  // pre validation transformations of user configuration
  userConfiguration = throwIDVInjectedCssFieldName(userConfiguration);
  userConfiguration = clearHtmlDotProperty("consentText", userConfiguration);
  userConfiguration = clearHtmlContent("welcomeScreen.htmlContent", userConfiguration);
  userConfiguration = clearHtmlContent("pendingScreen.htmlContent", userConfiguration);
  userConfiguration = parseAcceptedCountries(userConfiguration);
  userConfiguration = replaceIDVObject(userConfiguration);
  userConfiguration = transformConsent(userConfiguration);
  userConfiguration = expandDocumentTypes(userConfiguration);
  userConfiguration = handleDocumentUploadsShortcut(userConfiguration);
  // validation
  const validConfig = validate(userConfiguration ?? {});
  // define default configurations
  let contextualDefaults: IWidgetConfiguration = cloneDeep(configDefaults);

  contextualDefaults = defineIDVDefaults(configDefaults, validConfig);
  return assignDefaults(validConfig, contextualDefaults);
}
function throwIDVInjectedCssFieldName(config: DeepPartial<IWidgetConfiguration>): DeepPartial<IWidgetConfiguration> {
  if (typeof (config?.idScanVerification as any)?.injectCss !== "undefined") {
    throw new Error("Don't use idScanVerification.injectCss. Instead use idScanVerification.injectedCss.");
  }
  return config;
}
function clearHtmlContent<T extends object>(path: string, userConfiguration: T): T {
  const htmlContent = get(userConfiguration, path) as string;
  if (typeof htmlContent !== "string") return userConfiguration;

  set(userConfiguration, path, stripOutScriptTagFromString(htmlContent));
  set(userConfiguration, path, mkAnchorsTargetBlank(htmlContent));

  return userConfiguration;
}
function replaceIDVObject(userConfiguration: DeepPartial<IWidgetConfiguration>): DeepPartial<IWidgetConfiguration> {
  if (userConfiguration?.idScanVerification === true) {
    userConfiguration.idScanVerification = {};
  }

  return userConfiguration;
}
function transformConsent(userConfiguration: DeepPartial<IWidgetConfiguration>): DeepPartial<IWidgetConfiguration> {
  const consentText = () => userConfiguration?.consentText as string;
  if (typeof consentText() === "string") {
    userConfiguration.consentText = stripOutScriptTagFromString(consentText());
  }
  if (typeof consentText() === "string") {
    userConfiguration.consentText = mkAnchorsTargetBlank(consentText());
  }
  return userConfiguration;
}

function mergeDefaltDocTypeConfiguration(docConfig: { type: string }) {
  return mergeWith(getTypedDocConfigDefaults(docConfig.type as IdType), docConfig);
}
function expandDocTypeString(docTypeString: IdType) {
  return getTypedDocConfigDefaults(docTypeString);
}
function expandDocTypeConfiguration(docType: IdType | { type: string; label?: string }, options: { mergeDefaults?: boolean } = {}) {
  const { mergeDefaults = true } = options;
  const typeStringTolabel = (label: string): string => {
    return label[0] + label.slice(1).toLowerCase().replace(/_/g, " ");
  };
  const addDefaultLabels = (obj: { type: string; label?: string }) => ({
    ...obj,
    label: obj.label ?? typeStringTolabel(obj.type),
  });
  if (typeof docType === "string" && mergeDefaults) {
    return expandDocTypeString(docType);
  } else if (typeof docType === "object" && mergeDefaults) {
    return mergeDefaltDocTypeConfiguration(docType);
  } else if (typeof docType === "string") {
    return addDefaultLabels({ type: docType });
  } else return addDefaultLabels(docType ?? {});
}
function expandDocumentTypes(userConfiguration: DeepPartial<IWidgetConfiguration>): DeepPartial<IWidgetConfiguration> {
  type DocConfig = UserDocConfig | TSupportedDocuments; // keep this, even if repeated from global.ts
  const { documentTypes } = userConfiguration ?? {};
  if (!documentTypes) return userConfiguration;
  const mergedDocumentTypes = (documentTypes as DocConfig[]).map((doc) => expandDocTypeConfiguration(doc));
  userConfiguration.documentTypes = mergedDocumentTypes;
  return userConfiguration;
}

export function handleDocumentUploadsShortcut(userConfiguration: DeepPartial<IWidgetConfiguration>): DeepPartial<IWidgetConfiguration> {
  const expandTypes = (up: { types?: TDocumentUploadType[] }) => {
    up.types = up.types?.map((doc) => expandDocTypeConfiguration(doc, { mergeDefaults: false }));
    return up;
  };
  const sanitiseDescription = (up: { description?: string }) => {
    return clearHtmlContent("description", up);
  };
  let documentUploads = userConfiguration?.documentUploads! as IWidgetConfiguration["documentUploads"];
  if (!documentUploads) return userConfiguration;
  // Handle the true shortcut
  // true shortcut wasn't a very good idea for the customer, as it's too specific to a single use case.
  // I Will leave it here for testing purposes, but this use by customers shouldn't be encouraged
  if (documentUploads === true) documentUploads = DOCUMENT_UPLOADS_DEFAULT;
  // for typescript correct type inferrence, also do a explicit check for 'object'
  else if (typeof documentUploads === "object" && documentUploads.uploads?.length) {
    documentUploads.uploads = documentUploads.uploads.map(expandTypes) as DocumentUpload[];
    documentUploads.uploads = documentUploads.uploads.map(sanitiseDescription) as DocumentUpload[];
  }

  userConfiguration.documentUploads = documentUploads;
  return userConfiguration;
}

function defineIDVDefaults(contextualDefaults: IWidgetConfiguration, userConfiguration: DeepPartial<IWidgetConfiguration>): IWidgetConfiguration {
  const { ...newDefaults } = contextualDefaults;
  const { idScanVerification } = userConfiguration;
  const typeofIdScanVerification = typeof idScanVerification;
  if (typeofIdScanVerification === "object") {
    newDefaults.idScanVerification = {
      releaseVersion: "latest",
      welcomeScreen: {
        title: "Verify your identity",
        content: ["We need to collect some personal information to verify your identity before we can open your account."],
        ctaText: "Start Identity Verification",
      },
      useMobileDevice: true,
      useLiveness: false,
      injectedCss: "",
      enableLiveDocumentCapture: false,
      language: null,
    };
  }

  return newDefaults;
}

export function mkAnchorsTargetBlank(content: string) {
  const html = htmlParser(content, { comment: true });
  const as = html.querySelectorAll("a");
  as.forEach((a) => a.setAttribute("target", "_blank"));
  return html.toString();
}

type TMap<T> = (v: any) => T;
const nullOr = <T>(v: any, m: TMap<T>): Nullable<T> => (v === null ? null : m(v));

const bool: TMap<boolean> = (v) => v !== "false" && Boolean(v);
const nBool: TMap<Nullable<boolean>> = (v) => nullOr(v, bool);
const string: TMap<string> = <T = string>(v: T) => String(v);
const object: TMap<object> = (v) => v !== "false" && Object(v);
const nString: TMap<Nullable<string>> = (v) => nullOr(v, string);
const number: TMap<number> = (v) => Number(v);
const nNumber: TMap<Nullable<number>> = (v) => nullOr(v, number);
const strBool: TMap<string | boolean> = (v) => {
  const isBoolean = typeof v === "boolean";
  if (isBoolean) return v;
  if (v === "true") return true;
  if (v === "false") return false;

  return String(v);
};
const nStrBool: TMap<Nullable<string | boolean>> = (v) => nullOr(v, strBool);
// array is used to declare array types: array(bool) will map v:any => boolean[]
type TMapArray = <T>(m: TMap<T>) => TMap<Array<T>>;
const array: TMapArray =
  <T>(m: T) =>
  (v) =>
    v.map(m);

export const type = {
  bool,
  nBool,
  string,
  nString,
  number,
  nNumber,
  strBool,
  nStrBool,
  array,
  object,
};
