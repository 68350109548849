

















import { Component, Vue, Prop } from "vue-property-decorator";
import { Address, AddressAutoComplete } from "@frankieone/shared";
import GooglePlaceClient from "@frankieone/shared/dist/api/GooglePlace";
import ManualAddressInput from "@/components/inputs/ManualAddressInput.vue";
import { resolveAcceptedCountriesConfigurationObject } from "../../utils/resolveAcceptedCountries";

@Component({
  name: "AddressInput",
  components: {
    ManualAddressInput,
    AddressAutoComplete,
  },
})
export default class AddressInput extends Vue {
  @Prop() value: Address;

  @Prop() autocomplete: boolean;

  @Prop({ required: true }) step: CurrentStepObject;

  @Prop() acceptedCountries: string[];

  get acceptedCountriesFromConfiguration(): string[] {
    const addressConfiguration = this.$store.getters.config("requestAddress");

    return resolveAcceptedCountriesConfigurationObject(
      addressConfiguration,
      this.acceptedCountries as string[]
    );
  }

  googlePlaceClient: GooglePlaceClient =
    this.$store.direct.getters.frankie?.getGooglePlaceClient(
      this.googleApiKey,
      { version: "v2" }
    ) as GooglePlaceClient;

  get googleApiKey(): string {
    return this.$store.direct.getters.config("googleAPIKey");
  }

  get address(): Address {
    return this.value;
  }

  get isAllFilled(): boolean {
    return !this.value.isIncomplete;
  }

  setAddress(address: Address | null, nextStep: boolean) {
    if (!address) {
      address = new Address();
    }
    if (!address.addressType) {
      // if address type is not being overwritten by child address inputs,
      // copy address type coming from parent
      // this is just to prevent addresstype from being wiped out by child
      const { addressType, data } = this.value;
      address.addressType = addressType || "RESIDENTIAL1";
      if (data["widget-address-creation"])
        address.data["widget-address-creation"] = true;
    }
    if (!address.country) {
      // if address has no country defined
      // copy address country coming from parent
      const { country } = this.value;
      address.country = country;
    }
    this.$emit("input", address.clone());
    if (nextStep) this.emitAutocompleteSelected();
  }

  async emitAutocompleteSelected() {
    this.$emit("autocompleteSelected");
  }

  emitIsAllFilled(value: boolean) {
    this.$emit("isAllFilled", value);
  }
}
