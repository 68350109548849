











import { Component, Vue } from "vue-property-decorator";
import OutcomeMessage from "@/components/OutcomeMessage.vue";

@Component({
  name: "CreditHeaderFailure",
  components: {
    OutcomeMessage,
  },
})
export default class CreditHeaderFailure extends Vue {
  nextStep() {
    this.$store.direct.dispatch.nextStep();
  }
}
