export * from './aus-blueprint';
export * from './nzl-blueprint';

import {
  docNumberValidationPerState,
  licenceNumberValidationPerState,
  mkAUSDriverLicenceBlueprint,
} from '@/documents/driversLicence/aus-blueprint';
import {
  idNumberValidation,
  mkNZLDriversLicenceBlueprint,
  versionNumberValidation,
} from '@/documents/driversLicence/nzl-blueprint';
import { getCountryName } from '@/utils/country';
import { Document, DynamicUIComponent, GetPhraseFunction, UIBlueprint } from '@frankieone/shared';
import { mkGenericDriversLicenceBlueprint } from './generic-blueprint';
import { feature } from '@/store/features';

export type LicenceValidation = {
  mask: string;
  minLength?: number;
  maxLength?: number;
  regex?: RegExp;
};
export type Context = {
  getCompleteDriverLicence: () => Document;
  setCompleteDriverLicence: (document: Document) => void;
  getShowDigitalLicenceOption: () => boolean;
  getPhrase: GetPhraseFunction;
  getDocumentNumberOptions: () => string[];
  getCountryOptions: () => string[];
  onFocusListener: Callback;
};

export type Validation = { validation?: LicenceValidation; disabled?: boolean };
export type Callback = (v: string) => void;
export type Field = 'consent' | 'country' | 'region' | 'idNumber' | 'documentNumber' | 'versionNumber';
/**
 * Generate a driver's licence blueprint based on the current context
 * The supported driver's licence countries are only "AUS" and "NZL"
 * - If the provided DL Document contains a country other than the defined in the context object and there is more than one country defined, the blueprint will first clear the country field
 * - Otherwise, if there is only one country defined, the blueprint will set the country to that country
 * - If no country is defined, the blueprint will render a generic country selection dropdown
 * - If the country is "AUS" or "NZL", the blueprint will render the corresponding blueprint
 *
 * Each blueprint factory function takes the same context object and factory for setter functions for each field
 * getSetterFor: (f: Field) => Setter will return a setter function for the given field and is used to update the document field by field
 */
export function mkDriversLicenceBlueprint(context: Context): UIBlueprint | void {
  const document = context.getCompleteDriverLicence();
  const getSelectedCountry = () => <string>document.country;
  const countryOptions = context.getCountryOptions();
  const setCompleteDriverLicence = context.setCompleteDriverLicence;
  const getSetterFor = (f: Field) => (v: string | null) => {
    if (f === 'consent') {
      document.extraData.digital_licence = v;
    } else if (f === 'region') {
      document.region = v;
      document.extraData.digital_licence = null;
    } else if (f === 'documentNumber') {
      document.extraData.document_number = v;
    } else if (f === 'versionNumber') {
      document.extraData.licence_version = v;
    } else {
      document[f] = v;
    }
    setCompleteDriverLicence(document);
  };

  const setCountry = getSetterFor('country');

  // when selected country does not match any of the provided country options,
  // take the first available option to replace
  if (!countryOptions.includes(getSelectedCountry())) {
    setCountry(countryOptions[0]);
  }

  // if the country code does not match with a real country from country data
  // set the country to null, then it will display dropdown for user to choose
  if (!getCountryName(getSelectedCountry())) {
    setCountry(null);
  }

  if (getSelectedCountry() === 'AUS') {
    return mkAUSDriverLicenceBlueprint(context, getSetterFor);
  } else if (getSelectedCountry() === 'NZL') {
    return mkNZLDriversLicenceBlueprint(context, getSetterFor);
  } else {
    // For any other country, return the generic blueprint
    return mkGenericDriversLicenceBlueprint(context, getSetterFor);
  }
}

/** This blueprint is only used on invalid states or when no country was selected yet */
function mkCountryBlueprint(context: Context, getSetterFor: (f: Field) => Callback) {
  const setCountry = getSetterFor('country');

  return new UIBlueprint({
    children: {
      // If in invalid state, signal to country input that
      // it shouldn't even attempt to render options for the country dropdown by not providing a context object
      country: mkCountryInput(context, setCountry),
    },
  });
}

export const mkLicenceNumberInput = (context: Context & Validation, callback: Callback) => {
  const { getPhrase, getCompleteDriverLicence, onFocusListener } = context;

  const document = getCompleteDriverLicence();
  const { country, idNumber, region } = document;

  const placeholderPhrase = () => {
    const key = `drivers_licence_input_screen.licence_number.placeholder`;
    // for country with regions and region is selected, e.g. AUS
    if (getPhrase(`${key}.${country}.${region}`)) return getPhrase(`${key}.${country}.${region}`);

    // for country with regions and region is NOT selected, e.g. AUS
    if (typeof getPhrase(`${key}.${country}`) === 'object')
      return getPhrase('document.type_drivers_licence.licence_number');

    // for country with no region, e.g. NZL
    if (getPhrase(`${key}.${country}`)) return getPhrase(`${key}.${country}`);

    // when no phrases in config or default values
    return getPhrase('document.type_drivers_licence.licence_number');
  };

  return new DynamicUIComponent({
    tag: 'generic-input',
    classes: ['ff-licence-input'],
    attrs: {
      'data-qa': 'licence-number-input',
      'data-recording-disable': true,
      value: idNumber?.toUpperCase() || '',
      label: getPhrase('document.type_drivers_licence.licence_number', {
        isMandatory: true,
      }),
      placeholder: placeholderPhrase(),
      disabled: context.disabled ?? false,
      mask: context.validation?.mask ?? null,
      removeSpaces: true,
    },
    listeners: {
      input: callback,
      focus: () => onFocusListener(`licence_number`),
    },
  });
};

export const mkCountryInput = (context: Context, callback: Callback = () => void 0) => {
  const selectedCountry = context.getCompleteDriverLicence().country;
  const countries = context.getCountryOptions();
  const options = countries
    .map((c) => ({
      value: c,
      label: getCountryName(c),
    }))
    .filter(({ label }) => !!label);

  return new DynamicUIComponent({
    tag: 'select-option',
    classes: ['ff-country-select', 'ff-dropdown', 'ff-licence-input', 'no-margin-top'],
    attrs: {
      'data-qa': 'country-input',
      'data-recording-disable': true,
      options,
      filterable: true,
      value: selectedCountry,
      label: context.getPhrase('document.type_drivers_licence.country', {
        isMandatory: true,
      }),
      placeholder: context.getPhrase('document.type_drivers_licence.country'),
    },
    listeners: {
      input: callback,
    },
  });
};

/**
 * Validates a license number based on the document information.
 *
 * @param {Document} document - The document containing the license information.
 * @returns {boolean} - True if the license number is valid, otherwise false.
 */
export const validateLicenceNumber = (document: Document): boolean => {
  const enhancedValidation = feature('enhancedValidation');
  if (!enhancedValidation) return true;

  if (document.country === 'AUS') {
    const { regex } = licenceNumberValidationPerState(document.region);
    const idNumber = document.idNumber?.toString() ?? '';
    return regex ? regex.test(idNumber) : true;
  } else if (document.country === 'NZL') {
    const { regex } = idNumberValidation();
    const idNumber = document.idNumber?.toString() ?? '';
    return regex ? regex.test(idNumber) : true;
  }
  return true;
};

/**
 * Validates an extra number based on the document information.
 *
 * @param {Document} document - The document containing the extra number information.
 * @returns {boolean} - True if the extra number is valid, otherwise false.
 */
export const validateExtraNumber = (document: Document): boolean => {
  const enhancedValidation = feature('enhancedValidation');
  if (!enhancedValidation) return true;

  if (document.country === 'AUS') {
    const { regex } = docNumberValidationPerState(document.region);
    const cardNumber = document.extraData.document_number?.toString() ?? '';
    return regex ? regex.test(cardNumber) : true;
  } else if (document.country === 'NZL') {
    const { regex } = versionNumberValidation();
    const licenceVersion = document.extraData.licence_version?.toString() ?? '';
    return regex ? regex.test(licenceVersion) : true;
  }
  return true;
};
