export interface EnvironmentCheck {
  environmentMode: () => string;
  isDemo: () => boolean;
  isDevelopment: () => boolean;
  isProduction: () => boolean;
}
export interface ILogger {
  info(environment: string, ...message: any[]);
  error(environment: string, ...message: any[]);
}

function findShouldLog(environment: string, env: EnvironmentCheck): boolean {
  let shouldLog = false;
  switch (environment) {
    case "development":
      {
        shouldLog = env.isDevelopment();
      }
      break;
    case "production":
      {
        shouldLog = env.isProduction();
      }
      break;
    case "demo":
      {
        shouldLog = env.isDemo();
      }
      break;
    case "all":
      shouldLog = true;
      break;
    default:
      shouldLog = false;
      break;
  }
  if (window?.location?.search) {
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get("ff-widget-verbose");
    shouldLog = myParam !== null;
  }
  return shouldLog;
}

const loggerFactory = (config: { env: EnvironmentCheck }): ILogger => {
  const { env } = config;
  return {
    info(environment: string, ...messages: any[]) {
      const shouldLog = findShouldLog(environment, env);
      if (shouldLog) {
        console.log("-- ff widget:");
        console.log("\t", ...messages);
      }
    },
    error(environment: string, ...messages: any[]) {
      const shouldLog = findShouldLog(environment, env);
      if (shouldLog) {
        console.log("-- ff widget error");
        console.error("\t-- ", ...messages);
      }
    },
  };
};

export default loggerFactory;
