import { render, staticRenderFns } from "./DateOfBirthInput.vue?vue&type=template&id=036dbe6f&scoped=true&lang=pug&"
import script from "./DateOfBirthInput.vue?vue&type=script&lang=ts&"
export * from "./DateOfBirthInput.vue?vue&type=script&lang=ts&"
function injectStyles (context) {
  
  var style0 = require("./DateOfBirthInput.vue?vue&type=style&index=0&id=036dbe6f&lang=sass&scoped=true&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "036dbe6f",
  null
  ,true
)

export default component.exports