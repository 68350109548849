import { DynamicUIComponent, UIBlueprint } from "@frankieone/shared";
import { Context } from "./blueprint";

export function mkGenericBlueprint(context: Context): UIBlueprint {
  const { getAcceptedCountries, getDocument, getPhrase, setDocument } = context;
  const acceptedCountries = getAcceptedCountries();
  const countriesCount = acceptedCountries.length;
  const document = getDocument();
  const { country, idNumber } = document;

  const mkNationalIdInput = () => {
    return new DynamicUIComponent({
      tag: "generic-input",
      classes: ["ff-national-id-number"],
      attrs: {
        label: getPhrase("document.type_national_id.identification_number", {
          isMandatory: true,
        }),
        value: idNumber,
        placeholder: getPhrase(
          "document.type_national_id.identification_number"
        ),
        tabindex: 0,
        mask: getMaskForCountry(country ?? ""),
      },
      listeners: {
        input: (v: string) => setValue(v),
      },
    });
  };
  const setValue = (value: string) => {
    document.idNumber = value;
    setDocument(document);
  };

  const getMaskForCountry = (country: string) => {
    if (country === "IDN") return "################";
    if (country === "THA") return "# #### ##### ## #";
    if (country === "SGP") return "XXXXXXXXX";
    return "";
  };

  const children = {};
  if (countriesCount > 1)
    children["country-select"] = mkCountryInputComponent(context);
  if (country) children["national-idNumber"] = mkNationalIdInput();

  return new UIBlueprint({
    root: new DynamicUIComponent({
      tag: "div",
      classes: ["ff-national-id-input"],
    }),
    children,
  });
}

function mkCountryInputComponent(context: Context): DynamicUIComponent {
  const { getPhrase, getAcceptedCountries, getDocument, setDocument } = context;
  const acceptedCountries = getAcceptedCountries();
  const document = getDocument();
  const { country } = document;
  const setValue = (value: string) => {
    document.country = value;
    document.idNumber = null;
    document.extraData.laser_code = null;
    setDocument(document);
  };
  return new DynamicUIComponent({
    tag: "country-input",
    classes: ["ff-national-id-country", "country"],
    attrs: {
      label: getPhrase("document.type_national_id.country", {
        isMandatory: true,
      }),
      options: acceptedCountries,
      filterable: true,
      placeholder: getPhrase("document.type_national_id.country"),
      value: country,
    },
    listeners: {
      input: setValue,
    },
  });
}
