import { Profile } from "@frankieone/shared";

const isProfileInstance = (p: any): p is Profile => p instanceof Profile;
export const mkElectronicProfile = (type: string | Profile) => {
  const typeString = isProfileInstance(type) ? type.getProfileType()! : type;
  return new Profile(typeString, "electronic");
};
export const isProfileEmpty = (p: Profile | null) => {
  if (!p) return true;
  if (p.getProfileType() === "none") return true;
  return false;
};
