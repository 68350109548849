import { Document, DynamicUIComponent, UIBlueprint } from '@frankieone/shared';
import { Callback, Context, Field, LicenceValidation, Validation, mkLicenceNumberInput } from './blueprint';
import { mkGenericDriversLicenceBlueprint } from './generic-blueprint';
import { feature } from '@/store/features';
/**
 * Australia Driver Licence Blueprint factory
 *
 * This file contains a function to generate the blueprint for the Australian Driver Licence
 * It was extracted from the original Driver's Licence Blueprint factory, which only considered Australia.
 * This function is called from the generic drivers licence blueprint factory (aka the root [factory] function), which is called from the Drivers Licence Input vue component
 * The Australian drivers licence UI contains the following fields:
 * - country
 * - region (state)
 * - idNumber (licence number)
 * - documentNumber
 * - consent (digital licence consent)
 */
export const STATES_WITH_DIGITAL_LICENCE = ['NSW', 'SA'];

const ALL_AUS_STATES = ['ACT', 'NSW', 'SA', 'TAS', 'WA', 'NT', 'VIC', 'QLD'] as const;

export type AusState = typeof ALL_AUS_STATES[number];

export type AusVariant = 'licence_number' | 'document_number' | 'name' | null;

export function mkAUSDriverLicenceBlueprint(context: Context, getSetterFor: (f: Field) => Callback): UIBlueprint {
  const { getCompleteDriverLicence, getShowDigitalLicenceOption, getDocumentNumberOptions, getCountryOptions } =
    context;

  const genericBlueprint = mkGenericDriversLicenceBlueprint(context, getSetterFor);

  const document = getCompleteDriverLicence();
  const { region } = document;
  const statesRequiringDocumentNumber = getDocumentNumberOptions();

  const children = {};

  if (genericBlueprint.getChild('country')) children['country'] = genericBlueprint.getChild('country');

  children['region'] = mkStateInput(context, getSetterFor('region'));
  children['idNumber'] = mkLicenceNumberInput(
    {
      ...context,
      validation: licenceNumberValidationPerState(region),
      disabled: !region,
    },
    getSetterFor('idNumber'),
  );

  if (statesRequiringDocumentNumber.includes(region!)) {
    children['documentNumber'] = mkDocumentNumberInput(
      {
        ...context,
        validation: docNumberValidationPerState(region),
        disabled: !region,
      },
      getSetterFor('documentNumber'),
    );
  }

  if (getShowDigitalLicenceOption() && STATES_WITH_DIGITAL_LICENCE.includes(region || '')) {
    children['consent'] = mkDigitalConsentInput(context, getSetterFor('consent'));
  }

  genericBlueprint.setChildren(children);
  return genericBlueprint;
}

const mkStateInput = (context: Context, callback: Callback) => {
  const { getPhrase, getCompleteDriverLicence } = context;
  const document = getCompleteDriverLicence();
  const { region } = document;

  return new DynamicUIComponent({
    tag: 'state-input',
    classes: ['ff-state-select', 'ff-dropdown', 'ff-licence-input'],
    attrs: {
      'data-qa': 'state-input',
      'data-recording-disable': true,
      filterable: true,
      label: getPhrase('document.type_drivers_licence.state', {
        isMandatory: true,
      }),
      placeholder: getPhrase('document.type_drivers_licence.state'),
      value: region,
    },
    listeners: {
      input: callback,
    },
  });
};

const mkDigitalConsentInput = (context: Context, callback: Callback) => {
  const { getPhrase, getCompleteDriverLicence } = context;
  const document = getCompleteDriverLicence();
  const { extraData } = document;

  return new DynamicUIComponent({
    tag: 'consent-input',
    classes: ['ff-digital-licence-input'],
    attrs: {
      'data-qa': 'digital-licence-input',
      value: !!extraData?.digital_licence,
      consentText: getPhrase('document.type_drivers_licence.digital_consent'),
    },
    listeners: {
      input: callback,
    },
  });
};

export const mkDocumentNumberInput = (context: Context & Validation, callback: Callback) => {
  const { getPhrase, getCompleteDriverLicence, getDocumentNumberOptions, onFocusListener } = context;

  const document = getCompleteDriverLicence();
  const { country, region, extraData } = document;
  const statesRequiringDocumentNumber = getDocumentNumberOptions();

  return new DynamicUIComponent({
    tag: 'generic-input',
    classes: ['ff-licence-input'],
    id: 'documentNumber',
    attrs: {
      'data-qa': 'document-number-input',
      'data-recording-disable': true,
      value: extraData.document_number ?? '',
      label: getPhrase('document.type_drivers_licence.card_number', {
        isMandatory: statesRequiringDocumentNumber.includes(region!),
      }),
      placeholder:
        // for country with regions, e.g. AUS
        getPhrase(`drivers_licence_input_screen.document_number.placeholder.${country}.${region}`) ||
        // for country with no region, e.g. NZL
        getPhrase(`drivers_licence_input_screen.document_number.placeholder.${country}`) ||
        // no phrases in config or default values
        getPhrase('document.type_drivers_licence.card_number'),
      removeSpaces: true,
      disabled: context.disabled ?? false,
      mask: context.validation?.mask ?? null,
    },
    listeners: {
      input: callback,
      focus: () => onFocusListener(`document_number`),
    },
  });
};

export const docNumberValidationPerState = (region: Document['region']): LicenceValidation => {
  const rules = {
    // ACT: Alphanumeric, 10
    ACT: {
      mask: 'X'.repeat(10),
      minLength: 10,
      maxLength: 10,
      regex: /^[a-z\d]{10}$/i,
    },
    // NT: Numeric, 6 to 8
    NT: {
      mask: '#'.repeat(8),
      minLength: 6,
      maxLength: 8,
      regex: /^\d{6,8}$/,
    },
    // Qld: Alphanumeric, 10
    QLD: {
      mask: 'X'.repeat(10),
      minLength: 10,
      maxLength: 10,
      regex: /^[a-z\d]{10}$/i,
    },
    // NSW: Numeric, 10
    NSW: {
      mask: '#'.repeat(10),
      minLength: 10,
      maxLength: 10,
      regex: /^\d{10}$/,
    },
    // SA: Alphanumeric, 9
    SA: {
      mask: 'X'.repeat(9),
      minLength: 9,
      maxLength: 9,
      regex: /^[a-z\d]{9}$/i,
    },
    // Tas: Alphanumeric, 9
    TAS: {
      mask: 'X'.repeat(9),
      minLength: 9,
      maxLength: 9,
      regex: /^[a-z\d]{9}$/i,
    },
    // Vic: Alphanumeric, 8
    VIC: {
      mask: 'X'.repeat(8),
      minLength: 8,
      maxLength: 8,
      regex: /^[a-z\d]{8}$/i,
    },
    // WA: Alphanumeric, 8 to 10
    WA: {
      mask: 'X'.repeat(10),
      minLength: 8,
      maxLength: 10,
      regex: /^[a-z\d]{8,10}$/i,
    },
    default: {
      mask: 'X'.repeat(15),
      minLength: 1,
      maxLength: 15,
      regex: /^[a-z\d]{1,15}$/i,
    },
  };
  // When enhancedValidation is disabled, use old validation rules
  // This prevents breaking changes while our testbed is not updated
  if (!feature('enhancedValidation')) {
    Object.keys(rules).forEach((key) => {
      rules[key].mask = 'X'.repeat(rules[key].maxLength);
      delete rules[key].regex;
    });
  }
  return rules[region!] || rules['default'];
};

export const licenceNumberValidationPerState = (region: Document['region']): LicenceValidation => {
  const rules = {
    // ACT: Numeric, up to 10
    ACT: {
      mask: '#'.repeat(10),
      minLength: 1,
      maxLength: 10,
      regex: /^\d{1,10}$/i,
    },
    // NT: Numeric, up to 10
    NT: {
      mask: '#'.repeat(10),
      minLength: 1,
      maxLength: 10,
      regex: /^\d{1,10}$/i,
    },
    // Qld: Numeric, 8 to 9
    QLD: {
      mask: '#'.repeat(9),
      minLength: 8,
      maxLength: 9,
      regex: /^\d{8,9}$/i,
    },
    // NSW: Alphanumeric, 6 to 8
    NSW: {
      mask: 'X'.repeat(8),
      minLength: 6,
      maxLength: 8,
      regex: /^[a-z\d]{6,8}$/i,
    },
    // SA: Alphanumeric, 6
    SA: {
      mask: 'X'.repeat(6),
      minLength: 6,
      maxLength: 6,
      regex: /^[a-z\d]{6}$/i,
    },
    // Tas: Alphanumeric, 6 to 8
    TAS: {
      mask: 'X'.repeat(8),
      minLength: 6,
      maxLength: 8,
      regex: /^[a-z\d]{6,8}$/i,
    },
    // Vic: Numeric, up to 10
    VIC: {
      mask: '#'.repeat(10),
      minLength: 1,
      maxLength: 10,
      regex: /^\d{1,10}$/i,
    },
    // WA: Numeric, 7
    WA: {
      mask: '#'.repeat(7),
      minLength: 7,
      maxLength: 7,
      regex: /^\d{7}$/i,
    },
    default: {
      mask: 'X'.repeat(15),
      minLength: 1,
      maxLength: 15,
      regex: /^[a-z\d]{1,15}$/i,
    },
  };
  // When enhancedValidation is disabled, use old validation rules
  // This prevents breaking changes while our testbed is not updated
  if (!feature('enhancedValidation')) {
    Object.keys(rules).forEach((key) => {
      delete rules[key].regex;
    });
  }
  return rules[region!] || rules.default;
};
