






































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { Applicant, Address, Document } from "@frankieone/shared";
import DateOfBirthInput from "@/components/inputs/DateOfBirthInput.vue";
import FullNameInput from "@/components/inputs/FullNameInput.vue";
import AddressInput from "@/components/inputs/AddressInput.vue";
import AddNewAddressInput from "@/components/inputs/AddNewAddressInput.vue";
import {
  getDocumentImageForDocument,
  getNationalIdImage,
} from "@/utils/documentFactory";
import { snapshot } from "@/utils/stringify";
import { mkAddress } from "@/utils/address";
import { COMPONENTS } from "../store/modules/system/routes";
import { AusState } from "@/documents/driversLicence/aus-blueprint";

@Component({
  name: "PersonalForm",
  components: {
    DateOfBirthInput,
    FullNameInput,
    AddressInput,
    AddNewAddressInput,
  },
})
export default class PersonalForm extends Vue {
  @Prop({ required: true }) step: CurrentStepObject;

  @Prop({ required: true }) value: Applicant;

  @Prop() documents: Document[];

  @Prop() hasMedicareDoc: boolean;

  @Prop() displayMiddleNameValue: string | null;

  @Prop() middleNameOption: string;

  @Prop({ default: "default" }) mode: "default" | "check" | "edit";

  @Prop() autocomplete: boolean;

  @Prop() acceptedCountries: string[];

  @Prop() acceptedAgeRange: [number, number];

  @Prop() fieldNames: FieldName[];

  @Prop({ default: false }) dualName: boolean;

  @Prop() dobType: TDOBTypeSupport;

  isAllFilled = false;

  buffer = new Applicant();

  documentRegion: AusState | null = null;

  get hasDualName(): boolean {
    const hasDualNameInData = Boolean(
      this.value.extraData.home_country_givenname ||
        this.value.extraData.home_country_familyname
    );
    const hasDualNameInPath = this.dualName;
    return hasDualNameInData || hasDualNameInPath;
  }

  getExtraAddress(): Address {
    return this.getAddress("RESIDENTIAL2");
  }

  getFirstAddress(): Address | null {
    // return this.buffer.addresses[0] ?? null;
    return this.getAddress("RESIDENTIAL1");
  }

  getAddress(type: Address["addressType"]): Address {
    return this.buffer.getAddress(type) as Address;
  }

  get isButtonDisplayed(): boolean {
    const isAddressEmpty = (a?: Address | null) => !a || a.isIncomplete;
    const isMainAddressAutocompleteEmpty =
      isAddressEmpty(this.getFirstAddress()) &&
      this.isFirstStep(this.IDS.INPUT_ADDR_AUTO);
    const isExtraAddressAutocompleteEmpty =
      isAddressEmpty(this.getExtraAddress()) &&
      this.isExtraAddressStep(this.IDS.INPUT_ADDR_AUTO);

    if (isMainAddressAutocompleteEmpty) return false;
    if (isExtraAddressAutocompleteEmpty) return false;
    if (this.isExtraAddressStep(this.IDS.INPUT_ADDR_ADD_EXTRA)) return false;
    return true;
  }

  get isEditMode(): boolean {
    return this.mode === "edit";
  }

  get isCheckMode(): boolean {
    return this.mode === "check";
  }

  get isDefaultMode(): boolean {
    return this.mode === "default";
  }

  get documentImage(): DynamicComponent | null {
    if (!this.documents?.length) return null;
    // Does user have national id from thailand?
    const nationalID = this.documents?.find((d) => d.idType === "NATIONAL_ID");
    const hasNationalIDFrom = (country: string) =>
      nationalID && nationalID.country === country;
    const isCurrentStep = (slug: string) => this.step.slug === slug;
    // in such case, if use is currently on name inputs, show national id highlighting name (native and english versions)
    if (hasNationalIDFrom("THA") && isCurrentStep("INPUT_NAME"))
      return getNationalIdImage("THA", "front-name-english");
    if (hasNationalIDFrom("THA") && isCurrentStep("INPUT_NAME_EXTRA"))
      return getNationalIdImage("THA", "front-name");
    if (
      hasNationalIDFrom("SGP") &&
      (isCurrentStep("INPUT_NAME") || isCurrentStep("INPUT_NAME_EXTRA"))
    )
      return getNationalIdImage("SGP", "front-name");

    const lastInsertedDocument = this.documents[this.documents.length - 1];
    const component = getDocumentImageForDocument(lastInsertedDocument);

    this.documentRegion = lastInsertedDocument.region as AusState;

    if (!component) return null;
    return component;
  }

  get isDriversLicenceDocument(): boolean {
    if (!this.documents?.length) return false;
    return (
      this.documents[this.documents.length - 1].idType === "DRIVERS_LICENCE"
    );
  }

  isExtraAddressStep(slug?: string) {
    const isExtra = this.isExtraAddress;
    const isExpectedInner = !slug || this.step.slug === slug;
    return isExtra && isExpectedInner;
  }

  isFirstStep(slug?: string) {
    const isFirst = this.isFirstAddress;
    const isExpectedInner = !slug || this.step.slug === slug;
    return isFirst && isExpectedInner;
  }

  currentStepSlugIs(slug: COMPONENT): boolean {
    return this.step.slug === slug || this.step?.parent?.slug === slug;
  }

  componentIsVisible(slug: COMPONENT) {
    if (!slug) return false;
    if (this.currentStepSlugIs(slug)) return true;
    if (slug === "INPUT_NAME" && this.currentStepSlugIs("INPUT_NAME_EXTRA"))
      return true;
    return false;
  }

  emitSubmit() {
    this.emitInput();
    let hasChanged = false;
    if (this.currentStepSlugIs(this.IDS.INPUT_NAME))
      hasChanged = this.hasNameChanged;
    else if (this.currentStepSlugIs(this.IDS.INPUT_DOB))
      hasChanged = this.hasDobChanged;
    else if (this.isFirstStep()) hasChanged = this.hasFirstAddressChanged;
    else if (this.isExtraAddressStep())
      hasChanged = this.hasExtraAddressChanged;
    this.$emit("submit", hasChanged);
  }

  emitInput() {
    this.$emit("input", this.buffer.clone());
  }

  emitMiddleNameValue($event) {
    this.$emit("update:middleNameValue", $event);
  }

  getFieldNamesForNameInput(): FieldName[] {
    if (this.step.slug === "INPUT_NAME_EXTRA") {
      return ["first", "last"];
    }
    return this.fieldNames;
  }

  getHasMedicareDocForNameInput(): boolean {
    if (this.step.slug === "INPUT_NAME_EXTRA") return false;
    return this.hasMedicareDoc;
  }

  getApplicant(): Applicant {
    const bufferClone = this.buffer.clone();
    const nameObject: Applicant["name"] = {
      givenName: "",
      familyName: "",
      middleName: "",
      displayName: "",
    };
    if (this.step.slug === "INPUT_NAME_EXTRA") {
      nameObject.givenName =
        (this.buffer.extraData.home_country_givenname as string) ?? "";
      nameObject.familyName =
        (this.buffer.extraData.home_country_familyname as string) ?? "";
      const middleName = this.buffer.extraData
        .home_country_middlename as string;
      if (middleName) nameObject.middleName = middleName ?? null;
    } else {
      Object.assign(nameObject, this.buffer.name);
    }
    this.$set(bufferClone, "name", nameObject);
    return bufferClone;
  }

  setApplicant(applicant: Applicant) {
    if (this.step.slug === "INPUT_NAME_EXTRA") {
      const { name } = applicant;
      this.$set(
        this.buffer.extraData,
        "home_country_givenname",
        name.givenName
      );
      this.$set(
        this.buffer.extraData,
        "home_country_familyname",
        name.familyName
      );
      if (name.middleName) {
        this.$set(
          this.buffer.extraData,
          "home_country_middlename",
          name.middleName
        );
      }
    } else {
      this.buffer = applicant;
    }
  }

  setDOB(dob: Applicant["dateOfBirth"]) {
    this.buffer.dateOfBirth = dob;
  }

  setFirstAddress(address: Address) {
    const index = this.buffer.addresses.findIndex((add) =>
      ["RESIDENTIAL1", "RESIDENTIAL"].includes(add.addressType || "")
    );
    this.buffer.addresses.splice(index, 1, address);
  }

  setExtraAddress(address: Address) {
    const index = this.buffer.addresses.findIndex(
      (add) => add.addressType === "RESIDENTIAL2"
    );
    this.buffer.addresses.splice(index, 1, address);
  }

  setIsAllFilled(value: boolean) {
    this.isAllFilled = value;
  }

  hasAddressCreationFlag(type: Address["addressType"]) {
    const address = this.buffer.getAddress(type);
    return Boolean(address?.data["widget-address-creation"]);
  }

  addFirstAddress(addNew: boolean) {
    const type: Address["addressType"] = "RESIDENTIAL1";
    // either add new extra address, or remove it
    if (addNew) {
      const address = mkAddress(type);
      address.data["widget-address-creation"] = true;
      this.buffer.addresses.push(address);
    } else {
      const finder = (a: Address) =>
        ["RESIDENTIAL", "RESIDENTIAL1"].includes(a.addressType as string);
      const index = this.buffer.addresses.findIndex(finder);
      this.buffer.addresses.splice(index, 1);
    }
  }

  addExtraAddress(addNew: boolean) {
    const type: Address["addressType"] = "RESIDENTIAL2";
    // either add new extra address, or remove it
    if (addNew) {
      const address = mkAddress(type);
      address.data["widget-address-creation"] = true;
      this.buffer.addresses.push(address);
    } else {
      const index = this.buffer.addresses.findIndex(
        (add) => add.addressType === "RESIDENTIAL2"
      );
      this.buffer.addresses.splice(index, 1);
    }
    this.$emit("newAddressDecision", addNew);
  }

  hasChanged(a, b): boolean {
    return snapshot(a) !== snapshot(b);
  }

  get hasNameChanged(): boolean {
    return this.hasChanged(this.value.name, this.buffer.name);
  }

  get hasExtraNameChanged(): boolean {
    const mkPartialNameObject = (
      app: Applicant
    ): Omit<Applicant["name"], "displayName"> => ({
      givenName: (app.extraData.home_country_givenname as string) ?? "",
      familyName: (app.extraData.home_country_familyname as string) ?? "",
      middleName: (app.extraData.home_country_middlename as string) ?? "",
    });
    const applicantExtraName = mkPartialNameObject(this.value);
    const bufferExtraName = mkPartialNameObject(this.buffer);
    return this.hasChanged(applicantExtraName, bufferExtraName);
  }

  get hasDobChanged(): boolean {
    return this.hasChanged(this.value.dateOfBirth, this.buffer.dateOfBirth);
  }

  get hasFirstAddressChanged(): boolean {
    return this.hasChanged(
      this.value.getAddress("RESIDENTIAL1"),
      this.buffer.getAddress("RESIDENTIAL1")
    );
  }

  get hasExtraAddressChanged(): boolean {
    return this.hasChanged(
      this.value.getAddress("RESIDENTIAL2") ?? {},
      this.buffer.getAddress("RESIDENTIAL2") ?? {}
    );
  }

  get buttonText(): string {
    const isSlug = (slug) =>
      this.step.slug === slug || this.step?.parent?.slug === slug;
    const saveButtonCta = this.getPhrase("common.save_button_cta");
    const confirmButtonCta = this.getPhrase("common.confirm_button_cta");
    const nextButtonCta = this.getPhrase("common.next_button_cta");

    if (this.isCheckMode) {
      const nameAndChanged = isSlug(this.IDS.INPUT_NAME) && this.hasNameChanged;
      const dobAndChanged = isSlug(this.IDS.INPUT_DOB) && this.hasDobChanged;
      const addr1AndChanged =
        this.isFirstStep(this.IDS.INPUT_ADDR_MANUAL) &&
        this.hasFirstAddressChanged;
      const addr2AndChanged =
        this.isExtraAddressStep(this.IDS.INPUT_ADDR_MANUAL) &&
        (this.hasAddressCreationFlag("RESIDENTIAL2") ||
          this.hasExtraAddressChanged);

      if (nameAndChanged) return saveButtonCta;
      if (dobAndChanged) return saveButtonCta;
      if (addr1AndChanged) return saveButtonCta;
      if (addr2AndChanged) return saveButtonCta;

      return confirmButtonCta;
    }

    if (this.isEditMode) {
      const nameAndChanged = isSlug(this.IDS.INPUT_NAME) && this.hasNameChanged;
      const dobAndChanged = isSlug(this.IDS.INPUT_DOB) && this.hasDobChanged;
      const addr1AndChanged =
        this.isFirstStep(this.IDS.INPUT_ADDR_MANUAL) &&
        this.hasFirstAddressChanged;
      const addr2AndChanged =
        this.isExtraAddressStep(this.IDS.INPUT_ADDR_MANUAL) &&
        this.hasExtraAddressChanged;

      if (nameAndChanged) return saveButtonCta;
      if (dobAndChanged) return saveButtonCta;
      if (addr1AndChanged) return saveButtonCta;
      if (addr2AndChanged) return saveButtonCta;

      return nextButtonCta;
    }

    return nextButtonCta;
  }

  @Watch("step.slug", { immediate: true })
  async onStepChanged(slug: string) {
    this.emitCustomEvent(slug);
  }

  @Watch("isFirstAddress", { immediate: true })
  async onIsFirstAddressChanged(isFirstAddressScreen: boolean) {
    // if it is in the first address screen AND doesn't have a first address
    // create new first address
    if (!isFirstAddressScreen || this.value.getAddress("RESIDENTIAL1")) return;
    this.$nextTick(() => this.addFirstAddress(true));
  }

  async emitCustomEvent(slug: string) {
    const { IDS } = this;
    if (this.isDefaultMode) {
      await this.emitDefaultModeEvent(slug, IDS);
    } else if (this.isCheckMode) {
      await this.emitCheckModeEvent(slug, IDS);
    }
  }

  async emitDefaultModeEvent(slug: string, IDS: typeof COMPONENTS) {
    if (slug === IDS.INPUT_NAME) {
      await this.dispatchEvent({ eventName: "SCREEN:NAME_INPUT" });
    } else if (slug === IDS.INPUT_DOB) {
      await this.dispatchEvent({ eventName: "SCREEN:DOB_INPUT" });
    } else if (this.isFirstAddress && slug === IDS.INPUT_ADDR_AUTO) {
      await this.dispatchEvent({ eventName: "SCREEN:ADDRESS_INPUT" });
    } else if (this.isFirstAddress && slug !== IDS.INPUT_ADDR_AUTO) {
      await this.dispatchEvent({ eventName: "SCREEN:MANUAL_ADDRESS_INPUT" });
    }
  }

  async emitCheckModeEvent(slug: string, IDS: typeof COMPONENTS) {
    if (slug === IDS.INPUT_NAME) {
      await this.dispatchEvent({ eventName: "SCREEN:NAME_INPUT_CHECK" });
    } else if (slug === IDS.INPUT_DOB) {
      await this.dispatchEvent({ eventName: "SCREEN:DOB_INPUT_CHECK" });
    } else if (this.isExtraAddressStep() && slug === IDS.INPUT_ADDR_AUTO) {
      await this.dispatchEvent({ eventName: "SCREEN:ADDRESS_INPUT_CHECK" });
    } else if (this.isExtraAddressStep(IDS.INPUT_ADDR_ADD_EXTRA)) {
      await this.dispatchEvent({
        eventName: "SCREEN:ADDITIONAL_ADDRESS_INPUT",
      });
    }
  }

  get formattedTitle() {
    let theMode = this.mode;
    let titlesPerMode: TitlesPerMode = { default: "", edit: "", check: "" };
    // eslint-disable-next-line default-case
    switch (this.step.slug) {
      case this.IDS.INPUT_NAME:
        titlesPerMode = this.getEnglishNameTitles();
        break;
      case this.IDS.INPUT_NAME_EXTRA:
        titlesPerMode = this.getNativeNameTitles();
        break;
      case this.IDS.INPUT_DOB:
        titlesPerMode = this.getDOBTitles();
        break;
      case this.IDS.INPUT_ADDR_AUTO:
        {
          if (this.isFirstAddress) titlesPerMode = this.getFirstAddressTitles();
          else if (this.isExtraAddress) {
            titlesPerMode = this.getExtraAddressTitles(true);
            theMode = this.getExtraAddress()?.addressId ? "check" : "default";
          }
        }
        break;
      case this.IDS.INPUT_ADDR_MANUAL:
        {
          if (this.isFirstAddress) titlesPerMode = this.getFirstAddressTitles();
          else if (this.isExtraAddress) {
            titlesPerMode = this.getExtraAddressTitles(false);
            theMode = this.getExtraAddress()?.addressId ? "check" : "default";
          }
        }
        break;
      case this.IDS.INPUT_ADDR_ADD_EXTRA:
        titlesPerMode = this.getaddExtraAddressTitles();
        break;
    }

    return titlesPerMode[theMode];
  }

  get isFirstAddress(): boolean {
    return this.step.parent?.slug === this.IDS.INPUT_ADDRESS_WRAPPER;
  }

  get isExtraAddress(): boolean {
    return this.step.parent?.slug === this.IDS.INPUT_ADDRESS_EXTRA_WRAPPER;
  }

  getNativeNameTitles(): TitlesPerMode {
    return {
      default: this.getPhrase("name_input_screen.dual_name_native_title"),
      edit: this.getPhrase("review_details_screen.edit_title_dual_name_native"),
      check: this.getPhrase("name_input_screen.dual_name_native_title_loop"),
    };
  }

  getEnglishNameTitles(): TitlesPerMode {
    return {
      default: this.hasDualName
        ? this.getPhrase("name_input_screen.dual_name_english_title")
        : this.getPhrase("name_input_screen.title"),
      edit: this.hasDualName
        ? this.getPhrase("review_details_screen.edit_title_dual_name_english")
        : this.getPhrase("review_details_screen.edit_title_dual_name_native"),
      check: this.hasDualName
        ? this.getPhrase("name_input_screen.dual_name_english_title_loop")
        : this.getPhrase("name_input_screen.title_loop"),
    };
  }

  getDOBTitles(): TitlesPerMode {
    return {
      default: this.getPhrase("date_of_birth_input_screen.title"),
      edit: this.getPhrase("review_details_screen.edit_title_dob"),
      check: this.getPhrase("date_of_birth_input_screen.title_loop"),
    };
  }

  getFirstAddressTitles(): TitlesPerMode {
    return {
      default: this.getPhrase("current_address_screen.title"),
      edit: this.getPhrase("review_details_screen.edit_title_current_address"),
      check: this.getPhrase("current_address_screen.title_loop"),
    };
  }

  getExtraAddressTitles(isAutocomplete = false): TitlesPerMode {
    const hasAutocomplete = this.autocomplete;
    const isFirstAddressPage = isAutocomplete || !hasAutocomplete;
    // if it's the auto complete page OR if it's not supposed to show the autocomplete page (and therefore the first screen is the manual page),
    // show the initial extra address title "What is your previous..."
    // That results in "What is your previous..." being the first title for Autocomplete and "Your previous..." being the title for Manual
    // If autocomplete is false, then "What is your previous..." is the title for manual and there's no extra page
    const firstTitle = this.getPhrase(
      "previous_address_screen.title_first_time"
    );
    return {
      default: isFirstAddressPage
        ? firstTitle
        : this.getPhrase("previous_address_screen.title"),
      edit: isAutocomplete
        ? firstTitle
        : this.getPhrase("review_details_screen.edit_title_previous_address"),
      check: isAutocomplete
        ? firstTitle
        : this.getPhrase("previous_address_screen.title_loop"),
    };
  }

  getaddExtraAddressTitles(): TitlesPerMode {
    return {
      default: this.getPhrase(
        "previous_address_screen.question_has_previous_address"
      ),
      edit: this.getPhrase(
        "previous_address_screen.question_has_previous_address"
      ),
      check: this.getPhrase(
        "previous_address_screen.question_has_previous_address"
      ),
    };
  }
}
