import { Document, countryData } from "@frankieone/shared";

type HasConfig = { acceptedCountries?: TAcceptedCountries };

/** A valid configuration object contains a field acceptedCountries with a non empty array of country codes or the special value "ALL" */
const isConfigurationObject = (d: any): d is { acceptedCountries: string[] } => {
  const isObject = typeof d === "object" && d !== null;
  const hasListOrSpecialValue = Boolean(d?.acceptedCountries?.length) || d?.acceptedCountries === "ALL";
  return isObject && hasListOrSpecialValue;
}

/**
 * Given a configuration item, which may be an object containing the option for "acceptedCountries", resolve
 * between the provided configuration and a fallback list of accepted countries.
 */
export function resolveAcceptedCountriesConfigurationObject(specificConfiguration: any, oldConfiguration: IWidgetConfiguration["acceptedCountries"] | undefined | null) {
  const hasOldConfig = Boolean(oldConfiguration);
  const hasSpecificConfig = isConfigurationObject(specificConfiguration!);

  if (!hasOldConfig && !hasSpecificConfig) return ["AUS"]; // For historical reasons, this is the default fallback value
  if (hasSpecificConfig) return resolveSpecial(specificConfiguration.acceptedCountries);
  if (hasOldConfig) return resolveSpecial(oldConfiguration!); // This should already have been resolved at the initial parsing
  return [];
}
/**
 * Resolve the accepted countries for a given document type within a list of document type configurations.
 * First parameter is a list containing either a document type string or a configuration object which may contain an acceptedCountries array.
 * @param documentTypes List of target document types to check
 * @param documentType The document type to find and resolve
 * @param oldAcceptedCountriesConfig Fallback acceptedCountries configuration
 * @returns List of accepted countries for the given document type
 */
export function resolveAcceptedCountriesForDocType(
  documentTypes: IWidgetConfiguration["documentTypes"],
  documentType: Document["idType"],
  oldAcceptedCountriesConfig?: IWidgetConfiguration["acceptedCountries"] | null
): string[] {
  const findDocType = (el) =>
    typeof el === "string" ? el === documentType : el.type === documentType;
  const docConfig = documentTypes.find(findDocType);

  return resolveAcceptedCountriesConfigurationObject(
    docConfig,
    oldAcceptedCountriesConfig
  );
}
function removeInvalidCodes(codes: string[]): string[] {
  return codes.filter((c) => countryData.find((d) => d.alpha3Code === c));
}
function resolveSpecial(
  value: Required<HasConfig>["acceptedCountries"]
): string[] {
  if (value === "ALL") return countryData.map((c) => c.alpha3Code) as string[];
  return value;
}
