






import {
Context,
mkNationalIdBlueprint,
setup
} from "@/documents/nationalId/blueprint";
import { getNationalIdImage } from "@/utils/documentFactory";
import { resolveAcceptedCountriesForDocType } from "@/utils/resolveAcceptedCountries";
import { checkStringLength, isEmpty } from "@/utils/validator";
import {
Applicant, Document, UIBlueprint
} from "@frankieone/shared";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

declare type ValidationObject = {
  mask: string;
  minLength: number;
  maxLength?: number;
};

type NationalIdTypes = "THA-id" | "IDN-id" | "THA-laser-code" | "SGP-id";

@Component({
  components: {},
})
export default class NationalIdInput extends Vue {
  @Prop({ required: true }) value: Document;

  @Prop({ required: true }) applicant: Applicant;

  @Prop({ required: true, default: () => [] }) acceptedCountries: Country[];

  get blueprint(): UIBlueprint {
    return mkNationalIdBlueprint(this.blueprintContext);
  }

  get blueprintContext(): Context {
    return {
      getDocument: () => this.value,
      setDocument: (document: Document | null) => this.$emit("input", document),
      getApplicant: () => this.applicant,
      setApplicant: (applicant: Applicant | null) =>
        this.$emit("update:applicant", applicant),
      getPhrase: this.getPhrase,
      getAcceptedCountries: () => this.acceptedCountriesFromConfiguration,
    };
  }

  get acceptedCountriesFromConfiguration(): string[] {
    const documentTypes = this.$store.getters.config("documentTypes");

    return resolveAcceptedCountriesForDocType(
      documentTypes,
      "NATIONAL_ID",
      this.acceptedCountries as string[]
    );
  }

  created() {
    setup(this.blueprintContext);
  }

  emitIsAllFilled() {
    this.$emit("isAllFilled", this.isAllFieldsFilled);
  }

  get documentCountry(): string | null {
    return this.value.country;
  }

  get thaiLaserCode(): string {
    return (this.value?.extraData?.laser_code as string) || "";
  }

  getExtraDataField(field: string): string {
    return this.value?.extraData?.[field] as string;
  }

  get nationalIdImage(): DynamicComponent[] {
    // hardcode countries for whom we want to display national id image
    const country = this.documentCountry;
    if (!country || !["THA", "IDN", "SGP"].includes(country)) return [];

    const documentClass = "ff-document-image";
    const defaultClass = "ff-national-id-image";
    const backClass = "ff-national-id-image-back";

    type Variation = "front" | "back";
    const variations: Variation[] = ["front"];
    if (country === "THA") variations.push("back");

    const getNationalIdVariationOrNull = (
      variation: Variation
    ): DynamicComponent | null => {
      const component = getNationalIdImage(country, variation);
      if (!component) return null;
      // Ensure expected class is present for each variation "front" and "back"
      return Object.assign(component, {
        class: [
          variation === "back" ? backClass : defaultClass,
          documentClass,
          component.class ?? {},
        ],
      });
    };
    return variations
      .map(getNationalIdVariationOrNull)
      .filter(Boolean) as DynamicComponent[];
  }

  get isAllFieldsFilled(): boolean {
    const { idNumber } = this.value;
    if (!idNumber) return false;
    if (this.documentCountry === "IDN") {
      const { displayName } = this.applicant;
      const indoNationalIdValidator = this.validationForFieldType("IDN-id");
      return (
        checkStringLength(
          idNumber,
          indoNationalIdValidator?.minLength ?? 0,
          indoNationalIdValidator?.maxLength
        ) && !isEmpty(displayName)
      );
    }
    if (this.documentCountry === "SGP") {
      const sgpNationalIdValidator = this.validationForFieldType("SGP-id");
      const hasNationality = !!this.getExtraDataField("nationality_code");
      return (
        hasNationality &&
        checkStringLength(
          idNumber,
          sgpNationalIdValidator?.minLength ?? 0,
          sgpNationalIdValidator?.maxLength
        )
      );
    }
    if (this.documentCountry === "THA") {
      const thaiNationalIdValidator = this.validationForFieldType("THA-id");
      const thaiLaserCodeValidator =
        this.validationForFieldType("THA-laser-code");
      const thaiIdTrim = idNumber.replace(/\s/g, "");
      const laserCodeTrim = this.thaiLaserCode.replace(/\-/g, "");

      return (
        checkStringLength(
          thaiIdTrim,
          thaiNationalIdValidator?.minLength ?? 0,
          thaiNationalIdValidator?.maxLength
        ) &&
        checkStringLength(
          laserCodeTrim,
          thaiLaserCodeValidator?.minLength ?? 0,
          thaiLaserCodeValidator?.maxLength
        )
      );
    }
    const trimmed = idNumber?.replace(/\s/g, "") ?? "";
    return trimmed.length > 0;
  }

  @Watch("value.country")
  onCountryChanged(country, prev) {
    if (prev) this.$emit("countryChanged");
  }

  validationForFieldType(field: NationalIdTypes): ValidationObject {
    // eslint-disable-next-line default-case
    switch (field) {
      case "IDN-id":
        return { mask: "################", minLength: 16 };
      case "THA-id":
        return { mask: "# #### ##### ## #", minLength: 13 };
      case "THA-laser-code":
        return { mask: "XXX-XXXXXXX-XX", minLength: 12 };
      case "SGP-id":
        return { mask: "XXXXXXXXX", minLength: 9 };
      default:
        return { mask: "", minLength: 0, maxLength: 15 };
    }
  }

  @Watch("allPropsToWatch", { immediate: true, deep: true })
  onValueChanged() {
    // setup(this.blueprintContext);
    this.$nextTick(() => this.emitIsAllFilled());
  }

  get allPropsToWatch() {
    return { value: this.value, applicant: this.applicant };
  }

  async mounted() {
    await this.$root.dispatchEvent({ eventName: "SCREEN:NATIONAL_ID" });
  }
}
