import { feature } from '@/store/features';
import { checkDateIsValid, getInFormattedExpiry, getOutFormattedIdExpiry } from '@/utils/date';
import { Document, DynamicUIComponent, GetPhraseFunction, UIBlueprint } from '@frankieone/shared';

export type Context = {
  getDocument: () => Document;
  setDocument: (document: Document | null) => void;
  getPhrase: GetPhraseFunction;
  getAcceptedCountries: () => Document['country'][];
  getShowExpiryDate: () => boolean;
  setExpiryBuffer: ($event) => void;
  getExpiryBuffer: () => string;
};
type MkInputComponent = {
  tag?: string;
  field: TPassportInputFields;
  key?: string;
};

type TPassportInputFields = 'idExpiry' | 'idNumber' | 'country';

type PassportValidation = {
  mask: string;
  minLength: number;
  maxLength: number;
};

export const idNumberValidation = (country: Document['country']): PassportValidation => {
  const enhancedValidation = feature('enhancedValidation');
  /**
   * vue-the-mask does not support falsy value.
   * It is a known issue: https://github.com/vuejs-tips/vue-the-mask/issues/82
   * We need to explicitly specify a loose validation as default fallback
   * Otherwise the state will be staled with previous value
   */
  const rules = {
    AUS: {
      mask: enhancedValidation ? 'SXXXXXXXX' : 'X'.repeat(9),
      minLength: 8,
      maxLength: 9,
    },
    NZL: { mask: 'X'.repeat(8), minLength: 7, maxLength: 8 },
    default: { mask: 'X'.repeat(99), minLength: 1, maxLength: 99 },
  };

  return rules[country!] || rules.default;
};

/**
 * By default, the display of the expiry date is determined by the configuration setting obtained from getShowExpiryDate().
 * However, some countries may not follow this default behavior and always require the expiry date to be displayed.
 **/
export const hasExpiryDate = (country, config): boolean => {
  const configuredByUser: boolean = !!config;
  if (country === 'NZL') return true;
  return configuredByUser ?? false;
};

export function mkPassportBlueprint(context: Context) {
  const {
    getPhrase,
    getAcceptedCountries,
    getDocument,
    setDocument,
    getShowExpiryDate,
    setExpiryBuffer,
    getExpiryBuffer,
  } = context;
  const acceptedCountries = getAcceptedCountries();
  const hasOneAcceptedCountry = acceptedCountries.length === 1;
  const value = getDocument();
  const passportCountry = value.country;
  const buffer = getExpiryBuffer();

  const mkGenericInput = ({ tag = 'generic-input', field }: MkInputComponent) => {
    return new DynamicUIComponent({
      tag,
      classes: [getClassnameForField(field)],
      attrs: {
        style: getStyleForField(field),
        label: getLabelForField(field),
        value: getValueForField(field),
        placeholder: getPlaceholderForField(field),
        tabindex: 0,
        ...getAttrsForField(field),
      },
      listeners: {
        input: (v: string) => setValueForField(field, v),
      },
    });
  };

  const mkCountryInput = () => {
    return new DynamicUIComponent({
      tag: 'country-input',
      classes: ['ff-passport-country', 'country', 'mb-6'],
      attrs: {
        style: { display: hasOneAcceptedCountry && 'none' }, // hide country input if only 1 country is allowed
        label: getPhrase('document.type_passport.country', {
          isMandatory: true,
        }),
        options: acceptedCountries,
        filterable: true,
        placeholder: getPhrase('document.type_passport.country'),
        value: passportCountry,
      },
      listeners: {
        input: (v: string) => setValueForField('country', v),
      },
    });
  };

  const getStyleForField = (f: TPassportInputFields): CSSStyleDeclaration => {
    const styles = <const>{
      idNumber: { margin: hasOneAcceptedCountry && '0' }, // fix margin for the adjacent input field if country input is hidden
    };
    return styles[f] || '';
  };

  const getLabelForField = (f: TPassportInputFields): string => {
    const phraseWithStar = (key: string): string => getPhrase(key, { isMandatory: getIsMandatory(f) });
    if (f === 'idNumber') return phraseWithStar('document.type_passport.number');
    if (f === 'idExpiry') return phraseWithStar('document.type_passport.expiry');
    return '';
  };
  const getPlaceholderForField = (f: TPassportInputFields): string => {
    if (f === 'idNumber') return getPhrase('document.type_passport.number');
    if (f === 'idExpiry') return getPhrase('document.type_passport.expiry');
    return '';
  };
  const getClassnameForField = (f: TPassportInputFields): string => {
    if (f === 'idNumber') return 'ff-passport-number';
    if (f === 'idExpiry') return 'ff-passport-id-expiry';
    return '';
  };
  const getValueForField = (f: TPassportInputFields): string => {
    if (f === 'idNumber') return value?.idNumber ?? '';
    if (f === 'idExpiry') return getInFormattedExpiry(value?.idExpiry ?? '', buffer) ?? '';
    return '';
  };
  const getIsMandatory = (f: TPassportInputFields): boolean => {
    if (f === 'idNumber') return true;
    if (f === 'country') return true;
    if (f === 'idExpiry') return hasExpiryDate(passportCountry, getShowExpiryDate());
    return false;
  };
  const setValueForField = (f: TPassportInputFields, v: string) => {
    const document = value.clone();
    if (f === 'idExpiry') {
      const expiry = getOutFormattedIdExpiry(v);
      setExpiryBuffer(v);
      if (checkDateIsValid(expiry)) {
        document[f] = expiry;
        setDocument(document);
      }
    } else {
      document[f] = v;
      setDocument(document);
    }
  };
  const getAttrsForField = (f: TPassportInputFields) => {
    if (f === 'idNumber') {
      return {
        mask: idNumberValidation(passportCountry).mask,
      };
    }
    if (f === 'idExpiry') {
      return {
        type: 'tel',
        mask: '##/##/####',
        removeSpaces: true,
      };
    }
    return {};
  };

  const children = {};
  children['country-input'] = mkCountryInput();
  children['idNumber'] = mkGenericInput({ field: 'idNumber' });
  if (hasExpiryDate(passportCountry, getShowExpiryDate())) {
    children['idExpiry'] = mkGenericInput({ field: 'idExpiry' });
  }

  return new UIBlueprint({
    root: new DynamicUIComponent({
      tag: 'div',
      classes: ['ff-passport-input'],
    }),
    children: {
      ...children,
    },
  });
}
