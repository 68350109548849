import {
  DateObject,
  TDOBTypeSupport,
  transformGregorianDateTo as gregTo,
  UIBlueprint,
  GetPhraseFunction,
  Applicant,
  breakDateToObject,
} from "@frankieone/shared";
import padLeft from "pad-left";
import { mkBuddhistDOBBlueprint } from "./buddhist-blueprint";
import { mkGregorianDOBBlueprint } from "./gregorian-blueprint";
import { checkStringLength } from "../../utils/validator";

type ContextData = { applicant: Applicant };
export type Context = {
  getBuffer: () => string;
  setBuffer: (value: string) => void;
  getData: () => ContextData;
  setData: (d: ContextData) => void;
  getAgeRange: () => [number, number];
  getPhrase: GetPhraseFunction;
  getDOBType: () => TDOBTypeSupport;
  getIsDisabled?: () => boolean;
};

export function onCreated(context: Context) {
  const { setBuffer, getDOBType, getData } = context;
  const dobType = getDOBType();
  const { applicant } = getData();

  const transformed =
    dobType === "buddhist"
      ? applicant.extraData["dob.Buddhist"]
      : applicant.dateOfBirth;

  setBuffer(transformed ?? "");
}

export function mkDOBBlueprint(context: Context) {
  // when there's an additional date of birth, the inputs for gregorian getCale won't have
  // read/write access to the buffer and will only be able to read the applicants DOB
  //
  // in such cases, the additional calendars are the ones to use the buffer as an
  // intermediate place of storage and will when the date is complete it will also
  // convert the final date to gregorian, outputting it to applicants data with setCompleteDateOfBirth

  const hasAdditionalDOB = context.getDOBType() !== "gregorian";
  const children = {};

  if (hasAdditionalDOB)
    children["dob-additional"] = mkAdditionalDOBBlueprint(context);
  else children["dob-gregorian"] = mkGregorianDOBBlueprint(context);

  return new UIBlueprint({ children });
}

export function padValue(value: string, padLength: number) {
  return padLeft(value, padLength, "0");
}

export function mkAdditionalDOBBlueprint(context: Context): UIBlueprint {
  // buddhist is the only type rn
  return mkBuddhistDOBBlueprint(context);
}

export function transformGregorianDateTo(
  value: string,
  target: TDOBTypeSupport
) {
  return gregTo(value, target);
}

export function isDOBComplete(
  value: null | string | DateObject,
  calendarType: TDOBTypeSupport
): boolean {
  if (!value) return false;

  const dateObject: DateObject =
    typeof value === "string" ? breakDateToObject(value) : value;
  const { dd, mm, yyyy } = dateObject;

  const isDayComplete = checkStringLength(dd, 1, 2);
  const isMonthComplete = checkStringLength(mm, 1, 2);
  const isYearComplete = checkStringLength(yyyy, 4);

  const hasEveryValue = isDayComplete && isMonthComplete && isYearComplete;
  if (calendarType === "gregorian" && hasEveryValue) return true;
  // if it has day, it needs month too
  if (isDayComplete && dd !== "00" && mm === "00") return false;
  // if it has month, it needs day too
  if (isMonthComplete && mm !== "00" && dd === "00") return false;
  // otherwise, if it has all values, it's complete
  return hasEveryValue;
}
