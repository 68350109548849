
































import { Component, Vue } from "vue-property-decorator";
import { Document, Applicant, Address } from "@frankieone/shared";
import PersonalForm from "@/forms/PersonalForm.vue";
import ReviewCreateDocument from "@/forms/ReviewOrCreateDocumentForm.vue";
import OutcomeMessage from "@/components/OutcomeMessage.vue";
import { snapshot } from "@/utils/stringify";
import { type } from "../utils/configurationParser";
import { getNameFields, hasExtraName } from "../utils/personalDetails";

@Component({
  name: "NoMatchView",
  components: {
    PersonalForm,
    ReviewCreateDocument,
    OutcomeMessage,
  },
})
export default class NoMatchView extends Vue {
  applicantSnapshot = "";

  get hasGoogleApi(): boolean {
    return this.$store.direct.state.session.isGoogleApiLoaded;
  }

  get currentStep() {
    return this.$store.direct.getters.currentStep;
  }

  get currentView() {
    return this.$store.direct.getters.currentView;
  }

  get dobType(): TDOBTypeSupport {
    return (
      this.$store.direct.getters.config("dateOfBirth")?.type ?? "gregorian"
    );
  }

  get isCurrentStepPersonal(): boolean {
    return (
      [
        this.IDS.INPUT_NAME,
        this.IDS.INPUT_NAME_EXTRA,
        this.IDS.INPUT_DOB,
        this.IDS.INPUT_ADDR_MANUAL,
      ].includes(this.currentStep.slug as COMPONENT) ||
      this.currentStep.parent?.slug === this.IDS.INPUT_ADDRESS_EXTRA_WRAPPER
    );
  }

  get acceptedCountries(): Document["country"][] {
    return this.$store.direct.getters.countriesList;
  }

  get acceptedAgeRange(): IWidgetConfiguration["ageRange"] {
    const ageRange = this.$store.direct.getters.config(
      "ageRange",
      type.array(type.number)
    ) as IWidgetConfiguration["ageRange"];
    return ageRange;
  }

  get documents(): Document[] {
    return this.$store.direct.state.documents.documentsList;
  }

  get displayMiddleNameValue(): string | null {
    const medicareDoc =
      this.$store.direct.getters.getDocumentOfType("NATIONAL_HEALTH_ID");
    return (medicareDoc?.extraData?.display_middle_name as string) ?? null;
  }

  get applicant(): Applicant {
    return this.$store.direct.state.personal.applicant;
  }

  get personalDetailsChecked() {
    const checked = this.$store.direct.getters.personalDetailsChecked;
    return checked;
  }

  get areAllPersonalDetailsChecked(): boolean {
    return this.$store.direct.getters.areAllPersonalDetailsChecked;
  }

  get hasAvailableDocTypes(): boolean {
    const verifiedDocuments = this.documents.filter((d) => d.verified.manual);
    return this.acceptedDocumentTypes.length > verifiedDocuments.length;
  }

  get acceptedDocumentTypes() {
    const { acceptedDocumentTypes } = this.$store.direct.getters;
    return acceptedDocumentTypes;
  }

  get lastDocument(): Document {
    const { documents } = this;
    const lastDocument = documents[documents.length - 1];
    return lastDocument;
  }

  get requestID(): boolean {
    return this.$store.getters.config("requestID", type.bool);
  }

  get hasMedicareDoc(): boolean {
    return !!this.$store.direct.getters.getDocumentOfType("NATIONAL_HEALTH_ID");
  }

  updateApplicant(applicant: Applicant) {
    return this.$store.direct.dispatch.updateApplicant(applicant);
  }

  nextStep() {
    return this.$store.direct.dispatch.nextStep();
  }

  onNewAddressDecision(decision) {
    this.$store.direct.state.personal.extraAddressOption = decision;
    if (decision) {
      this.$store.direct.dispatch.updateInnerSteps({
        innerSteps: (steps) => {
          const newSteps = [...steps];
          if (this.hasGoogleApi)
            newSteps.push({
              slug: this.IDS.INPUT_ADDR_AUTO,
              label: "Address Autocomplete",
            });
          newSteps.push({
            slug: this.IDS.INPUT_ADDR_MANUAL,
            label: "Address Manual",
          });
          return newSteps;
        },
      });
      this.nextStep();
    } else {
      // change parameter to true to display the question again on every repeated cycle until a previous address is added
      this.personalFormSubmitted(false);
    }
  }

  setPersonalDetailsChecked(what: string, checked: boolean) {
    this.$store.direct.dispatch.setPersonalDetailChecked({ what, checked });
  }

  addPartialView() {
    this.addView("partial-match");
    const viewIndex = this.$store.direct.getters.routeIndex.view + 1;
    this.$store.direct.dispatch.updateSteps({
      steps: [],
      viewIndex,
    });
  }

  addView(slug) {
    this.$store.direct.dispatch.addView(slug);
  }

  get nameInputFieldNames(): TFieldNames[] {
    const nationalIdDoc =
      this.$store.direct.getters.getDocumentOfType("NATIONAL_ID");

    return getNameFields(nationalIdDoc);
  }

  addSteps() {
    const checked = this.personalDetailsChecked;
    const hasDocumentToVerify =
      this.lastDocument?.verified?.manual === false ||
      this.hasAvailableDocTypes;
    if (
      this.areAllPersonalDetailsChecked &&
      hasDocumentToVerify &&
      this.requestID
    ) {
      this.addPartialView();
      return;
    }
    if (this.areAllPersonalDetailsChecked && !hasDocumentToVerify) {
      this.addTooManyTriesView(); // this should never happen as it should be checked before adding a loop
      return;
    }

    const only = this.currentView.data?.only ?? {
      name: true,
      dob: true,
      extraName: true,
      firstAddress: true,
      secondAddress: true,
    };
    this.$store.direct.dispatch.updateSteps({
      steps: (steps) => {
        const newSteps = [...steps];

        if (!checked.name && only.name)
          newSteps.push({ slug: this.IDS.INPUT_NAME, label: "Review Name" });
        if (
          !checked.extraName &&
          only.extraName &&
          hasExtraName(this.documents)
        )
          newSteps.push({
            slug: this.IDS.INPUT_NAME_EXTRA,
            label: "Review Extra Name",
          });
        if (!checked.dob && only.dob)
          newSteps.push({
            slug: this.IDS.INPUT_DOB,
            label: "Review Date of Birth",
          });
        if (
          !checked.firstAddress &&
          only.firstAddress &&
          this.$store.getters.config("requestAddress", type.bool)
        ) {
          newSteps.push({
            slug: this.IDS.INPUT_ADDRESS_WRAPPER,
            label: '"Review Current Address"',
            inner: [
              { slug: this.IDS.INPUT_ADDR_MANUAL, label: "Address Manual" },
            ],
          });
        }
        if (
          !checked.extraAddress &&
          only.secondAddress &&
          this.$store.getters.config("requestAddress", type.bool)
        ) {
          const extraAddress = this.applicant.getAddress("RESIDENTIAL2");
          const inner: any[] = [];
          if (extraAddress && !extraAddress.isIncomplete) {
            const manualStep = {
              slug: this.IDS.INPUT_ADDR_MANUAL,
              label: "Address Manual",
            };
            inner.push(manualStep);
          } else {
            const addAddrStep = {
              slug: this.IDS.INPUT_ADDR_ADD_EXTRA,
              label: "Add new address?",
            };
            inner.push(addAddrStep);
          }
          newSteps.push({
            slug: this.IDS.INPUT_ADDRESS_EXTRA_WRAPPER,
            label: '"Review Previous Address"',
            inner,
          });
        }
        return newSteps;
      },
    });
  }

  addTooManyTriesView() {
    this.$store.direct.dispatch.addView("too-many-tries");
  }

  async mounted() {
    await this.dispatchEvent({ eventName: "SCREEN:NO_MATCH" });
  }

  personalFormSubmitted(hasChanged: boolean) {
    const step = this.currentStep.slug;
    const parent = this.currentStep.parent?.slug;
    const extraAddress = this.applicant.getAddress("RESIDENTIAL2");
    const isCreatingExtraAddress =
      extraAddress?.data["widget-address-creation"];
    // if this is the first time this address is appearing, it's being created now and contains the flag widget-address-creation, included in PersonalForm
    if (step === this.IDS.INPUT_NAME)
      this.setPersonalDetailsChecked("name", !hasChanged);
    if (step === this.IDS.INPUT_NAME_EXTRA)
      this.setPersonalDetailsChecked("extraName", !hasChanged);
    if (step === this.IDS.INPUT_DOB)
      this.setPersonalDetailsChecked("dob", !hasChanged);
    const isFirstAddressManualInput =
      parent === this.IDS.INPUT_ADDRESS_WRAPPER &&
      step === this.IDS.INPUT_ADDR_MANUAL;
    if (isFirstAddressManualInput)
      this.setPersonalDetailsChecked("firstAddress", !hasChanged);
    // on page "add extra address?", if changed === false, flag extraAddress as reviewed (true), so this question wont be repeated
    const isExtraAddressDecision =
      parent === this.IDS.INPUT_ADDRESS_EXTRA_WRAPPER &&
      step === this.IDS.INPUT_ADDR_ADD_EXTRA;
    if (isExtraAddressDecision && !hasChanged)
      this.setPersonalDetailsChecked("extraAddress", true);
    // second address will be submitted once for creation and then other times while hasChanged === true (reviwed)
    // if second address is appearing here for the first time, it's being created, so simply remove the flag
    if (
      parent === this.IDS.INPUT_ADDRESS_EXTRA_WRAPPER &&
      isCreatingExtraAddress
    ) {
      const index = this.applicant.addresses.findIndex(
        (a: Address) => a.addressType === "RESIDENTIAL2"
      );
      delete this.applicant.addresses[index].data["widget-address-creation"];
    }
    // if second address doesnt have a creation flag, update the checked state for extra address
    if (
      parent === this.IDS.INPUT_ADDRESS_EXTRA_WRAPPER &&
      step === this.IDS.INPUT_ADDR_MANUAL &&
      !isCreatingExtraAddress
    )
      this.setPersonalDetailsChecked("extraAddress", !hasChanged);

    const totalStepCount =
      this.$store.direct.getters.currentView.steps?.length ?? 1;
    const lastStepInnerCount = this.currentStep.parent?.inner?.length ?? 1;
    const isLastStep = this.currentStep.parentStepIndex + 1 === totalStepCount;
    const isLastInner =
      this.currentStep.innerStepIndex + 1 === lastStepInnerCount;

    // if this submission is the last step in the path
    if (isLastStep && isLastInner) {
      const hasApplicantChanged =
        this.applicantSnapshot !== snapshot(this.applicant);
      const hasDocumentToVerify =
        this.lastDocument?.verified?.manual === false ||
        this.hasAvailableDocTypes;
      // if no change/review was done in current loop and has documents to verify, add partial view next for document review
      if (!hasApplicantChanged && hasDocumentToVerify && this.requestID)
        this.addPartialView();
      // if no change/review was done in current loop and has no documents to verify, fail with too many tries
      else if (!hasApplicantChanged && !hasDocumentToVerify)
        this.addTooManyTriesView();
      // if has made changes in current loop, go to summary view next
      else this.addView("summary");
    }
    // then move on to next step
    this.nextStep();
  }

  created() {
    this.applicantSnapshot = snapshot(this.applicant);
    this.addSteps();
  }
}
