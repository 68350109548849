






































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { Document, Applicant } from "@frankieone/shared";
import DocumentTypeInput from "@/components/inputs/DocumentTypeInput.vue";
import DriversLicenceInput from "@/components/inputs/DriversLicenceInput.vue";
import NationalIdInput from "@/components/inputs/NationalIdInput.vue";
import PassportInput from "@/components/inputs/PassportInput.vue";
import MedicareInput from "@/components/inputs/MedicareInput.vue";
import { isEmpty } from "@/utils/validator";
import {
  mkDocumentForType,
  ID_TYPES,
  findDocumentConfigInList,
} from "@/utils/documentFactory";

@Component({
  name: "DocumentForm",
  components: {
    DocumentTypeInput,
  },
})
export default class DocumentForm extends Vue {
  @Prop({ required: true }) step: CurrentStepObject;

  @Prop({ required: true }) value: Document;

  @Prop({ required: true }) applicant: Applicant;

  @Prop() mode: string;

  @Prop({ required: true }) acceptedDocuments: TypedDocConfig[];

  @Prop({ default: () => [] }) disabledDocTypes: string[];

  @Prop() acceptedCountries: string[];

  documentInputKey = "re-render-key";

  isAllFilled = false;

  buffer: Document = new Document();

  get isExtra(): boolean {
    return this.mode === "extra";
  }

  get isButtonShown(): boolean {
    if (this.step.slug === this.IDS.INPUT_DRIVERS_STATE) return false;
    return true;
  }

  async setDocumentType(type: Document["idType"]) {
    const isCustomType = type?.startsWith("CUSTOM_") || false;

    if (isCustomType) {
      this.emitCustomDocPayload(type);
    } else if (type !== this.buffer.idType) {
      this.buffer = mkDocumentForType(type);
      this.emitValue();
      this.emitDocumentTypeSelected(type);
    }
  }

  emitDocumentTypeSelected(type: Document["idType"]) {
    this.$emit("documentTypeSelected", type);
  }

  get documentConfig(): TypedDocConfig | null {
    const docType = this.value.idType as TSupportedDocuments;
    const docConfig = this.acceptedDocuments;
    return findDocumentConfigInList(docConfig, docType);
  }

  emitCustomDocPayload(type: Document["idType"]) {
    const customDoc: TCustomDocumentConfig = this.acceptedDocuments.find(
      (doc) => doc.type === type
    ) as TCustomDocumentConfig;
    const customEventPayload = customDoc?.customEventPayload;
    if (window.CustomEvent) {
      const { CustomEvent } = window;
      const outcome = new CustomEvent("DOC_TYPE_SELECTED", {
        detail: {
          applicant: this.applicant,
          customEventPayload,
        },
      });
      window.dispatchEvent(outcome);
    }
  }

  emitValue() {
    this.$emit("input", this.buffer.clone());
  }

  setDocument(document: Document) {
    this.$emit("input", document.clone());
  }

  setApplicant(applicant: Applicant) {
    this.$emit("update:applicant", applicant.clone());
  }

  submit() {
    this.$emit("submit");
  }

  get documentDetailsComponent(): {
    component: VueComponent;
    attrs?: {};
  } | null {
    if (!this.isIdTypePresent) return null;
    switch (this.value.idType) {
      case ID_TYPES.DRIVERS_LICENCE:
        return { component: DriversLicenceInput };
      case ID_TYPES.PASSPORT:
        return { component: PassportInput };
      case ID_TYPES.NATIONAL_HEALTH_ID:
        return { component: MedicareInput };
      case ID_TYPES.NATIONAL_ID:
        return { component: NationalIdInput };
      default:
        return null;
    }
  }

  get isIdTypePresent(): boolean {
    return !isEmpty(this.value?.idType);
  }

  setIsAllFilled(value: boolean) {
    this.isAllFilled = value;
  }

  getFormattedTitle(): string {
    switch (this.value?.idType) {
      case ID_TYPES.DRIVERS_LICENCE:
        return this.getPhrase("drivers_licence_input_screen.title");
      case ID_TYPES.PASSPORT:
        return this.getPhrase("passport_input_screen.title");
      case ID_TYPES.NATIONAL_HEALTH_ID:
        return this.getPhrase("medicare_input_screen.title");
      case ID_TYPES.NATIONAL_ID:
        return this.getPhrase("national_id_input_screen.title");
      default:
        return "document";
    }
  }

  @Watch("step.slug", { immediate: true })
  onStepSlugChanged(slug) {
    if (
      slug === this.IDS.INPUT_DOCUMENT_TYPE &&
      !!this.step.data.preselectedIdType
    ) {
      const idType = this.step.data.preselectedIdType;
      this.setDocumentType(idType);
    }
  }

  get buttonText(): string {
    const saveButtonCta = this.getPhrase("common.save_button_cta");
    const nextButtonCta = this.getPhrase("common.next_button_cta");
    return this.isEditMode ? saveButtonCta : nextButtonCta;
  }

  get titleText(): string {
    if (this.step.slug === this.IDS.INPUT_DRIVERS_STATE)
      return this.getPhrase("drivers_licence_input_screen.state_screen_title");
    return this.getFormattedTitle();
  }

  get isEditMode(): boolean {
    return this.mode === "edit";
  }
}
