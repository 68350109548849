import {
  UIBlueprint,
  breakDateToObject,
  serialiseDateObject,
  DynamicUIComponent,
} from "@frankieone/shared";
import { Context, padValue, isDOBComplete } from "./blueprint";

export function mkGregorianDOBBlueprint(context: Context): UIBlueprint {
  type FieldNames = "day" | "month" | "year";
  type ObjFieldNames = "dd" | "mm" | "yyyy";
  type DayComponentFactory = { f: FieldNames };

  const { getIsDisabled = () => false } = context;

  if (getIsDisabled()) {
    context.setBuffer = () => {};
    context.setData = () => {};
  }

  const { getPhrase, setData, getData, setBuffer, getBuffer } = context;
  const applicant = getData().applicant.clone();
  const setCompleteDateOfBirth = (dob: string | null) => {
    applicant.dateOfBirth = dob;
    setData({ applicant });
  };

  const dateObject = breakDateToObject(getBuffer());

  const isComplete = (): boolean => isDOBComplete(dateObject, "gregorian");
  const getDateObjectFieldFor = (field: FieldNames): ObjFieldNames => {
    if (field === "day") return "dd";
    if (field === "month") return "mm";
    return "yyyy";
  };
  const getMaskFor = (field: FieldNames): string => {
    if (field === "day") return "##";
    if (field === "month") return "##";
    return "####";
  };
  const getPadLengthFor = (field: FieldNames): number =>
    field === "year" ? 0 : 2;
  const serialiseDate = (): string => serialiseDateObject(dateObject);
  const setFieldValue = (field: FieldNames, value: string) => {
    const objField = getDateObjectFieldFor(field);
    dateObject[objField] = value;
    const serialised = serialiseDate();
    setBuffer(serialised);
    if (isComplete()) {
      setCompleteDateOfBirth(serialised);
    } else {
      setCompleteDateOfBirth(null);
    }
  };
  const getFieldValue = (field: FieldNames): string => {
    const objField = getDateObjectFieldFor(field);
    return dateObject[objField] ?? "";
  };
  const getPadFieldValue = (field: FieldNames) => {
    const padLength = getPadLengthFor(field);
    const value = getFieldValue(field);
    const padded = padValue(value, padLength);
    return value.length > 0 ? padded : "";
  };

  const mkDateInputComponent = ({ f }: DayComponentFactory) =>
    new DynamicUIComponent({
      tag: "generic-input",
      classes: [`ff-${f}`],
      attrs: {
        disabled: getIsDisabled(),
        mask: getMaskFor(f),
        type: "tel",
        noTopPadding: true,
        placeholder: getPhrase(`date_of_birth_input_screen.${f}_label`),
        value: getFieldValue(f),
      },
      listeners: {
        input: (v: string) => setFieldValue(f, v),
        blur: () => setFieldValue(f, getPadFieldValue(f)),
      },
    });
  const isMandatory = (): boolean => context.getDOBType() === "gregorian";

  return new UIBlueprint({
    root: new DynamicUIComponent({
      tag: "label-wrapper",
      classes: ["ff-dob-inputs"],
      attrs: {
        label: context.getPhrase("date_of_birth_input_screen.full_date_label", {
          isMandatory: isMandatory(),
        }),
      },
    }),
    children: {
      day: mkDateInputComponent({ f: "day" }),
      month: mkDateInputComponent({ f: "month" }),
      year: mkDateInputComponent({ f: "year" }),
    },
  });
}
