import moment from "moment";

const DEFAULT_IN_FORMAT = "DD/MM/YYYY";
const DEFAULT_OUT_FORMAT = "YYYY-MM-DD";

export const inFormat = DEFAULT_IN_FORMAT;
export const outFormat = DEFAULT_OUT_FORMAT;

export const getInFormattedExpiry = (
  expiryValue: string,
  expiryBuffer = "",
  inFormat = DEFAULT_IN_FORMAT,
  outFormat = DEFAULT_OUT_FORMAT
) => {
  let formatted: string;
  const idExpiry = expiryValue ?? "";
  if (outFormat.length > idExpiry.length) {
    formatted = expiryBuffer;
  } else {
    formatted = moment(idExpiry, outFormat).format(inFormat);
  }
  return formatted;
};

export const getOutFormattedIdExpiry = (
  value,
  inFormat = DEFAULT_IN_FORMAT,
  outFormat = DEFAULT_OUT_FORMAT
): string => {
  const dateObject = moment(value, inFormat);
  let expiry: string;
  if (checkDateIsValid(value, inFormat)) {
    expiry = "";
  } else {
    expiry = dateObject.format(outFormat);
  }
  return expiry;
};

export const checkDateIsValid = (
  value: string,
  inFormat = DEFAULT_IN_FORMAT
) => {
  const dateObject = moment(value, inFormat);
  const isValidDate = inFormat.length > value.length || !dateObject.isValid();
  return isValidDate;
};
