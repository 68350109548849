














import ConsentInput from "@/components/inputs/ConsentInput.vue";
import {
  AusVariant,
  docNumberValidationPerState,
  licenceNumberValidationPerState,
  mkDriversLicenceBlueprint,
} from "@/documents/driversLicence/blueprint";
import {
  idNumberValidation,
  versionNumberValidation,
} from "@/documents/driversLicence/nzl-blueprint";
import COLORS from "@/styles/_colors.sass";
import { getLicenceImage } from "@/utils/documentFactory";
import { resolveAcceptedCountriesForDocType } from "@/utils/resolveAcceptedCountries";
import { checkStringLength } from "@/utils/validator";
import { Document, UIBlueprint } from "@frankieone/shared";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
@Component({
  components: {
    ConsentInput,
  },
})
export default class DriversLicenceInput extends Vue {
  @Prop({ required: true }) value: Document;

  @Prop({ required: true }) step: CurrentStepObject;

  @Prop() documentConfig: TDriversLicenceConfig;

  buffer: Document = new Document();

  licenceImageVariant: AusVariant = null;

  get blueprint(): UIBlueprint | void {
    return mkDriversLicenceBlueprint({
      getCompleteDriverLicence: () => this.buffer,
      setCompleteDriverLicence: (buffer) => this.setBuffer(buffer),
      getPhrase: this.getPhrase,
      getShowDigitalLicenceOption: () => this.showDigitalLicence,
      getDocumentNumberOptions: () => this.statesWithDocumentNumberConfig,
      // country options below defaults to australia to prevent adding a breaking change to original behaviour (australia only)
      getCountryOptions: () => this.acceptedCountries,
      onFocusListener: (variant) => {
        this.licenceImageVariant = variant as AusVariant;
      },
    });
  }

  setBuffer(buffer: Document) {
    this.buffer = buffer;
    this.emitInput();
    this.$nextTick(() => this.emitIsAllFilled());
  }

  setState(value, nextStep: boolean) {
    this.buffer.region = value;
    this.emitInput();
    this.$nextTick(() => this.emitIsAllFilled());
    if (nextStep) this.$nextTick(() => this.$emit("submit"));
  }

  get licenceImage(): VueComponent | null {
    return getLicenceImage(this.buffer);
  }

  get showDigitalLicence(): boolean {
    return !!this.documentConfig?.digitalLicence;
  }

  get selectedState(): string | null {
    return this.buffer.region;
  }

  get digitalLicenceOption(): boolean {
    return !!this.buffer?.extraData?.digital_licence;
  }
  get licenceVersion(): string | null {
    const value = this.buffer?.extraData?.licence_version;
    return value ? String(value) : null;
  }
  get statesWithDocumentNumberConfig(): string[] {
    type ConfigType = string[];
    const configKey = "statesThatRequireDocNumber";
    return this.$feature<ConfigType>(configKey) ?? [];
  }
  get deviceType(): TDeviceType {
    if (this.isMobile) return "mobile";
    if (this.isTablet) return "tablet";
    return "desktop";
  }

  get documentNumberInfoText(): string | null {
    const { country, region } = this.buffer;
    if (this.statesWithDocumentNumberConfig.includes(region!)) {
      return (
        // for country with regions, e.g. AUS
        this.getPhrase(
        `drivers_licence_input_screen.document_number.hint.${country}.${region}`
        ) ||
        // for country with no region, e.g. NZL
        this.getPhrase(
          `drivers_licence_input_screen.document_number.hint.${country}`
        )
      );
    }
    return "";
  }

  emitIsAllFilled() {
    this.$emit("isAllFilled", this.isAllFieldsFilled);
  }

  get bannerText() {
    return this.getPhrase("document.type_drivers_licence.digital_notification_banner");
  }

  get bannerIconAttrs() {
    return {
      name: "needs-attention-status",
      backgroundColor: COLORS.COLOR__WARN_HARD,
      color: COLORS.COLOR__WARN_LIGHT,
    };
  }

  emitInput() {
    this.$emit("input", this.buffer.clone());
  }
  get isDocNumberRequiredConfig(): boolean {
    return Boolean(this.$feature("driversLicenceCardNumberRequired"));
  }
  get isAllFieldsFilled(): boolean {
    // Use value to determine if data is all filled instead of buffer, since it
    // represents the values already held by the parent component
    const { country, region, idNumber, extraData } = this.value;

    if (!country) return false;

    /** NEW ZEALAND DRIVERS LICENCE VALIDATION  */
    // TODO: Include more complete validation for NZL

    if (country === "NZL") {
      const { minLength: idNumberMin, maxLength: idNumberMax } = idNumberValidation();
      const { minLength: versionNumberMin, maxLength: versionNumberMax } = versionNumberValidation();
      const isNumberValid = checkStringLength(idNumber, idNumberMin, idNumberMax);
      const isVersionValid = checkStringLength(extraData.licence_version, versionNumberMin, versionNumberMax);
      return isNumberValid && isVersionValid;
    }

    if (country !== "AUS") return Boolean(idNumber && idNumber.length > 0);

    /** AUSTRALIAN DRIVERS LICENCE VALIDATION */
    if (!region || !idNumber) return false;

    const isDocNumberEmpty = () => {
      // Document number (card number) is considered empty if
      // 1) IF doc number is active/required by configuration
      const isDocNumberRequiredAtAll = this.isDocNumberRequiredConfig;
      // 2) IF that particular state requires doc number
      const statesRequiringDocNumber = this.statesWithDocumentNumberConfig;
      const stateRequiresDocNumber = statesRequiringDocNumber.includes(region);
      // 3) IF the value of doc number is empty
      const isDocNumberEmpty = !extraData.document_number;
      // Otherwise document number "emptyness" isn't relevant, so it's considered false
      return stateRequiresDocNumber && isDocNumberRequiredAtAll && isDocNumberEmpty;
    };

    if (isDocNumberEmpty()) return false;

    // licence number validation
    const licenseTrim = idNumber.replace(/\s/g, "") || "";

    const validationForStateType = licenceNumberValidationPerState(region);
    const idNumberValidated = checkStringLength(licenseTrim, validationForStateType.minLength, validationForStateType.maxLength);

    //doc number validation
    const docNumber = extraData.document_number || "";
    if (idNumberValidated && !docNumber) return true;

    const { minLength, maxLength } = docNumberValidationPerState(region);
    const documentNumberValidated = typeof docNumber === "string" && checkStringLength(docNumber, minLength, maxLength);
    return idNumberValidated && documentNumberValidated;
  }

  get acceptedCountries(): string[] {
    const documentTypes = this.$store.getters.config("documentTypes") ?? [];
    const acceptedCountries = this.$store.getters.config("acceptedCountries") ?? [];

    return resolveAcceptedCountriesForDocType(documentTypes, "DRIVERS_LICENCE", acceptedCountries);
  }

  get isAUSOnly(): boolean {
    const hasOnlyOneCountry = this.acceptedCountries.length === 1;
    const isCountryAUS = this.acceptedCountries[0] === "AUS";
    return hasOnlyOneCountry && isCountryAUS;
  }

  dispatch(eventName: eventNames) {
    this.$root.dispatchEvent({
      eventName,
    });
  }

  @Watch("step.slug", { immediate: true })
  onSlugChanged(newSlug: string) {
    // If the country options is not just AUS, then we need to emit the submit event to skip the state selection page
    // TODO: write test case for this
    if (!this.isAUSOnly && newSlug === this.IDS.INPUT_DRIVERS_STATE) {
      this.$emit("submit");
    }
  }
  @Watch("buffer", { immediate: true, deep: true })
  onBufferUpdated() {
    /**
     * Every time buffer.country changes, we need to vue.$set new dynamic fields and $delete unused dynamic fields
     * extraData is empty by default, which causes those fields not to be reactive if this isn't done.
     *
     * 1) Map country codes to custom fields
     * 2) Helper functions resetFor and deleteFor simply apply $set and $delete to those fields
     * 3) Some countries require further changes and those can be defined as functions in the map customSetup
     *    - up: called when that country is set
     *    - down: called when another country is set
     *
     * 4) Run customSetup[COUNTRY].up && resetFor(COUNTRY)
     * 5) For each other country, Run customSetup[OTHER].down && deleteFor(OTHER)
     */

    const mapCountryExtraData = {
      AUS: ["digital_licence", "document_number"],
      NZL: ["licence_version"],
    };

    const resetFor = (country: string) => {
      mapCountryExtraData[country]?.forEach((key) => {
        this.$set(this.buffer.extraData, key, this.buffer.extraData[key] ?? null);
      });
    };
    const deleteFor = (country: string) => {
      mapCountryExtraData[country]?.forEach((key) => {
        this.$delete(this.buffer.extraData, key);
      });
    };

    const customSetup = {
      AUS: {
        up: () => {
          // If the selected state doesn't require number, clear it
          const isDocNumberRequiredForState = this.statesWithDocumentNumberConfig.includes(this.buffer.region!);
          if (!isDocNumberRequiredForState) this.buffer.extraData.document_number = null;
        },
        down: () => {
          this.buffer.region = null;
        },
      },
    };

    if (this.buffer.country) {
      const currentCountry = this.buffer.country;
      const notCurrentCountry = (country: string) => country !== currentCountry;
      const allOtherData = Object.keys(mapCountryExtraData).filter(notCurrentCountry);
      const allCustomSetup = Object.keys(customSetup).filter(notCurrentCountry);

      // If has custom data, set it
      resetFor(currentCountry);
      // If has custom setup, run it
      customSetup[currentCountry]?.up?.();

      // Delete all other data
      allOtherData.forEach(deleteFor);
      // Run custom setdown for all other countries
      allCustomSetup.forEach((country) => customSetup[country]?.down?.());
    }
  }

  @Watch("buffer.region", { immediate: true })
  resetLicenceImageVariant() {
    this.licenceImageVariant = null;
  }

  mounted() {
    this.dispatch("SCREEN:DRIVERS_LICENCE_INPUT");
  }
}
