import {
  AUSLicence,
  AustralianPassport,
  ForeignPassport,
  IndoNationalId,
  MedicareBlue,
  MedicareGreen,
  MedicareYellow,
  NewZealandPassport,
  NZLLicence,
  SingaporeNationald,
  SingaporeNationalIdName,
  ThaiNationalIdBack,
  ThaiNationalIdFront,
  ThaiNationalIdFrontEnglishName,
  ThaiNationalIdFrontName,
} from "@/assets/svgs/index";
import SmartUIImage from "@/components/SmartUIImage.vue";
import { Document, countryData } from "@frankieone/shared";
import deepcopy from "deepcopy";
import { merge } from "lodash";
/* eslint-disable @typescript-eslint/camelcase */

export const SUPPORTED_DOCUMENTS: TSupportedDocuments[] = ["PASSPORT", "DRIVERS_LICENCE", "NATIONAL_HEALTH_ID", "NATIONAL_ID"];

export const ID_TYPES = {
  DRIVERS_LICENCE: "DRIVERS_LICENCE",
  PASSPORT: "PASSPORT",
  VISA: "VISA",
  NATIONAL_HEALTH_ID: "NATIONAL_HEALTH_ID",
  IMMIGRATION: "IMMIGRATION",
  BIRTH_CERT: "BIRTH_CERT",
  CITIZENSHIP: "CITIZENSHIP",
  NAME_CHANGE: "NAME_CHANGE",
  MARRIAGE_CERT: "MARRIAGE_CERT",
  ATTESTATION: "ATTESTATION",
  VEHICLE_REGISTRATION: "VEHICLE_REGISTRATION",
  SELF_IMAGE: "SELF_IMAGE",
  OTHER: "OTHER",
  NATIONAL_ID: "NATIONAL_ID",
  BANK_STATEMENT: "BANK_STATEMENT",
};

export const ID_COUNTRY_CODES = {
  AUSTRALIA: "AUS",
  NEW_ZEALAND: "NZL",
  UNITED_KINGDOM: "GBR",
  INDIA: "IND",
  PHILIPPINES: "PHL",
  CHINA: "CHN",
  HONG_KONG: "HKG",
  INDONESIA: "IDN",
  MALAYSIA: "MYS",
  SPAIN: "ESP",
  SWEDEN: "SWE",
  ITALY: "ITA",
  BRAZIL: "BRA",
  MEXICO: "MEX",
  RUSSIA: "RUS",
  TURKEY: "TUR",
  SINGAPORE: "SGP",
  SOUTH_AFRICA: "ZAF",
  ARGENTINA: "ARG",
  JORDAN: "JOR",
  KUWAIT: "KWT",
  OMAN: "OMN",
  SAUDI_ARABIA: "SAU",
  EGYPT: "EGY",
  ROMANIA: "ROU",
  VIETNAM: "VNM",
  CAMBODIA: "KHM",
  DEFAULT: "DEFAULT",
};

export function mkDocumentForType(idType: Document["idType"] = "OTHER"): Document {
  let extraData;
  const standardDocTypes = ["NATIONAL_HEALTH_ID", "DRIVERS_LICENCE", "PASSPORT"];
  switch (idType) {
    case "NATIONAL_HEALTH_ID":
      extraData = {
        reference: null,
      };
      break;
    default:
      extraData = {};
  }
  const doc = new Document();
  if (idType === "NATIONAL_HEALTH_ID") {
    doc.idSubType = "G";
  }
  doc.idType = idType;
  if (standardDocTypes.includes(idType as string)) {
    doc.country = "AUS";
  }
  doc.extraData = extraData;
  return doc;
}

type Options = Pick<Document, "country" | "region">;
export function getLicenceImage(document: Options): VueComponent | null {
  const { country, region } = document;
  if (country === "NZL") return NZLLicence;
  if (country === "AUS" && region) return AUSLicence;

  return null;
}

export function getMedicareImage(type: string | null): VueComponent {
  switch (type) {
    case "G":
      return MedicareGreen;
    case "B":
      return MedicareBlue;
    case "Y":
      return MedicareYellow;
    default:
      return MedicareGreen;
  }
}
export const isInFormat = (comp) => typeof comp.is !== "undefined";
export const testArguments =
  (...args: Parameters<typeof getNationalIdImage>) =>
  (...testedArgs: Parameters<typeof getNationalIdImage>): boolean => {
    const [country, variation] = args;
    const [testedCountry, testedVariation] = testedArgs;
    const hasVariation = typeof testedVariation !== "undefined";
    // if testing variation, does it match?
    const variationMatches = hasVariation ? testedVariation === variation : true;

    const countryMatches = testedCountry === country;
    return countryMatches && variationMatches;
  };
/**
 * This function returns a DynamicComponent object specifying at minimum a property "is" containing a vue component which renders
 * the image for the national id of the specified country, but also optionally extra parameters to be passed to that component at render-time.
 * For example { is: SmartUIImage, name: 'example.png' } will render the component SmartUIImage with the prop name: 'example.png'.
 *
 * Optionally you may specify a variation for said image, front, back, front-name etc..
 * The function above "testArgs" will attempt to match the requested combination country + variation
 * procedurally (a bunch of if else's until we refactor this into a simpler dictionary match) of country + variation => DynamicComponent in the order defined.
 * The first match will be returned, or the "default".
 *
 * @param country Char3 ISO code for the country to which national id image is for
 * @param variation optional variation of the image. Either front, back or highlighting a specific field (such as name)
 */
export function getNationalIdImage(country: string, variation?: "front" | "back" | "front-name" | "front-name-english"): DynamicComponent | null {
  // ALL IMAGES CONTAINED IN ALL VUE COMPONENTS BELOW, EXCEPT "SmartUIImage", ARE DEPLOYED TO S3.
  // THEY ARE NOT LOCAL FILES
  const is = testArguments(country, variation);

  if (is("THA", "front-name-english")) return { is: ThaiNationalIdFrontEnglishName };
  if (is("THA", "front-name")) return { is: ThaiNationalIdFrontName };
  if (is("THA", "back")) return { is: ThaiNationalIdBack };
  if (is("THA", "front")) return { is: ThaiNationalIdFront };

  if (is("IDN")) return { is: IndoNationalId };

  if (is("SGP", "front-name")) return { is: SingaporeNationalIdName };
  if (is("SGP")) return { is: SingaporeNationald };
  // to indicate there's no image for that country, simply return null.
  // since this is outside the scope of my task I'll leave this example below
  if (is("AUS")) return null;

  return {
    is: SmartUIImage,
    name: "national-id.png",
  };
}

export function getPassportImage(country: string): VueComponent {
  switch (country) {
    case "AUS":
      return AustralianPassport;
    case "NZL":
      return NewZealandPassport;
    default:
      return ForeignPassport;
  }
}

export function getDocumentImageForDocument(document: Document): DynamicComponent | null {
  const { region, idSubType, idType, country } = document;

  let image: VueComponent | null;

  switch (idType) {
    case "DRIVERS_LICENCE":
      image = getLicenceImage(document);
      break;
    case "PASSPORT":
      image = getPassportImage(country || "AUS");
      break;
    case "NATIONAL_HEALTH_ID":
      image = getMedicareImage(idSubType);
      break;
    default:
      image = null;
  }
  // why default country to "THA-front-name"?
  if (idType === "NATIONAL_ID" && country) return getNationalIdImage(country);
  if (!image) return null;
  return {
    is: image,
  };
}

export const documentConfigurationDefaults: DocConfigDefaults = {
  NATIONAL_ID: {
    IDN: { includesPersonalData: ["INPUT_NAME"] }, // details specific to a country
    icon: "national-id",
    label: "document.type_national_id.label",
    subtitle: "document.type_national_id.subtitle",
  },
  PASSPORT: {
    idExpiry: false,
    icon: "passport",
    label: "document.type_passport.label",
    subtitle: "",
  },
  NATIONAL_HEALTH_ID: {
    icon: "medicare",
    label: "document.type_medicare.label",
    subtitle: "",
  },
  DRIVERS_LICENCE: {
    icon: "driving-licence",
    label: "document.type_drivers_licence.label",
    subtitle: "document.type_drivers_licence.subtitle",
    acceptedCountries: undefined,
  },
};

export const getTypedDocConfigDefaults = (idType: IdType, country?: Country): TypedDocConfig => {
  // Returns a TYPED doc config body
  const defaultDocObject = { type: idType, icon: "national-id" };
  const docDefaults = documentConfigurationDefaults[idType] as DocConfigBody & {
    type: IdType;
  };

  // No defaults for idType were found, simply return the "default defaults" for any type
  if (!docDefaults) return defaultDocObject;
  // If defaults contain specific configuration for a country, use that as a final default configuration
  if (country && docDefaults[country])
    return Object.assign({}, docDefaults[country], {
      type: idType,
    });

  return {
    ...docDefaults,
    type: idType,
  };
};

export const findDocumentConfigInList = (config: TypedDocConfig[], idType: TSupportedDocuments, country?: Country): TypedDocConfig | null => {
  if (!config) return null;
  const found = config.find((i) => i.type === idType) || null;
  if (!found) return null;
  if (!country) return stripCountryCodes(found);
  const foundCountry = found[country] ?? {};
  const merged = merge({}, found, foundCountry);

  return stripCountryCodes(merged);
};
export const docConfigIncludesPersonalData = (config: DocConfigBody, personalDataFieldName: string): boolean => config?.includesPersonalData?.includes(personalDataFieldName as COMPONENT) ?? false;
const stripCountryCodes = (config: TypedDocConfig): TypedDocConfig => {
  const fullClone = deepcopy(config);
  countryData.forEach((country) => {
    const alpha3 = country.alpha3Code;
    delete fullClone[alpha3];
  });
  return fullClone;
};

export function getDocumentForDocumentType(idType: Document["idType"] = "OTHER"): Document {
  let extraData;
  switch (idType) {
    case "NATIONAL_HEALTH_ID":
      extraData = {
        reference: null,
      };
      break;
    default:
      extraData = {};
  }
  const doc = new Document();
  if (idType === "NATIONAL_HEALTH_ID") {
    doc.idSubType = "G";
  }
  doc.idType = idType;
  doc.country = "AUS";
  doc.extraData = extraData;
  return doc;
}
