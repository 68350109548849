import store from "@/store"

const errorHandler = (error: Error) => {
  store.dispatch.dispatchEvent({
    eventName: "JAVASCRIPT_ERROR",
    payload: {
      name: error.name,
      message: error.message,
      stackTrace: error.stack ?? ''
    }
  })
}

export default errorHandler