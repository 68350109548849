import { Applicant, Document } from "@frankieone/shared";
import { initial } from "lodash";
import { EMPTY_STRING } from "@/utils/constants";

export function getApplicantNameForCountryId(
  country: string,
  value: string
): Applicant["name"] {
  const name: Applicant["name"] = {
    givenName: null,
    middleName: null,
    familyName: null,
    displayName: null,
  };
  const trimmedName = value?.trim();
  const splitNames = trimmedName?.split(" ");
  switch (country) {
    case "IDN":
      if (splitNames?.length > 1) {
        name.displayName = value;
        name.givenName = initial(splitNames).join(" ");
        name.familyName = splitNames[splitNames.length - 1];
      } else {
        name.displayName = value;
        name.givenName = value;
        name.familyName = EMPTY_STRING;
      }
      return name;
    default:
      return name;
  }
}

export const GENDER_OPTIONS = [
  {
    label: "Male",
    value: "M",
  },
  {
    label: "Female",
    value: "F",
  },
  {
    label: "Unknown",
    value: "U",
  },
];

export const MARITAL_STATUS_OPTIONS = [
  {
    label: "Single",
    value: "Single",
  },
  {
    label: "Married",
    value: "Married",
  },
  {
    label: "Widowed",
    value: "Widowed",
  },
  {
    label: "Divorced",
    value: "Divorced",
  },
];

export function getNameFields(idDoc: Document | null): TFieldNames[] {
  const defaultFields: TFieldNames[] = [
    "first",
    { field: "middle", required: false },
    "last",
  ];
  const thaiFields: FieldName[] = ["first", "last"];
  const sgpFields: TFieldNames[] = [
    "first",
    { field: "last", required: false },
    "gender",
    "marital_status",
  ];

  if (!idDoc) return defaultFields;

  switch (idDoc?.country) {
    case "THA":
      return thaiFields;
    case "SGP":
      return sgpFields;
    default:
      return defaultFields;
  }
}

export function hasExtraName(documents: Document[]) {
  return documents.some(
    (document) =>
      document?.country === "THA" && document?.idType === "NATIONAL_ID"
  );
}
