



























import { Component, Vue } from "vue-property-decorator";
import {
  Applicant,
  CheckSummary,
  Document,
  SupportingDocument,
} from "@frankieone/shared";

import SummaryForm from "@/forms/SummaryForm.vue";
import {
  ArgumentFindViewExtra,
  findViewForCheckResult,
  mkExternalIdvViewOutcome,
  mkPendingViewOutcome,
  mkSuccessViewOutcome,
  mkViewFromViewOutcome,
  ViewObject,
} from "@/utils/checks";

import { type } from "../utils/configurationParser";
import { getNameFields } from "../utils/personalDetails";
import LoadingVerify from "./LoadingVerify.vue";

@Component({
  components: { SummaryForm, LoadingVerify },
})
export default class DetailsSummary extends Vue {
  medicareDisplayName: null;

  get currentStep(): CurrentStepObject {
    return this.$store.direct.getters.currentStep;
  }

  get supportingDocuments(): (SupportingDocument | null)[] {
    return this.$store.direct.getters.supportingDocuments;
  }

  get supportingDocumentUploadFields(): DocumentUpload[] {
    return this.$store.direct.getters.supportingDocumentUploadFields;
  }

  get documents(): Document[] {
    const documents = this.$store.direct.getters.selectedDocuments as any;
    const acceptedTypes = this.acceptedDocTypes.map((d) => d.type);

    const isAcceptableDocument = (doc: Document) =>
      acceptedTypes.includes(doc.idType!);
    const filteredDocs = documents.filter(isAcceptableDocument);

    return filteredDocs;
  }

  get organisationName(): IWidgetConfiguration["organisationName"] {
    const { config } = this.$store.direct.getters;
    return config(
      "organisationName",
      type.string
    ) as IWidgetConfiguration["organisationName"];
  }

  get acceptedDocTypes(): { type: Document["idType"] }[] {
    const { config } = this.$store.direct.getters;
    return config("documentTypes", type.array(type.object)) as {
      type: Document["idType"];
    }[];
  }

  get acceptedAgeRange(): IWidgetConfiguration["ageRange"] {
    const ageRange = this.$store.direct.getters.config(
      "ageRange",
      type.array(type.number)
    ) as IWidgetConfiguration["ageRange"];
    return ageRange;
  }

  get acceptedCountries(): Document["country"][] {
    return this.$store.direct.getters.countriesList;
  }

  get currentView() {
    return this.$store.direct.getters.currentView;
  }

  get applicant(): Applicant {
    return this.$store.direct.state.personal.applicant;
  }

  get consentGiven(): boolean {
    return this.$store.direct.state.checks.consentGiven;
  }

  set consentGiven(v: boolean) {
    this.$store.direct.state.checks.consentGiven = v;
  }

  get dobType(): TDOBTypeSupport {
    return this.$store.direct.getters.config("dateOfBirth")
      .type as TDOBTypeSupport;
  }

  get displayMiddleNameValue(): string | null {
    const medicareDoc =
      this.$store.direct.getters.getDocumentOfType("NATIONAL_HEALTH_ID");
    return (medicareDoc?.extraData?.display_middle_name as string) ?? null;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setIsEditMode(isEdit) {
    // this.$store.direct.state.system.isNavigationHidden = isEdit;
  }

  updateDocuments(docs: Document[]) {
    docs.forEach((doc, i) => {
      this.$store.direct.dispatch.updateDocument({ document: doc, index: i });
    });
    this.updateMedicareMiddleName();
  }

  get externalIdv(): boolean {
    const { config } = this.$store.direct.getters;
    const hasIdScanVerification = config("idScanVerification", type.bool);
    return hasIdScanVerification;
  }

  updateApplicant(app: Applicant) {
    this.$store.direct.dispatch.updateApplicant(app);
  }

  updateSupportingDocuments(supportingDocuments: SupportingDocument[]) {
    supportingDocuments.forEach((d, i) =>
      this.$store.direct.dispatch.addSupportingDocument({
        document: d,
        index: i,
      })
    );
  }

  get defaultConsentText(): string {
    return `
    	I consent to my personal information being collected and used in accordance with the organisation's Privacy Policy. 
      In addition, for the purposes of verifying my identity, I consent to: 
      a) the verification of my personal information with credit bureau header files (for verification only); 
      b) against records held by official document issuers or official record holders via third party systems; and 
      c) your verification agent(s) acting as a nominated intermediary in accordance with Australian Privacy Principles. 
      I consent to the use by third parties of the results of any verification checks on my identity for the purposes of monitoring and improving their verification services.`;
  }

  get configuredConsentText(): string | null {
    const { config } = this.$store.direct.getters;

    return config(
      "consentText",
      type.nString
    ) as IWidgetConfiguration["consentText"];
  }

  get consentText(): string {
    return this.configuredConsentText
      ? this.configuredConsentText
      : this.defaultConsentText;
  }

  get hasMedicareDoc(): boolean {
    return !!this.$store.direct.getters.getDocumentOfType("NATIONAL_HEALTH_ID");
  }

  setMedicareDisplayName(value) {
    this.medicareDisplayName = value;
  }

  async updateMedicareMiddleName() {
    const medicareDoc =
      this.$store.direct.getters.getDocumentOfType("NATIONAL_HEALTH_ID");
    if (medicareDoc) {
      const medicareDocIndex = this.documents.findIndex(
        (d) => d.idType === "NATIONAL_HEALTH_ID"
      );
      medicareDoc.extraData.display_middle_name = this.medicareDisplayName;
      await this.updateDocument(medicareDoc, medicareDocIndex);
    }
  }

  async updateDocument(document: Document, index = 0): Promise<void> {
    return this.$store.direct.dispatch.updateDocument({ document, index });
  }

  get nameInputFieldNames(): TFieldNames[] {
    const nationalIdDoc =
      this.$store.direct.getters.getDocumentOfType("NATIONAL_ID");

    return getNameFields(nationalIdDoc);
  }

  async mkSaveOnlyView(): Promise<ViewObject> {
    const view = (() => {
      const successViewOutcome = mkSuccessViewOutcome();
      const pendingViewOutcome = mkPendingViewOutcome();
      let viewOutcome = successViewOutcome;
      if (this.externalIdv)
        viewOutcome = mkExternalIdvViewOutcome(pendingViewOutcome);

      const view = mkViewFromViewOutcome(viewOutcome);
      return view;
    })();
    await this.dispatchEvent({
      eventName: "SUBMIT:SAVE_ONLY",
      payload: {
        addressCount: this.applicantAndDocuments.applicant.addresses?.length ?? 0
      },
    });
    if (window.CustomEvent) {
      const { CustomEvent } = window;
      const outcome = new CustomEvent("FF_SAVE_RESULT", {
        detail: {
          applicant: this.applicantAndDocuments.applicant,
          documents: this.applicantAndDocuments.documents ?? [],
          attemptCount: this.attemptCount,
          maxAttemptCount: this.maxAttemptCount,
          externalIdv: this.externalIdv,
          isCheckingAddress: this.isCheckingAddress,
          isCheckingIDs: this.isCheckingIDs,
          isSaveOnly: this.isSaveOnly,
          nextView: view,
        },
      });
      window.dispatchEvent(outcome);
    }
    return view;
  }

  async mkCheckResultsView(checkSummary: CheckSummary): Promise<ViewObject> {
    const checked = this.$store.direct.getters.personalDetailsChecked;
    const hasAvailableDocumentsToReview =
      this.$store.direct.getters.hasAvailableDocsToReview;
    const isPersonalInfoReviewed = Object.values(checked).every(Boolean);
    const creditHeaderWarning = !!checkSummary?.issues?.creditHeader;

    await this.$store.direct.dispatch.incrementAttemptCount();
    const extra: ArgumentFindViewExtra = {
      isPersonalInfoReviewed,
      hasAvailableDocumentsToReview,
      attemptCount: this.attemptCount,
      maxAttemptCount: this.maxAttemptCount,
      externalIdv: this.externalIdv,
      isCheckingIDs: this.isCheckingIDs,
      isCheckingAddress: this.isCheckingAddress,
      personalInfoReviewed: checked,
      creditHeaderWarning,
    };

    const checkViewOutcome = findViewForCheckResult(checkSummary, extra);
    const resultSlug = (() => {
      const resultView = checkViewOutcome.nextView ?? checkViewOutcome;
      return resultView.slug;
    })();
    const nextViewSlug = checkViewOutcome.slug;
    const view = mkViewFromViewOutcome(checkViewOutcome);
    const detail = {
      checkSummary,
      resultSlug,
      nextViewSlug,
      applicant: this.applicantAndDocuments.applicant,
      documents: this.applicantAndDocuments.documents ?? [],
      allPersonalInformationReviewedByUser: isPersonalInfoReviewed,
      allDocumentsReviewedByUser: hasAvailableDocumentsToReview,
      attemptCount: this.attemptCount,
      maxAttemptCount: this.maxAttemptCount,
      externalIdv: this.externalIdv,
      isCheckingAddress: this.isCheckingAddress,
      isCheckingIDs: this.isCheckingIDs,
      nextView: checkViewOutcome,
    };
    const outcome = new CustomEvent("FF_CHECK_RESULT", {
      detail,
    });
    const eventDetail = {
      entityId: this.applicantAndDocuments.applicant.entityId,
      maxAttemptCount: this.maxAttemptCount,
      attemptCount: this.attemptCount,
      addressCount: this.applicantAndDocuments.applicant.addresses?.length ?? 0
    };
    await this.dispatchEvent({
      eventName: "SUBMIT:RUN_CHECK",
      payload: { ...(eventDetail as any) },
    });
    if (window.CustomEvent) {
      window.dispatchEvent(outcome);
    }

    return view;
  }

  async submitData() {
    let view: ViewObject | string;
    await this.$store.direct.dispatch.setIdle(true);
    try {
      const checkSummary =
        await this.$store.direct.dispatch.initializeAndRunChecks();
      if (!checkSummary) view = await this.mkSaveOnlyView();
      else view = await this.mkCheckResultsView(checkSummary);
    } catch (e) {
      console.error(e);
      const eventName = this.isSaveOnly ? "FF_SAVE_ERROR" : "FF_CHECK_ERROR";
      const errorEvent = new CustomEvent(eventName, {
        detail: e,
      });
      window.dispatchEvent(errorEvent);
      if (e && (e as any).isAxiosError && !this.disableThirdPartyAnalytics) {
        const { response } = e as any;
        // eslint-disable-next-line default-case
        switch (response?.status) {
          case 400:
          case 500:
          case 401:
          // TODO: Emit telemetry events here
        }
      }
      this.$store.direct.dispatch.setError({ message: e });

      view = "error";
    }
    await this.$store.direct.dispatch.setIdle(false);
    return view;
  } 

  getDemoView() {
    if (this.isSaveOnly) {
      const outcome = new CustomEvent("FF_SAVE_RESULT", {
        detail: {
          applicant: this.applicantAndDocuments.applicant,
          documents: this.applicantAndDocuments.documents ?? [],
          attemptCount: this.attemptCount,
          maxAttemptCount: this.maxAttemptCount,
          externalIdv: this.externalIdv,
          isCheckingAddress: this.isCheckingAddress,
          isCheckingIDs: this.isCheckingIDs,
          isSaveOnly: this.isSaveOnly,
        },
      });
      window.dispatchEvent(outcome);
    }
    return this.isSaveOnly ? "success" : "outcome";
  }

  async handleNextStep() {
    this.setIsEditMode(true);
    let view: ViewObject | string;
    if (this.isDemo) {
      view = this.getDemoView();
    } else {
      view = await this.submitData();
    }
    this.addView(view);
    this.nextStep();
  }

  addView(data: string | ViewObject) {
    this.$store.direct.dispatch.addView(data);
  }

  nextStep() {
    return this.$store.direct.dispatch.nextStep();
  }

  get isSaveOnly() {
    return this.$store.direct.getters.config("saveOnly", type.bool);
  }

  get applicantAndDocuments() {
    return this.$store.direct.getters.personalAndDocuments;
  }

  get isCheckingAddress(): boolean {
    return this.$store.direct.getters.config("requestAddress", type.bool);
  }

  get isCheckingIDs(): boolean {
    return this.$store.direct.getters.config("requestID", type.bool);
  }

  get maxAttemptCount(): number {
    return this.$store.direct.getters.config("maxAttemptCount", type.number);
  }

  get disableThirdPartyAnalytics(): boolean {
    return this.$store.direct.getters.config(
      "disableThirdPartyAnalytics",
      type.bool
    );
  }

  get attemptCount(): number {
    return this.$store.direct.state.checks.attemptCount;
  }

  get isLoading() {
    return this.$store.direct.getters.isIdle;
  }

  async mounted() {
    await this.dispatchEvent({ eventName: "SCREEN:DETAILS_SUMMARY" });
  }
}
