




















import { Address, Applicant, Document, SupportingDocument } from '@frankieone/shared';
import ClickOutside from 'vue-click-outside';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import DocumentUploadsForm from '@/forms/DocumentUploadsForm.vue';
import {
  docConfigIncludesPersonalData,
  getTypedDocConfigDefaults,
  findDocumentConfigInList,
} from '../../utils/documentFactory';
import ApplicantReviewInput from './ApplicantReviewInput.vue';
import DateOfBirthInput from './DateOfBirthInput.vue';
import DocumentReviewInput from './DocumentReviewInput.vue';
import DriversLicenceInput from './DriversLicenceInput.vue';
import FullNameInput from './FullNameInput.vue';
import ManualAddressInput from './ManualAddressInput.vue';
import MedicareInput from './MedicareInput.vue';
import NationalIdInput from './NationalIdInput.vue';
import PassportInput from './PassportInput.vue';
import SupportingDocumentReviewInput from './SupportingDocumentReviewInput.vue';

@Component({
  components: {
    ApplicantReviewInput,
    DocumentReviewInput,
    SupportingDocumentReviewInput,
  },
  directives: { ClickOutside },
})
export default class ReviewInput extends Vue {
  @Prop() applicant: Applicant;

  @Prop() documents: Document[];

  @Prop() supportingDocuments: (SupportingDocument | null)[];

  @Prop() supportingDocumentUploadFields: DocumentUpload[];

  @Prop({ default: () => ['ApplicantReviewInput', 'DocumentReviewInput'] })
  display: string[];

  @Prop() acceptedDocTypes: TypedDocConfig[];

  @Prop() acceptedAgeRange: [number, number];

  @Prop() acceptedCountries: string[];

  @Prop() hasMedicareDoc: boolean;

  @Prop() displayMiddleNameValue: string | null;

  @Prop() dobType: TDOBTypeSupport;

  @Prop({ required: false, default: () => ['first', 'middle', 'last'] })
  fieldNames: FieldName[];

  explicitSelectedField: string | number | null = null;

  reviewStep: number | null = null;

  isAllFilled = true;

  applicantBuffer: Applicant = new Applicant();

  documentsBuffer: Document[] = [];

  documentsValidationState: boolean[] = [];

  supportingDocumentsBuffer: (SupportingDocument | null)[] = [];

  get cancelButtonCta(): string {
    const namespace = 'common.cancel_button_cta';
    return this.getPhrase(namespace);
  }

  get saveButtonCta(): string {
    const namespace = 'common.save_button_cta';
    return this.getPhrase(namespace);
  }

  get selectedField(): string | number | null {
    // if name is selected and a document contains name input, select the document input instead the name input
    if (this.explicitSelectedField === 'name' && this.documentIndexWithNameInput !== null) {
      return this.documentIndexWithNameInput;
    }
    return this.explicitSelectedField;
  }

  set selectedField(value: string | number | null) {
    this.explicitSelectedField = value;
  }

  shouldDisplay(field: string) {
    return this.display.includes(field);
  }

  get isEditMode(): boolean {
    return this.selectedField !== null;
  }

  get isAddressType(): boolean {
    return ['current-address', 'previous-address'].includes(this.inputType as string);
  }

  get inputHeading(): string {
    if (!this.inputType) return '';
    const key = (() => {
      if (this.inputType === 'english-name') return 'edit_title_dual_name_english';
      if (this.inputType === 'native-name') return 'edit_title_dual_name_native';
      if (this.inputType === 'gender') return 'edit_title_name';
      if (this.inputType === 'marital-status') return 'edit_title_name';

      return `edit_title_${this.inputType.replace('-', '_').toLowerCase()}`;
    })();
    const namespace = 'review_details_screen';
    return this.getPhrase([namespace, key].join('.'));
  }

  get selectedInputAttributes(): {
    attrs: any & { is: any };
    inputType: string | null;
    listeners: any;
  } {
    const field = this.selectedField ?? '';
    const inputAttributes: { [s: string]: any } = {
      acceptedDocTypes: this.acceptedDocTypes,
      acceptedCountries: this.acceptedCountries,
      acceptedAgeRange: this.acceptedAgeRange,
      documentConfig: this.documentConfig,
      documents: this.documentsBuffer,
      applicant: this.applicantBuffer,
      supportingDocuments: this.supportingDocumentsBuffer,
      fieldNames: this.fieldNames,
      hasMedicareDoc: this.hasMedicareDoc,
      displayMiddleNameValue: this.displayMiddleNameValue,
      step: { slug: this.IDS.INPUT_DOCUMENT_DATA },
      dobType: this.dobType,
      is: this.inputComponent,
      value: this.bufferData,
      supportingDocumentUploadFields: this.supportingDocumentUploadFields,
      isEdit: true,
    };
    const inputListeners: { [s: string]: any } = {
      input: ($event) => this.updateField(field, $event),
      'update:middleNameValue': ($event) => this.updateMiddleNameValue($event),
      'update:applicant': (applicant) => (this.applicantBuffer = applicant),
      isAllFilled: (isIt) => (this.isAllFilled = isIt),
      supportingDocumentRemoved: (index) => {
        const newDoc = this.supportingDocumentsBuffer[index] || ({} as SupportingDocument);
        newDoc.file = null;
        this.supportingDocumentsBuffer.splice(index, 1, newDoc);
      },
      supportingDocumentAdded: (document) => {
        const index = document.smartUiDocOrder;
        this.supportingDocumentsBuffer.splice(index, 1, document);
      },
    };

    return {
      inputType: this.inputType,
      attrs: inputAttributes,
      listeners: inputListeners,
    };
  }

  get documentConfig(): TypedDocConfig | null {
    const docType = this.inputType as TSupportedDocuments;
    const docConfig = this.acceptedDocTypes;
    return findDocumentConfigInList(docConfig, docType);
  }

  get inputClass(): string | null {
    switch (this.inputType) {
      case 'english-name':
      case 'native-name':
      case 'gender':
      case 'marital-status':
      case 'name':
        return 'ff-fullname-input';
      case 'dob':
        return 'ff-dob-input';
      case 'current-address':
        return 'ff-address-input';
      case 'previous-address':
        return 'ff-address-input';
      case 'PASSPORT':
        return 'ff-passport-input';
      case 'PASSPORT:AUS':
        return 'ff-passport-input';
      case 'DRIVERS_LICENCE':
        return 'ff-licence-inputs-container';
      case 'NATIONAL_HEALTH_ID':
        return 'ff-medicare-input';
      case 'NATIONAL_ID':
        return 'ff-national-id-input';
      case 'uploads':
        return 'ff-fullname-input';
      default:
        return null;
    }
  }

  get inputComponent() {
    switch (this.inputType) {
      case 'name':
      case 'native-name':
      case 'english-name':
        return FullNameInput;
      case 'gender':
        return FullNameInput;
      case 'marital-status':
        return FullNameInput;
      case 'dob':
        return DateOfBirthInput;
      case 'current-address':
        return ManualAddressInput;
      case 'previous-address':
        return ManualAddressInput;
      case 'PASSPORT':
        return PassportInput;
      case 'PASSPORT:AUS':
        return PassportInput;
      case 'DRIVERS_LICENCE':
        return DriversLicenceInput;
      case 'NATIONAL_HEALTH_ID':
        return MedicareInput;
      case 'NATIONAL_ID':
        return NationalIdInput;
      case 'uploads':
        return DocumentUploadsForm;
      default:
        return null;
    }
  }

  get isDocumentSelected() {
    return typeof this.selectedField === 'number';
  }

  get inputType() {
    if (this.isDocumentSelected) {
      const index = this.selectedField as number;
      const document = this.documentsBuffer[index];
      const documentType = document.idType;
      return documentType;
    }
    return this.selectedField as string | null;
  }

  get bufferData() {
    const field = this.selectedField;

    if (this.isDocumentSelected) {
      const index = field as number;
      return this.documentsBuffer[index];
    }

    if (field === 'uploads') return this.supportingDocumentsBuffer;

    if (field === 'name' || field === 'english-name' || field === 'gender' || field === 'marital-status') {
      return this.applicantBuffer;
    }
    if (field === 'native-name') {
      const extra = this.applicantBuffer.extraData;
      const applicantBufferClone = this.applicantBuffer.clone();
      const nativeNameObject = {
        givenName: extra.home_country_givenname,
        middleName: extra.home_country_middlename,
        familyName: extra.home_country_familyname,
      } as Applicant['name'];
      applicantBufferClone.name = nativeNameObject;
      return applicantBufferClone;
    }
    if (field === 'dob') {
      return this.applicantBuffer.dateOfBirth;
    }
    if (field === 'current-address') {
      return this.applicantBuffer.getAddress('RESIDENTIAL1');
    }
    if (field === 'previous-address') {
      return this.applicantBuffer.getAddress('RESIDENTIAL2');
    }
    return null;
  }

  updateDocumentValidationState(index: number, state: boolean) {
    this.documentsValidationState.splice(index, 1, state);
  }

  // eslint-disable-next-line consistent-return
  updateField(field: string | number, data: any) {
    // updated through document review input and document details are being updatedfat
    if (typeof field === 'number') {
      return this.documentsBuffer.splice(field, 1, data);
    }
    if (field === 'name' || field === 'english-name') {
      return (this.applicantBuffer = data);
    }
    if (field === 'native-name') {
      const nameObject = data.name as Applicant['name'];
      this.applicantBuffer.extraData.home_country_givenname = nameObject.givenName as string;
      this.applicantBuffer.extraData.home_country_middlename = nameObject.middleName as string;
      this.applicantBuffer.extraData.home_country_familyname = nameObject.familyName as string;
      return data;
    }
    if (field === 'dob') {
      return (this.applicantBuffer.dateOfBirth = data);
    }
    if (field === "current-address") {
      const index = this.applicantBuffer.addresses.findIndex(
        (a: Address) =>
          a.addressType === "RESIDENTIAL" || a.addressType === "RESIDENTIAL1"
      );
      return (this.applicantBuffer.addresses[index] = data);
    }
    if (field === 'previous-address') {
      const index = this.applicantBuffer.addresses.findIndex((a: Address) => a.addressType === 'RESIDENTIAL2');
      return (this.applicantBuffer.addresses[index] = data);
    }
    // updated through name input and applicant details are being updated
  }

  updateMiddleNameValue(data) {
    this.$emit('update:middleNameValue', data);
  }

  updateIsAllFilled(v: boolean) {
    this.isAllFilled = v;
  }

  emitIsEditMode(mode) {
    this.$emit('update:isEditMode', mode);
  }

  setSelectedField(field: number | string | null = null) {
    this.selectedField = field;
    this.updateBuffers();
    this.emitIsEditMode(field !== null);
  }

  hideForm() {
    this.setSelectedField(null);
    this.updateBuffers();
  }

  emitData() {
    this.emitApplicant();
    this.emitDocuments();
    this.emitSupportingDocuments();
    this.hideForm();
  }

  emitApplicant() {
    const applicant = this.applicantBuffer;
    this.$emit('update:applicant', applicant);
  }

  emitDocuments() {
    const documents = this.documentsBuffer;
    this.$emit('update:documents', documents);
  }

  emitSupportingDocuments() {
    this.$emit('update:supporting_documents', this.supportingDocumentsBuffer);
  }

  updateBuffers() {
    if (this.applicant) this.applicantBuffer = this.applicant.clone();
    if (this.documents) this.documentsBuffer = this.documents.map((d) => d.clone());
    if (this.supportingDocuments) {
      this.supportingDocumentsBuffer = this.supportingDocuments.map((sd: any) => (sd && sd.clone ? sd.clone() : null));
    }
  }

  get documentIndexWithNameInput(): number | null {
    const docConfigIncludesPersonalDataGeneric =
      (personalDataFieldName: string) =>
      (doc: DocumentModel): boolean => {
        const config = getTypedDocConfigDefaults(doc.idType!);
        return Boolean(config) && docConfigIncludesPersonalData(config!, personalDataFieldName);
      };
    const docConfigIncludesPersonalDataCountrySpecific =
      (personalDataFieldName: string) =>
      (doc: DocumentModel): boolean => {
        const config = getTypedDocConfigDefaults(doc.idType!, doc.country!);
        return Boolean(config) && docConfigIncludesPersonalData(config!, personalDataFieldName);
      };
    const index = (i: number): number | null => (i > -1 ? i : null);

    if (!this.documents?.length) return null;
    const genericConfigIdType = this.documents.findIndex(docConfigIncludesPersonalDataGeneric('INPUT_NAME'));
    const countryConfigIdType = this.documents.findIndex(docConfigIncludesPersonalDataCountrySpecific('INPUT_NAME'));

    return index(countryConfigIdType) ?? index(genericConfigIdType);
  }
  @Watch('documentsValidationState', { immediate: true })
  onDocumentsValidationStateChange() {
    const allValid = this.documentsValidationState.every((state) => state === true);
    this.$emit('update:validationState', allValid);
  }
  created() {
    this.updateBuffers();
    // Initialise documentsValidationState to an array of true values
    const documentsLength = this.documents?.length ?? 0;
    const allTrues = Array(documentsLength).fill(true);
    this.documentsValidationState.splice(0, documentsLength, ...allTrues);
  }
}
