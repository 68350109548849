import {
  Document,
  DynamicUIComponent,
  UIBlueprint,
  Applicant,
  GetPhraseFunction,
} from "@frankieone/shared";
import { mkGenericBlueprint } from "./generic-blueprint";
import { mkTHABlueprint } from "./tha-blueprint";
import { mkSGPBlueprint } from "./sgp-blueprint";
import { mkIDNBlueprint } from "./idn-blueprint";

export type Context = {
  getDocument: () => Document;
  setDocument: (document: Document) => void;
  getApplicant: () => Applicant;
  setApplicant: (applicant: Applicant) => void;
  getAcceptedCountries: () => Country[];
  getPhrase: GetPhraseFunction;
};
export function setup(context: Context) {
  const { setDocument, getDocument, getAcceptedCountries } = context;
  const document = getDocument();
  const acceptedCountries = getAcceptedCountries();
  const singleCountryOption = acceptedCountries.length === 1;
  const selectedCountry = document.country;
  const [singleCountryCode] = acceptedCountries;

  if (singleCountryOption && !selectedCountry)
    document.country = singleCountryCode;
  setDocument(document);
}
export function mkNationalIdBlueprint(context: Context): UIBlueprint {
  const children = {};
  children["national-id"] = mkNationalIdCountryBlueprint(context);

  return new UIBlueprint({
    root: new DynamicUIComponent({
      tag: "div",
      classes: ["ff-national-id-input"],
    }),
    children,
  });
}
function mkNationalIdCountryBlueprint(context: Context): UIBlueprint {
  const { getDocument } = context;
  const { country } = getDocument();

  if (country === "IDN") return mkIDNBlueprint(context);
  if (country === "THA") return mkTHABlueprint(context);
  if (country === "SGP") return mkSGPBlueprint(context);
  else return mkGenericBlueprint(context);
}
