import { Action, Getter } from "@/store/types";
import { type } from "@/utils/configurationParser";
import {
  Address,
  Applicant,
  CheckSummary,
  Document,
  IApplicantDetails,
  IApplicantInformation,
  SupportingDocument,
} from "@frankieone/shared";
import { defineModule } from "direct-vuex";
import { set } from "lodash";
import { moduleActionContext, moduleGetterContext } from "../..";
import { DeviceCheckDetails } from "@frankieone/shared/dist/types";

const mkModuleActionContext = (original: any) =>
  moduleActionContext(original, mod);
const mkModuleGetterContext = (original: any) =>
  moduleGetterContext(original, mod);
export interface ChecksStoreModule {
  state: {
    checkResults: CheckSummary[];
    consentGiven: boolean;
    attemptCount: number;
  };
  getters: {
    isApplicantCreated: Getter<boolean>;
    personalAndDocuments: Getter<IApplicantInformation>;
  };
  actions: {
    createApplicant: Action;
    saveApplicant: Action;
    incrementAttemptCount: Action;
    initializeAndRunChecks: Action<void, Promise<CheckSummary | null>>;
    triggerChecks: Action<void, Promise<IApplicantDetails>>;
    reloadData: Action<
      void,
      Promise<{
        applicant: Applicant;
        documents: Document[];
        checkSummary: CheckSummary;
      }>
    >;
  };
}

/**
 * STATE
 */
const STATE: ChecksStoreModule["state"] = {
  checkResults: [],
  consentGiven: false,
  attemptCount: 0,
};

/**
 * GETTERS
 */
const GETTERS: ChecksStoreModule["getters"] = {
  isApplicantCreated: (...args) => {
    const { rootState } = mkModuleGetterContext(args);
    const { applicant } = rootState.personal;
    return !!applicant.entityId;
  },
  personalAndDocuments: (...args) => {
    const {
      rootState: {
        personal: { applicant },
        documents: { documentsList: documents, supportingDocuments },
      },
    } = mkModuleGetterContext(args);

    const cleanUpFileContent = (doc: SupportingDocument) =>
      set(doc, "file.file", null);
    const isSupportingDocument = (
      doc: SupportingDocument | null
    ): doc is SupportingDocument => Boolean(doc);
    const supportingDocumentsClean = supportingDocuments
      .filter(isSupportingDocument)
      .map(cleanUpFileContent);

    return {
      applicant,
      documents,
      supportingDocuments: supportingDocumentsClean,
    };
  },
};

/**
 * ACTIONS
 */
const ACTIONS: ChecksStoreModule["actions"] = {
  async createApplicant(context) {
    const { getters, rootState, rootGetters, dispatch } =
      mkModuleActionContext(context);
    const { personalAndDocuments } = getters;
    const { frankie, config } = rootGetters;
    // const { applicant, documents, supportingDocuments } = personalAndDocuments;
    const data: IApplicantInformation = personalAndDocuments;
    const noAddress = !config("requestAddress", type.bool);
    const { consentGiven } = rootState.checks;
    // Attach consent to the applicant
    if (consentGiven) {
      rootState.personal.applicant.consents = [
        "general",
        "docs",
        "creditheader",
      ];
    }
    const { entityId } = await frankie!.createApplicant(data, {
      noAddress,
      dataConsent: consentGiven,
    });
    rootState.personal.applicant.entityId = entityId;
    return dispatch.reloadData();
  },
  async reloadData(context) {
    const { getters, rootState, rootGetters } = mkModuleActionContext(context);
    const { personalAndDocuments } = getters;
    const { frankie } = rootGetters;
    const { applicant, documents } = personalAndDocuments;
    const entityId = applicant.entityId!;

    const details = await frankie?.getApplicantDetails(entityId);
    if (!details)
      throw new Error("There was a issue comunicating with Frankie");
    const sorter = (d1, d2) =>
      Number(d1.extraData["widget-index"]) -
      Number(d2.extraData["widget-index"]);
    const theDocuments = details?.documents ?? documents ?? [];
    rootState.personal.applicant = details?.applicant ?? applicant;
    rootState.documents.documentsList = theDocuments.sort(sorter);
    return details;
  },
  async triggerChecks(context) {
    const { rootGetters } = mkModuleActionContext(context);
    const { frankie, applicantDetails } = rootGetters;
    const { entityId } = applicantDetails;

    if (!entityId) {
      throw new Error(
        "Missing Frankie entity id. Applicant potentially wasn't submitted yet"
      );
    }
    const verifyOptions: {
      fetchDetails?: boolean;
      manualKyc?: boolean;
      deviceCheckDetails?: DeviceCheckDetails;
    } = {
      fetchDetails: true,
    };
    if (rootGetters.getDeviceCheckDetails?.activityType) {
      verifyOptions.deviceCheckDetails = rootGetters.getDeviceCheckDetails;
    }
    return (await frankie!.triggerChecks(
      entityId!,
      verifyOptions
    )) as IApplicantDetails;
  },
  async saveApplicant(context) {
    const { rootGetters, getters, dispatch, rootState } =
      mkModuleActionContext(context);
    const { personalAndDocuments } = getters;
    const { frankie, applicantDetails, config } = rootGetters;
    const { applicant } = personalAndDocuments;
    const data: IApplicantInformation = getters.personalAndDocuments;
    const { entityId } = applicantDetails;
    if (!entityId) {
      throw new Error(
        "Missing Frankie entity id. Applicant potentially wasn't submitted yet"
      );
    }
    const noAddress = !config("requestAddress", type.bool);
    const { consentGiven } = rootState.checks;
    // Attach consent to the applicant
    if (consentGiven) {
      rootState.personal.applicant.consents = [
        "general",
        "docs",
        "creditheader",
      ];
    }
    // removing empty address that might end up here on some edge case
    const isSingLineAddress = (add: Address): boolean =>
      add.hasExplicitLongForm();
    applicant.addresses = applicant.addresses.filter(
      (a: Address) => !a.isIncomplete || isSingLineAddress(a)
    );
    await frankie!.updateApplicant(entityId, data, {
      noAddress,
      dataConsent: consentGiven,
    });

    return dispatch.reloadData();
  },
  async initializeAndRunChecks(context) {
    const { getters, dispatch, rootGetters, rootState } =
      mkModuleActionContext(context);
    try {
      // check again if entity doesn’t exist prior to submission.
      // if it does now, update entity id accordingly
      const { frankie, applicantDetails } = rootGetters;
      const { customerReference: reference, entityId } = applicantDetails;
      if (reference && !entityId) {
        const { applicant } = (await frankie!.search({ reference })) || {};
        rootState.personal.applicant.entityId = applicant.entityId;
      }
    } catch (e) {
      const { response, isAxiosError } = (e as any) || {};
      const errorNotFoundEntity = isAxiosError && response?.status === 404;
      if (!errorNotFoundEntity) throw e;
    }
    const isAlreadyCreated = getters.isApplicantCreated;

    if (!isAlreadyCreated) {
      await dispatch.createApplicant();
    } else {
      await dispatch.saveApplicant();
    }
    if (!rootGetters.config("saveOnly")) {
      const { checkSummary } = await dispatch.triggerChecks();
      rootState.checks.checkResults.push(checkSummary);
      return checkSummary;
    }
    return null;
  },
  incrementAttemptCount(context) {
    const { state } = mkModuleActionContext(context);
    state.attemptCount += 1;
  },
};

const mod = defineModule({
  state: STATE,
  getters: GETTERS,
  actions: ACTIONS,
} as ChecksStoreModule);

export default mod;
