















import { Component, Vue } from "vue-property-decorator";
import { Document } from "@frankieone/shared";
import OutcomeMessage from "@/components/OutcomeMessage.vue";
import { type } from "../utils/configurationParser";

@Component({
  components: {
    OutcomeMessage,
  },
})
export default class Success extends Vue {
  get currentView() {
    return this.$store.direct.getters.currentView;
  }

  get creditHeaderIssue() {
    return this.currentView.data.issue;
  }

  get ctaText(): string {
    const configured = this.$store.direct.getters.config(
      "successScreen.ctaText",
      type.string
    );
    return configured;
  }

  get ctaUrl(): string | null {
    const configured = this.$store.direct.getters.config(
      "successScreen.ctaUrl",
      type.nString
    );
    return configured;
  }

  get documentTypes(): Document["idType"][] {
    const allDocuments = this.$store.direct.state.documents.documentsList;
    const documentTypes = Array.from(
      new Set(allDocuments.map((d) => d.idType))
    );
    return documentTypes;
  }

  nextStep() {
    if (this.ctaText && this.ctaUrl) {
      window.location.href = this.ctaUrl;
    }
  }

  async mounted() {
    await this.dispatchEvent({ eventName: "SCREEN:SUCCESS" });
  }
}
