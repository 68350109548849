











import { Component, Vue, Prop } from "vue-property-decorator";
import {
  Applicant,
  transformGregorianDateTo,
  Nullable,
} from "@frankieone/shared";
import moment from "moment";
import { getFormattedDate } from "@/utils/validators/date";

type IField = {
  field: string;
  label: string;
  value: string | null;
};
@Component({})
export default class ApplicantReviewInput extends Vue {
  @Prop({
    required: true,
  })
  applicant: Applicant;

  @Prop({
    default: false,
  })
  dualName: boolean;

  get fields(): IField[] {
    const fields = [
      ...this.getNameFields(),
      {
        field: "dob",
        label: this.getPhrase("applicant.date_of_birth"),
        value: this.dateOfBirth,
      },
      {
        field: "dob",
        label: this.getPhrase("applicant.date_of_birth_buddhist"),
        value: this.buddhistDateOfBirth,
      },
      ...this.getAdditionalNameFields(),
      {
        field: "current-address",
        label: this.getPhrase("applicant.current_address"),
        value: this.formattedCurrentAddress,
      },
      {
        field: "previous-address",
        label: this.getPhrase("applicant.previous_address"),
        value: this.formattedPreviousAddress,
      },
    ];
    return fields.filter((e) => !!e.value);
  }

  get hasDualName(): boolean {
    return Boolean(
      this.applicant.extraData.home_country_givenname ||
        this.applicant.extraData.home_country_familyname
    );
  }

  getNameFields(): IField[] {
    if (!this.hasDualName) {
      return [
        {
          field: "name",
          label: this.getPhrase("applicant.name"),
          value: this.fullName,
        },
      ];
    }
    return [
      {
        field: "english-name",
        label: this.getPhrase("applicant.english_name"),
        value: this.fullName,
      },
      {
        field: "native-name",
        label: this.getPhrase("applicant.native_name"),
        value: this.nativeName,
      },
    ];
  }

  getAdditionalNameFields(): IField[] {
    if (!this.gender && !this.maritalStatus) return [];
    return [
      {
        field: "gender",
        label: this.getPhrase("applicant.gender"),
        value: this.gender,
      },
      {
        field: "marital-status",
        label: this.getPhrase("applicant.marital_status"),
        value: this.maritalStatus,
      },
    ];
  }

  get applicantClone(): Applicant {
    return Object.assign(Object.create(this.applicant), this.applicant);
  }

  get hasBuddhistDOB(): boolean {
    return Boolean(this.applicant.extraData["dob.Buddhist"]);
  }

  get nameFieldSlug(): string {
    return "name";
  }

  get fullName(): string {
    const fields = ["givenName", "middleName", "familyName"];
    return (
      this.applicant.displayName ||
      fields
        .map((f) => this.applicant.name[f])
        .filter(Boolean)
        .join(" ")
    );
  }

  get nativeName(): string {
    return [
      this.applicant.extraData.home_country_givenname,
      this.applicant.extraData.home_country_middlename,
      this.applicant.extraData.home_country_familyname,
    ]
      .filter(Boolean)
      .join(" ");
  }

  get dateOfBirth(): string | null {
    const dob = this.applicantClone.dateOfBirth;
    const buddhistDob = this.applicantClone.extraData["dob.Buddhist"];
    if (!dob || buddhistDob) return null;
    return getFormattedDate(dob, "gregorian", "DD/MM/yyyy");
  }

  get gender(): string | null {
    const { gender } = this.applicantClone;
    return gender || "";
  }

  get maritalStatus(): Nullable<string> {
    const {
      extraData: { marital_status = "" },
    } = this.applicantClone;
    return (marital_status as string) || null;
  }

  get buddhistDateOfBirth(): string | null {
    const dob = this.applicant.extraData["dob.Buddhist"];
    if (!dob) return null;
    return getFormattedDate(dob, "buddhist", "DD/MM/yyyy");
  }

  get formattedCurrentAddress(): string | null {
    const currentAddress = this.applicantClone.getAddress("RESIDENTIAL1");
    const formatted = currentAddress?.longForm;
    return formatted ?? null;
  }

  get formattedPreviousAddress(): string | null {
    const previousAddress = this.applicantClone.getAddress("RESIDENTIAL2");
    const formatted = previousAddress?.longForm;
    return formatted ?? null;
  }

  slugify(field: string): string {
    return field.toLowerCase().replace(/ /gi, "-");
  }

  getFieldClass(field: string): string {
    const slug = field?.toLowerCase().replace(/ /gi, "-");
    return `ff-applicant-${slug}`;
  }

  clicked(fieldSlug: string) {
    this.$emit("click", fieldSlug);
  }
}
