import moment from "moment";
import { Applicant, Address, Nullable } from "@frankieone/shared";

import { defineModule } from "direct-vuex";
import { Getter, Action } from "@/store/types";
import { moduleActionContext, moduleGetterContext } from "@/store";
import { type } from "@/utils/configurationParser";
import mapSupportingDocsToConfig from "@/utils/mapSupportingDocsToConfig";
import { mkAddress } from "../../../utils/address";
import { hasExtraName } from "../../../utils/personalDetails";

const mkSessionActionContext = (original: any) => moduleActionContext(original, mod);
const mkSessionGetterContext = (original: any) => moduleGetterContext(original, mod);
export interface PersonalStoreModule {
  state: {
    applicant: Applicant;
    middleNameOption: string;
    extraAddressOption: Nullable<boolean>;
    personalDetailsChecked: {
      name: boolean;
      dob: boolean;
      extraName: boolean;
      firstAddress: boolean;
      extraAddress: boolean;
    };
  };
  getters: {
    applicantDetails: Getter<Applicant>;
    middleNameOption: Getter<string>;
    personalDetailsChecked: Getter<PersonalStoreModule["state"]["personalDetailsChecked"]>;
    areAllPersonalDetailsChecked: Getter<boolean>;
    dobType: Getter<TDOBTypeSupport>;
  };
  actions: {
    updateApplicant: Action<Applicant>;
    updateMiddleNameOption: Action<string>;
    setPersonalDetailChecked: Action<{ what: string; checked: boolean }>;
    resetPersonal: Action<void>;
    setApplicantReference: Action<string | { entityId: string }>;
    initialiseAddresses: Action<void>;
    initialiseDOBType: Action<void>;
    addEmptyAddress: Action<Address["addressType"], number>;
  };
}

/**
 * STATE
 */
const initialState: PersonalStoreModule["state"] = {
  applicant: new Applicant({ entityId: null, addresses: [] }),
  middleNameOption: "",
  personalDetailsChecked: {
    name: false,
    dob: false,
    extraName: false,
    firstAddress: false,
    extraAddress: false,
  },
  extraAddressOption: null,
};

const STATE: PersonalStoreModule["state"] = initialState;

/**
 * GETTERS
 */
const GETTERS: PersonalStoreModule["getters"] = {
  applicantDetails: (state): Applicant => state.applicant,
  middleNameOption: (state): string => state.middleNameOption,
  personalDetailsChecked: (state) => state.personalDetailsChecked,
  dobType: (...context) => {
    const { rootGetters } = mkSessionGetterContext(context);
    const dobConfig = rootGetters.config("dateOfBirth") as TConfigDOB;
    if (!dobConfig) return "gregorian";
    return dobConfig.type;
  },
  areAllPersonalDetailsChecked: (...context) => {
    const { getters, rootGetters } = mkSessionGetterContext(context);
    const { selectedDocuments } = rootGetters;
    const areAllChecked = (dictionary) => Object.values(dictionary).every(Boolean);
    type TCheckShouldHave = Partial<PersonalStoreModule["state"]["personalDetailsChecked"]>;
    const { extraAddress, firstAddress, extraName, ...checkDictionary } = getters.personalDetailsChecked;
    const checksShouldHave: TCheckShouldHave = { ...checkDictionary };
    if (rootGetters.config("requestAddress", type.bool)) {
      checksShouldHave.extraAddress = extraAddress;
      checksShouldHave.firstAddress = firstAddress;
    }

    if (hasExtraName(selectedDocuments)) {
      checksShouldHave.extraName = extraName;
    }
    return areAllChecked(checksShouldHave);
  },
};

/**
 * ACTIONS
 */
const ACTIONS: PersonalStoreModule["actions"] = {
  addEmptyAddress: (context, type) => {
    const { state } = mkSessionActionContext(context);
    const address = mkAddress(type);
    const newIndex = state.applicant.addresses.length;
    state.applicant.addresses.push(address);
    return newIndex;
  },
  updateApplicant: (context, applicant) => {
    const { state } = mkSessionActionContext(context);
    state.applicant = applicant;
    const { addresses, entityId } = applicant;
    state.applicant.addresses.splice(0, addresses.length, ...applicant.addresses);
  },
  updateMiddleNameOption: (context, option: string) => {
    const { state } = mkSessionActionContext(context);
    state.middleNameOption = option;
  },
  setPersonalDetailChecked: ({ state }, { what, checked }) => {
    state.personalDetailsChecked[what] = checked;
  },
  resetPersonal(context) {
    const { state } = mkSessionActionContext(context);
    const newState: PersonalStoreModule["state"] = {
      applicant: new Applicant({ entityId: null, addresses: [] }),
      middleNameOption: "",
      personalDetailsChecked: {
        name: false,
        dob: false,
        extraName: false,
        firstAddress: false,
        extraAddress: false,
      },
      extraAddressOption: null,
    };
    Object.assign(state, newState);
    // dispatch.initialiseAddresses();
  },
  initialiseDOBType(context) {
    const { state, rootGetters } = mkSessionActionContext(context);
    const isBuddhistDOB = rootGetters.dobType === "buddhist";
    if (isBuddhistDOB && state.applicant.extraData) state.applicant.extraData.buddhist_solar_dob = true;
  },
  initialiseAddresses(context) {
    const { state, rootGetters, dispatch } = mkSessionActionContext(context);
    const isAddressRequired = rootGetters.config("requestAddress", type.bool);
    const { isPreloaded } = rootGetters;

    const addressCount = state.applicant?.addresses?.length ?? 0;
    const hasAddress = addressCount > 0;
    // covering each case individually
    // is preloaded + is address required
    const finalAddresses = (() => {
      if (!isPreloaded && !isAddressRequired) return [];
      if (!isPreloaded && isAddressRequired) return [mkAddress("RESIDENTIAL1")];
      if (isPreloaded && !isAddressRequired) return state.applicant.addresses;
      if (isPreloaded && isAddressRequired && hasAddress) return state.applicant.addresses;
      if (isPreloaded && isAddressRequired && !hasAddress) return [mkAddress("RESIDENTIAL1")];
      return [];
    })();

    // if address is not required, set checked state to true, so they are not reviewd later
    const finalCheckedState = !isAddressRequired;

    state.applicant.addresses = finalAddresses;
    dispatch.setPersonalDetailChecked({
      what: "firstAddress",
      checked: finalCheckedState,
    });
    dispatch.setPersonalDetailChecked({
      what: "extraAddress",
      checked: finalCheckedState,
    });
  },
  async setApplicantReference(context, applicantReference: string | { entityId: string }) {
    const { state, rootGetters, dispatch, rootDispatch } = mkSessionActionContext(context);

    const hasReference = (r): r is string => typeof r === "string";
    const searchOption = hasReference(applicantReference) ? "reference" : "entityId";
    const searchValue = hasReference(applicantReference) ? applicantReference : applicantReference.entityId;
    const searchNeedle = { [searchOption]: searchValue };

    if (hasReference(applicantReference)) {
      state.applicant.customerReference = applicantReference;
    }

    const { frankie } = rootGetters;
    if (!frankie) return;

    try {
      const { applicant, documents, supportingDocuments } = await frankie.search(searchNeedle);

      await dispatch.updateApplicant(applicant);
      const isDOBValid = (dob?: string | null) => dob && dob !== "0001-01-01" && moment(dob).isValid();
      if (!isDOBValid(applicant?.dateOfBirth)) {
        applicant.dateOfBirth = null;
      }
      if (documents) {
        const updateDocuments = (doc, i) => rootDispatch.updateDocument({ document: doc, index: i });
        await Promise.all(documents?.map(updateDocuments));
      }
      if (supportingDocuments) {
        const config = (rootGetters.config("documentUploads.uploads") as DocumentUpload[]) || [];
        // This is to keep track of the order of supporting documents
        // in order to fix if they come back from the api in the wrong order
        const updatedOrder = mapSupportingDocsToConfig(config, supportingDocuments);
        await Promise.all(updatedOrder.map((d, i) => rootDispatch.addSupportingDocument({ document: d, index: i })));
      }
      await rootDispatch.setPreloaded();
    } catch (e) {
      const { response, isAxiosError } = (e as any) || {};
      const isNotFoundErorr = isAxiosError && response?.status === 404;
      if (!isNotFoundErorr || !hasReference(applicantReference)) throw e;
    }
  },
};

const mod = defineModule({
  state: STATE,
  getters: GETTERS,
  actions: ACTIONS,
} as PersonalStoreModule);
export default mod;
