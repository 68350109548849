import { defineModule } from "direct-vuex";
import Vue from "vue";
import { debounce } from "lodash";
import { Action, Getter } from "@/store/types";
import { moduleActionContext, moduleGetterContext } from "@/store";
import loggerFactory, { ILogger } from "@/utils/Logger";
import getRoute from "./routes";

const MOBILE = { key: "ff-mobile", width: 768 };
const TABLET = { key: "ff-tablet", width: 1024 };
const DESKTOP = { key: "ff-desktop", width: Infinity };

const updateSize = debounce(
  (theWidth: number, theHeight: number, state) => {
    const allDeviceTypes = [MOBILE, TABLET, DESKTOP];
    // eslint-disable-next-line no-restricted-syntax
    for (const device of allDeviceTypes) {
      const deviceWidth = device.width;
      const deviceKey = device.key;
      if (theWidth <= deviceWidth) {
        state.screenWidthClass = deviceKey;
        break;
      }
    }
  },
  200,
  { leading: true }
);

const mkClearState = () => ({
  routeIndex: {
    view: 0,
    step: 0,
  },
  routePath: [],
  idle: false,
  screenWidthClass: MOBILE.key,
  isNavigationHidden: false,
  environment: "development",
  error: null,
  // isEdit: false,
  // backwardsBlock: false
});
const setDefaultVisited = (step: Step | RouteObject) => {
  if (typeof step.visited !== "boolean") step.visited = false;
  return step;
};
const setDefaultData = (step: Step | RouteObject) => {
  if (typeof step.data === "undefined") step.data = {};
  return step;
};
const setStepDefaultData = (step: Step): Step => {
  if (step.inner) {
    step.inner = step.inner.map(setDefaultData) as Step[];
  }
  return setDefaultData(step) as Step;
};
const setStepDefaultVisited = (step: Step): Step => {
  if (step.inner) {
    step.inner = step.inner?.map(setDefaultVisited) as Step[];
  }
  return setDefaultVisited(step) as Step;
};
const mkSessionActionContext = (original: any) =>
  moduleActionContext(original, mod);
const mkSessionGetterContext = (original: any) =>
  moduleGetterContext(original, mod);
const countInnerStepsInStep = (step: Step) => {
  const innerCount = step.inner ? step.inner.length : 1;
  return innerCount;
};
const accumulateInnerStepCount = (acc: number, step: Step) => {
  const innerCount = countInnerStepsInStep(step);
  return acc + innerCount;
};
const countInnerStepsInView = (view: RouteObject) => {
  const { steps } = view;
  if (!steps) return 1;
  return steps.reduce(accumulateInnerStepCount, 0);
};
const accumulateViewStepCount = (acc: number, view: RouteObject): number => {
  const stepCount = countInnerStepsInView(view);
  return acc + stepCount;
};
export const flattenInnerSteps = (
  arr: InnerStepObject[],
  step: Step,
  stepIndex: number
): InnerStepObject[] => {
  if (step.inner) {
    const innerSteps: InnerStepObject[] = step.inner.map(
      (innerStep: Step, index: number) => ({
        ...innerStep,
        parent: step,
        innerStepIndex: index,
        parentStepIndex: stepIndex,
        showNavigationBar:
          innerStep.showNavigationBar ?? step.showNavigationBar,
        data: innerStep.data ?? step.data ?? {},
      })
    );
    arr.push(...innerSteps);
  } else {
    arr.push({
      ...step,
      parent: null,
      innerStepIndex: 0,
      parentStepIndex: stepIndex,
      showNavigationBar: step.showNavigationBar,
      data: step.data ?? {},
    });
  }
  return arr;
};
const getStepsFromView = (view: RouteObject): Step[] => {
  if (!view.steps?.length) {
    return [
      {
        slug: view.slug,
        label: view.label,
        visited: Boolean(view.visited),
        data: view.data ?? {},
      },
    ];
  }
  return view.steps;
};
export const calculateGlobalIndex = (
  path: (RouteObject | string)[],
  index: number
) => {
  let nonMarkerCounter = 0;
  let foundSearchedIndex = false;
  const foundNonMarker = () => (nonMarkerCounter += 1);
  const hasReachedSearchedIndex = () => nonMarkerCounter - 1 === index;
  const isMarker = (entry: RouteObject | string) => typeof entry === "string";
  const globalIndex: number = path.reduce<number>(
    (accGlobalIndex, entry, currentIndex) => {
      if (foundSearchedIndex) return accGlobalIndex;
      if (!isMarker(entry)) foundNonMarker();
      if (hasReachedSearchedIndex()) {
        foundSearchedIndex = true;
        return currentIndex;
      }
      return accGlobalIndex;
    },
    -1
  );

  return globalIndex;
};

declare type State = SystemStoreModule["state"];

export interface SystemStoreModule {
  state: {
    environment: string;
    screenWidthClass: string;
    routeIndex: {
      view: number;
      step: number;
    };
    routePath: (RouteObject | string)[]; // string is a loop marker that will be filtered out
    idle: boolean;
    isNavigationHidden: boolean;
    error: null | Error;
    // isEdit: boolean;
    // idle: boolean;
    // attemptCount: number;
    // backwardsBlock: boolean;
  };
  getters: {
    viewPath: Getter<RouteObject[]>;
    currentView: Getter<RouteObject>;
    isFreeNavigationAvailable: Getter<boolean>;
    totalStepCount: Getter<number>;
    isDesktop: Getter<boolean>;
    isTablet: Getter<boolean>;
    isMobile: Getter<boolean>;
    globalStepIndex: Getter<number>;
    currentStep: Getter<CurrentStepObject>;
    flattenSteps: Getter<Step[]>;
    stepNavigationItems: Getter<NavigationItem[]>;
    isFirstStepInView: Getter<boolean>;
    globalRouteIndex: Getter<number>;
    isIdle: Getter<boolean>;
    routeIndex: Getter<State["routeIndex"]>;
    environmentMode: Getter<State["environment"]>;
    isDemo: Getter<boolean>;
    isDevelopment: Getter<boolean>;
    isProduction: Getter<boolean>;
    logger: Getter<ILogger>;
    error: Getter<State["error"]>;
    env: Getter<{
      environmentMode: () => string;
      isDemo: () => boolean;
      isDevelopment: () => boolean;
      isProduction: () => boolean;
    }>;
    // formCountForViewIndex: Getter<(i: number) => number>;

    // stepCount: Getter<number>;
    // componentForCurrentRoute: Getter<VueComponent | AsyncComponent>;
    // totalStepCount: Getter<number>;
    // currentStepCount: Getter<number>;
    // idle: Getter<boolean>;
    // isEdit: Getter<boolean>;
    // stepCountForViewIndex: Getter<(i: number) => number>;
    // totalAttemptCount: Getter<number>;
  };
  actions: {
    nextView: Action;
    previousView: Action;
    nextStep: Action;
    previousStep: Action;
    gotoStep: Action<State["routeIndex"]>;
    addView: Action<string | { routeName: string; routeData: any }>;
    updateSteps: Action<{
      steps: StepsTransformer | Step[];
      viewIndex?: number;
      viewSlug?: string;
    }>;
    updateInnerSteps: Action<{
      innerSteps: StepsTransformer | Step[];
      viewIndex?: number;
      viewSlug?: string;
      stepIndex?: number;
      stepSlug?: string;
    }>;
    setIdle: Action<boolean>;
    setError: Action<any>;
    resetSystem: Action;
    onSizeChanged: Action<{ width: number; height: number }>;
    setVisitedCurrentStep: Action<void, string>;
    // nextStep: Action;
    // previousStep: Action;
    // previousStep: Action;
    // goToNextView: Action;
    // goToPreviousView: Action;
    // setEdit: Action<boolean>;
    // setIdle: Action<boolean>;
    // setStepCountForCurrentView: Action<number>;
    // goToView: Action<State['routeIndex']>;
    // incrementAttemptCount: Action;
    // addView: Action<string | { viewName: string; stepCount?: number }>;
    // addStepCount: Action<number>;
    // resetSystem: Action;
    // blockBackButton: Action;
    // allowBackButton: Action;
  };
}

/**
 * HELPERS
 */

/**
 * STATE
 */

const STATE: SystemStoreModule["state"] = mkClearState();

/**
 * GETTERS
 */

const GETTERS: SystemStoreModule["getters"] = {
  error(state) {
    return state.error;
  },
  env(state, getters): ReturnType<SystemStoreModule["getters"]["env"]> {
    return {
      environmentMode: () => getters.environmentMode,
      isDemo: () => getters.isDemo,
      isDevelopment: () => getters.isDevelopment,
      isProduction: () => getters.isProduction,
    };
  },
  logger(state, getters): ILogger {
    return loggerFactory({ env: getters.env });
  },
  environmentMode(state): string {
    return state.environment;
  },
  isDemo(state): boolean {
    return state.environment === "demo";
  },
  isDevelopment(state): boolean {
    return state.environment === "development";
  },
  isProduction(state): boolean {
    return state.environment === "production";
  },
  isDesktop(state): boolean {
    return state.screenWidthClass === DESKTOP.key;
  },
  isTablet(state): boolean {
    return state.screenWidthClass === TABLET.key;
  },
  isMobile(state): boolean {
    return state.screenWidthClass === MOBILE.key;
  },
  // routeSlug: (state, getters): string => {
  //   const currentRouteIndex: number = state.routeIndex.view;
  //   return state.viewPath[currentRouteIndex];
  // },
  routeIndex: (...context): State["routeIndex"] => {
    const { state } = mkSessionGetterContext(context);
    return state.routeIndex;
  },
  isFreeNavigationAvailable: (): boolean => false,
  currentView: (...context): RouteObject => {
    const { state, getters } = mkSessionGetterContext(context);
    const { view } = state.routeIndex;
    return getters.viewPath[view];
  },
  viewPath: (...context): RouteObject[] => {
    const { state } = mkSessionGetterContext(context);
    const filterOutString = (route: RouteObject | string) =>
      typeof route !== "string";
    return state.routePath.filter(filterOutString) as RouteObject[];
  },
  totalStepCount: (...context): number => {
    const { getters } = mkSessionGetterContext(context);
    const path = getters.viewPath;

    const stepCount = path.reduce(accumulateViewStepCount, 0);
    return stepCount;
  },
  globalStepIndex: (...context): number => {
    const { state, getters } = mkSessionGetterContext(context);
    const path = getters.viewPath;
    const viewIndex = state.routeIndex.view;
    const stepIndex = state.routeIndex.step;
    const firstPathSlice = path.slice(0, viewIndex);
    const firstPathSliceStepCount = firstPathSlice.reduce(
      accumulateViewStepCount,
      0
    );
    return firstPathSliceStepCount + stepIndex;
  },
  currentStep: (...context): CurrentStepObject => {
    const { state, getters } = mkSessionGetterContext(context);
    const { step: stepIndex, view: viewIndex } = state.routeIndex;
    const { currentView } = getters;

    if (!currentView.steps?.length) {
      return {
        slug: currentView.slug,
        label: currentView.label,
        parentStepIndex: 0,
        innerStepIndex: 0,
        parent: null,
        viewIndex: { step: stepIndex, view: viewIndex },
        showNavigationBar: currentView.showNavigationBar ?? false,
        data: currentView.data,
      };
    }

    const flattenViewSteps = currentView.steps.reduce(flattenInnerSteps, []);
    const {
      innerStepIndex,
      slug,
      label,
      parent,
      parentStepIndex,
      showNavigationBar,
      data,
    } = flattenViewSteps[stepIndex];
    return {
      slug,
      label,
      parent,
      innerStepIndex,
      parentStepIndex,
      viewIndex: { step: stepIndex, view: viewIndex },
      showNavigationBar:
        showNavigationBar ?? currentView.showNavigationBar ?? false,
      data,
    };
  },
  flattenSteps: (...context): Step[] => {
    const { getters } = mkSessionGetterContext(context);
    const { viewPath } = getters;

    const flattenViewsIntoSteps = (arr: Step[], view: RouteObject) => {
      const steps = getStepsFromView(view);
      arr.push(...steps);
      return arr;
    };
    return viewPath.reduce(flattenViewsIntoSteps, []);
  },
  stepNavigationItems: (...context) => {
    const { getters } = mkSessionGetterContext(context);
    const { viewPath } = getters;

    const flattenViewsIntoSteps = (
      arr: NavigationItem[],
      view: RouteObject,
      viewIndex: number
    ) => {
      const steps = getStepsFromView(view);
      const labelIndexes = steps.map((step, stepIndex): NavigationItem => {
        const halfSteps = steps.slice(0, stepIndex);
        const flattenedInnerStepIndex = halfSteps.reduce(
          accumulateInnerStepCount,
          0
        );
        return {
          ...step,
          count: countInnerStepsInStep(step),
          index: {
            view: viewIndex,
            step: stepIndex,
            flattenedInnerStepIndex,
          },
        };
      });
      arr.push(...labelIndexes);
      return arr;
    };
    return viewPath.reduce(flattenViewsIntoSteps, []);
  },
  isFirstStepInView: (...context): boolean => {
    const { state } = mkSessionGetterContext(context);
    return state.routeIndex.step === 0;
  },
  globalRouteIndex: (...context): number => {
    const { state } = mkSessionGetterContext(context);
    const path = state.routePath;
    const index = state.routeIndex.view;
    return calculateGlobalIndex(path, index);
  },
  isIdle: (...context): boolean => {
    const { state } = mkSessionGetterContext(context);
    return state.idle;
  },
  // stepCount: (state, getters): number => {
  //   const currViewIndex = state.routeIndex.view;
  //   const count = getters.stepCountForViewIndex(currViewIndex);
  //   return count;
  // },
  // isEdit: (state, getters): boolean => state.isEdit,
  // idle: (state, getters): boolean => state.idle,
  // totalAttemptCount: (state, getters): number => state.attemptCount,
  // totalStepCount: (...context): number => {
  //   const { state, getters } = mkSessionGetterContext(context);
  //   const path = state.viewPath;
  //   const { view: currViewIndex, step: currStepIndex } = state.routeIndex;
  //   // const stepCount = path.reduce((count: number, slug: string, i: number) => {
  //   //   const route = RoutesDictionary[slug];
  //   //   const extra = getters.stepCountForViewIndex(i) || extractStepCount(route) || 1;
  //   //   return count + extra;
  //   // }, 0);
  //   return 0;
  // },
  // currentStepCount: (...context): number => {
  //   const { state, getters } = mkSessionGetterContext(context);
  //   const path = state.viewPath;
  //   const { view: currViewIndex, step: currStepIndex } = state.routeIndex;
  //   let total = currStepIndex + 1;
  //   // for (let currIndex = 0; currIndex < currViewIndex; currIndex += 1) {
  //   //   const slug = state.viewPath[currIndex];
  //   //   const route = RoutesDictionary[slug];
  //   //   total += getters.stepCountForViewIndex(currIndex) || extractStepCount(route) || 1;
  //   // }
  //   return 0;
  // },
  // stepCountForViewIndex: (state, getters) => (index: number): number => state.viewsStepCount[index],
  // componentForCurrentRoute: (state, getters): VueComponent | AsyncComponent => getters.currentView.component,
};

/**
 * ACTIONS
 */

const ACTIONS: SystemStoreModule["actions"] = {
  resetSystem(context) {
    const directContext = mkSessionActionContext(context);
    const clearState = mkClearState();
    const keys = Object.keys(clearState);
    keys.forEach((key: string) => {
      directContext.state[key] = clearState[key];
    });
  },
  nextView(context) {
    const { state, getters } = mkSessionActionContext(context);
    const currentViewIndex: number = state.routeIndex.view;
    const maxViewIndex: number = getters.viewPath.length - 1;
    const newViewIndex = Math.min(currentViewIndex + 1, maxViewIndex);
    state.routeIndex.step = 0;
    state.routeIndex.view = newViewIndex;
  },
  previousView(context) {
    const { state } = mkSessionActionContext(context);
    const currentViewIndex: number = state.routeIndex.view;
    const newViewIndex = Math.max(currentViewIndex - 1, 0);
    state.routeIndex.view = newViewIndex;
  },
  nextStep(context) {
    const { state, getters, dispatch } = mkSessionActionContext(context);

    const { currentView } = getters;
    const totalStepCountForCurrentView = countInnerStepsInView(currentView);

    const currentStepIndex: number = state.routeIndex.step;
    const isLastStepInCurrentView =
      currentStepIndex + 1 >= totalStepCountForCurrentView;

    const newIndex = { ...state.routeIndex };

    if (isLastStepInCurrentView) {
      newIndex.step = 0;
      newIndex.view += 1;
    } else {
      newIndex.step += 1;
    }
    dispatch.setVisitedCurrentStep();
    return new Promise((resolve) => {
      const stepAction = () => {
        Vue.set(state, "routeIndex", newIndex);
        resolve(newIndex);
      };
      Vue.nextTick(stepAction);
    });
  },
  setVisitedCurrentStep(context): string {
    const { getters } = mkSessionActionContext(context);
    const { parentStepIndex, innerStepIndex } = getters.currentStep;

    const viewObject = getters.currentView;
    const stepObject = viewObject.steps?.[parentStepIndex];
    const innerStepObject = stepObject?.inner?.[innerStepIndex];
    // console.log('setVisited', JSON.stringify(viewObject), stepObject, innerStepObject);
    if (!stepObject) {
      viewObject.visited = true;
      return "view";
    }
    if (!innerStepObject) {
      viewObject.visited = true;
      stepObject.visited = true;
      return "step";
    }
    viewObject.visited = true;
    stepObject.visited = true;
    innerStepObject.visited = true;
    return "inner";
  },
  previousStep(context) {
    const { state, getters } = mkSessionActionContext(context);
    if (!getters.isFirstStepInView) {
      state.routeIndex.step -= 1;
    }
  },
  updateSteps(context, { steps, viewIndex, viewSlug }) {
    const { state, getters } = mkSessionActionContext(context);
    let theViewIndex = -1;
    if (viewSlug) {
      theViewIndex = getters.viewPath.findIndex(
        (step) => step.slug === viewSlug
      );
    } else {
      theViewIndex = viewIndex ?? state.routeIndex.view;
    }
    const theView = getters.viewPath[theViewIndex];
    if (!theView) throw new Error(`Non existing view ${theViewIndex}`);
    const currentViewSteps = theView?.steps ?? [];
    const newViewSteps = Array.isArray(steps) ? steps : steps(currentViewSteps);
    const newViewStepsWithVisitedFlag = newViewSteps
      .map(setStepDefaultVisited)
      .map(setStepDefaultData);
    // console.log(`updt steps view ${theViewIndex}`, newViewSteps, currentViewSteps, theView);
    const viewObject = getters.viewPath[theViewIndex];
    viewObject.steps = newViewStepsWithVisitedFlag;
    const pathIndex = calculateGlobalIndex(state.routePath, theViewIndex);
    state.routePath.splice(pathIndex, 1, viewObject);
  },
  updateInnerSteps(
    context,
    { innerSteps, stepIndex, viewIndex, stepSlug, viewSlug }
  ) {
    const { dispatch, getters, state } = mkSessionActionContext(context);
    const { step: currentStepIndex } = state.routeIndex;
    const hasViewDefined =
      typeof viewIndex === "number" || typeof viewSlug === "string";
    const hasStepDefined =
      typeof stepIndex === "number" || typeof stepSlug === "string";

    if (hasViewDefined && !hasStepDefined) {
      throw new Error(
        `Widget routing: View is defined but step isn't when updating inner steps`
      );
    }
    let theViewIndex = -1;
    if (viewSlug) {
      theViewIndex = getters.viewPath.findIndex(
        (step) => step.slug === viewSlug
      );
    } else {
      theViewIndex = viewIndex ?? state.routeIndex.view;
    }
    const theView = getters.viewPath[theViewIndex];
    let theStepIndex = -1;
    if (stepSlug) {
      theStepIndex =
        theView.steps?.findIndex((step) => step.slug === stepSlug) ?? -1;
    } else {
      theStepIndex = stepIndex ?? currentStepIndex;
    }
    dispatch.updateSteps({
      viewIndex: theViewIndex,
      steps: (steps) => {
        const theStep = steps[theStepIndex];
        if (!theStep) throw new Error(`Non existing step ${theStepIndex}`);

        const currentInnerSteps = theStep?.inner ?? [];
        const newInnerSteps = Array.isArray(innerSteps)
          ? innerSteps
          : innerSteps(currentInnerSteps);
        const innerStepsWithData = newInnerSteps.map(setDefaultData);
        const newSteps = [...steps];
        newSteps[theStepIndex].inner = innerStepsWithData;

        return newSteps;
      },
    });
  },
  gotoStep(context, { view, step }) {
    const { state } = mkSessionActionContext(context);
    const path = state.routePath;
    const globalIndex = calculateGlobalIndex(path, view);
    state.routeIndex.view = view;
    state.routeIndex.step = step;

    // if going back to before an existing marker, removes all existing path after that marker
    const findFirstMarker = (r: RouteObject | string, i: number) =>
      i >= globalIndex && typeof r === "string";
    const indexOfFirstMarker = state.routePath.findIndex(findFirstMarker);
    if (indexOfFirstMarker > 0) state.routePath.splice(indexOfFirstMarker);
  },
  addView(context, routeNameData) {
    const { routeName, routeData } = (() => {
      const theRouteName =
        typeof routeNameData === "string"
          ? routeNameData
          : routeNameData.routeName;
      const theRouteData =
        typeof routeNameData === "string" ? {} : routeNameData.routeData ?? {};
      return {
        routeName: theRouteName,
        routeData: theRouteData,
      };
    })();
    const { state } = mkSessionActionContext(context);
    const route = setDefaultVisited(getRoute(routeName)) as RouteObject;

    route.data = routeData;
    if (route.steps) {
      route.steps = route.steps?.map(setStepDefaultVisited);
    }
    state.routePath.push(route);
  },
  setIdle(context, value) {
    const { state } = mkSessionActionContext(context);
    state.idle = value;
  },
  setError(context, error) {
    const { state } = mkSessionActionContext(context);
    state.error = error;
  },
  onSizeChanged(context, { width, height }) {
    const { state } = mkSessionActionContext(context);
    updateSize(width, height, state);
  },
};
const mod = defineModule({
  state: STATE,
  getters: GETTERS,
  actions: ACTIONS,
} as SystemStoreModule);
export default mod;
