import { DynamicUIComponent } from "@frankieone/shared";
import { mkGenericBlueprint } from "./generic-blueprint";
import { Context } from "./blueprint";

export function mkTHABlueprint(context: Context) {
  const genericBlueprint = mkGenericBlueprint(context);

  const { getPhrase, getDocument, setDocument } = context;

  const document = getDocument();
  const {
    extraData: { laser_code = "" },
  } = document;
  const setValue = (value: string) => {
    document.extraData["laser_code"] = value;
    setDocument(document);
  };

  const mkGenericInput = () => {
    return new DynamicUIComponent({
      tag: "generic-input",
      classes: ["ff-national-id-name"],
      attrs: {
        label: getPhrase("document.type_national_id.laser_code", {
          isMandatory: true,
        }),
        value: laser_code,
        placeholder: getPhrase("document.type_national_id.laser_code"),
        tabindex: 0,
        mask: "XXX-XXXXXXX-XX",
      },
      listeners: {
        input: (v: string) => setValue(v),
      },
    });
  };

  genericBlueprint.setChild("laser-code", mkGenericInput());

  return genericBlueprint;
}
