















import { Component, Vue } from "vue-property-decorator";
import TheHeader from "../components/TheHeader.vue";

@Component({
  name: "RouterFrame",
  components: {
    TheHeader,
  },
})
export default class App extends Vue {
  get routePath(): NavigationItem[] {
    return this.$store.direct.getters.stepNavigationItems;
  }

  get disableProgressBar(): boolean {
    return this.$store.direct.getters.isFirstStepInView;
  }

  get currentStep(): CurrentStepObject {
    return this.$store.direct.getters.currentStep;
  }

  get routeProgress(): number {
    const { getters } = this.$store.direct;
    const current = getters.globalStepIndex;
    const total = getters.totalStepCount + 1;
    return (current / total) * 100;
  }

  get navigationItems() {
    return this.$store.direct.getters.stepNavigationItems;
  }

  get viewPath() {
    return this.$store.direct.getters.viewPath;
  }

  get currentRouteName(): string {
    const { slug } = this.$store.direct.getters.currentView;
    return slug;
  }

  get currentRouteComponent(): AsyncVueComponent | VueComponent | null {
    return this.$store.direct.getters.currentView.component;
  }

  get isBackButtonBlocked(): boolean {
    return this.$store.direct.getters.isFirstStepInView;
  }

  get removeHeader(): boolean {
    return this.currentView?.removeHeader ?? false;
  }

  get showNavigationBar(): boolean {
    const state = !this.$store.direct.state.system.isNavigationHidden;
    const configured = this.$store.direct.getters.config(
      "progressBar",
      Boolean
    );
    const step = this.currentStep.showNavigationBar !== false;
    return configured && state && step;
  }

  get currentView(): RouteObject {
    return this.$store.direct.getters.currentView;
  }

  get index(): { step: number; view: number } {
    return this.$store.direct.state.system.routeIndex;
  }

  get idle(): boolean {
    return this.$store.direct.state.system.idle;
  }

  async backClicked(routeIndex?: { view: number; step: number }) {
    if (!routeIndex) {
      await this.$store.direct.dispatch.previousStep();
    } else {
      await this.$store.direct.dispatch.gotoStep(routeIndex);
    }
  }
}
