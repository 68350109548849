





















import { Component, Vue } from "vue-property-decorator";
import OutcomeMessage from "@/components/OutcomeMessage.vue";
import { type } from "../utils/configurationParser";

type Cta = { text: string; url: string };
@Component({
  components: {
    OutcomeMessage,
  },
})
export default class PendingSuccess extends Vue {
  get hasCreditHeaderIssue(): boolean {
    return !!this.currentView.data.issue;
  }

  get currentView() {
    return this.$store.direct.getters.currentView;
  }

  get customHtmlContent(): string | null {
    const hasPendingScreenConfig = this.$store.direct.getters.config(
      "pendingScreen",
      type.nBool
    );
    const htmlContent = this.$store.direct.getters.config(
      "pendingScreen.htmlContent",
      type.nStrBool
    );

    if (hasPendingScreenConfig && typeof htmlContent === "string")
      return htmlContent;
    return null;
  }

  get hasPendingScreen(): boolean {
    return !!this.$store.direct.getters.config("pendingScreen", type.nBool);
  }

  get ctaActions(): Cta[] {
    return this.$store.direct.getters.config("pendingScreen.ctaActions");
  }

  hrefThis(href: string) {
    window.location.href = href;
  }

  async mounted() {
    await this.dispatchEvent({ eventName: "SCREEN:PENDING_VERFICATION" });
  }
}
