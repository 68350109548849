

























































import { Applicant, Nullable } from "@frankieone/shared";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { isEmpty } from "@/utils/validator";
import {
  GENDER_OPTIONS,
  MARITAL_STATUS_OPTIONS,
} from "@/utils/personalDetails";

@Component({
  name: "FullNameInput",
  components: {},
})
export default class FullNameInput extends Vue {
  @Prop({ required: true }) value: Applicant;

  @Prop({ required: false, default: () => ["first", "middle", "last"] })
  fieldNames: TFieldNames[];

  @Prop() displayMiddleNameValue: string | null;

  @Prop() hasMedicareDoc: boolean;

  // needed to disable fields when more than one document is chosen
  // as user cannot change name after that point
  @Prop() disabled: boolean;

  readonly GENDER_OPTIONS = GENDER_OPTIONS;

  readonly MARITAL_STATUS_OPTIONS = MARITAL_STATUS_OPTIONS;

  @Watch("value")
  onChangeValue() {
    this.emitIsAllFilled();
  }

  get middleNameOption(): string {
    return this.$store.direct.getters.middleNameOption;
  }
  set middleNameOption(value: string) {
    this.$store.direct.dispatch.updateMiddleNameOption(value);
  }

  get isAllFilled(): boolean {
    const { givenName, familyName } = this.value.name;
    const {
      gender,
      extraData: { marital_status },
    } = this.value;
    const checkField = (field, val) =>
      this.fieldExists(field) ? !isEmpty(val) : true;
    const additionalFieldCheck =
      checkField("gender", gender) &&
      checkField("marital_status", marital_status);
    const familyNameCheck = this.fieldExists("last", true)
      ? !isEmpty(familyName)
      : true;

    return !isEmpty(givenName) && familyNameCheck && additionalFieldCheck;
  }

  get middleNameOptions() {
    const options = [
      { value: "FULL", label: "Full Middle Name", disabled: true },
      { value: "NO", label: "No Middle Name" },
      { value: "INITIAL", label: "Middle Initial Only", disabled: true },
    ];

    if (!isEmpty(this.value?.name?.middleName)) {
      return options.map((option) => ({
        ...option,
        disabled: false,
      }));
    }
    return options;
  }

  fieldExists(fieldName: FieldName, validate?: boolean): boolean {
    const findFieldByName = (field: TFieldNames): boolean => {
      if (typeof field === "object" && field.field === fieldName) return true;
      if (typeof field === "string" && field === fieldName) return true;
      return false;
    };
    // find field object passed in props
    const fieldObject: TFieldNames | null =
      this.fieldNames.find(findFieldByName) ?? null;
    // if not present, field doesnt exist
    if (!fieldObject) return false;
    // find out if field is configured to be required
    const isRequired =
      typeof fieldObject === "string" ? true : fieldObject.required ?? true;
    // if caller wants to consider validation, return if field is required
    // if caller doesnt wnat to consider validation, return true, since it is present
    return !validate || isRequired;
  }

  get middleNameValue() {
    const { name } = this.value;
    const { middleNameOption } = this;
    const { middleName } = name;

    switch (middleNameOption) {
      case "FULL":
        return middleName;
      case "INITIAL":
        return middleName?.charAt(0);
      case "NO":
        return "";
      default:
        return "";
    }
  }

  emit(applicant) {
    this.$emit("input", applicant);
  }

  async mounted() {
    const middle = this.displayMiddleNameValue;
    const locallyStored = this.middleNameOption;
    if (locallyStored) {
      this.middleNameOption = locallyStored;
    } else if (!middle) {
      this.middleNameOption = "NO";
    } else {
      this.middleNameOption = middle.length === 1 ? "INITIAL" : "FULL";
    }
  }

  setGiven(given: string) {
    this.value.name.givenName = given;
    this.emit(this.value);
    this.emitIsAllFilled();
  }

  setGender(gender: Core["enumGender"]) {
    this.value.gender = gender;
    this.emit(this.value);
    this.emitIsAllFilled();
  }

  setMaritalStatus(maritalStatus: Core["enumMaritalStatus"]) {
    this.$set(this.value.extraData, "marital_status", maritalStatus);
    this.emit(this.value);
    this.emitIsAllFilled();
  }

  setMiddle(middle: string) {
    this.value.name.middleName = middle;
    if (isEmpty(middle)) {
      this.middleNameOption = "NO";
    }
    if (!isEmpty(middle) && this.middleNameOption === "NO") {
      this.middleNameOption = "";
    }
    this.emit(this.value);
    this.emitIsAllFilled();
  }

  setFamily(family: string) {
    this.value.name.familyName = family;
    this.emit(this.value);
    this.emitIsAllFilled();
  }

  setMiddleNameOption(value: string) {
    this.middleNameOption = value;
    this.$emit("update:middleNameValue", this.middleNameValue);
  }

  getMandatoryTitle(label: string): string {
    return `${this.getPhrase(label)}  ${this.getPhrase(
      "common.mandatory_field"
    )}`;
  }

  get maritalStatus(): Nullable<Core["enumMaritalStatus"]> {
    return this.value?.extraData?.marital_status ?? null;
  }

  emitIsAllFilled() {
    this.$emit("isAllFilled", this.isAllFilled);
  }
}
