import { DynamicUIComponent, UIBlueprint } from '@frankieone/shared';
import { Callback, Context, Field, LicenceValidation, Validation } from './blueprint';
import { mkGenericDriversLicenceBlueprint } from './generic-blueprint';
import { feature } from '@/store/features';

/**
 * New Zealand driver's licence blueprint factory
 * This factory function reuses the factory for Australia to simplify the code
 * It will first generate the Australian blueprint and then:
 * 1) remove the "region" and "documentNumber" children
 * 2) add "versionNumber" field
 * 3) update the idNumber "disabled" and "validation" attributes
 *
 */
export function mkNZLDriversLicenceBlueprint(context: Context, getSetterFor: (f: Field) => Callback): UIBlueprint {
  // reuse the australian drivers license blueprint as a base and:
  // 1) remove the "region" and "documentNumber" children
  // 2) add "versionNumber" field
  const blueprint = mkGenericDriversLicenceBlueprint(context, getSetterFor);

  // Add versionNumber field
  blueprint.setChild(
    'versionNumber',
    mkVersionNumberInput(
      {
        ...context,
        validation: versionNumberValidation(),
        disabled: false,
      },
      getSetterFor('versionNumber'),
    ),
  );

  // Update idNumber field
  const idNumber = <DynamicUIComponent>blueprint.getChild('idNumber');
  idNumber.setAttr('disabled', false);
  idNumber.setAttr('mask', idNumberValidation().mask);

  return blueprint;
}

export const mkVersionNumberInput = (context: Context & Validation, callback: Callback) => {
  const { getPhrase, getCompleteDriverLicence } = context;

  const document = getCompleteDriverLicence();
  const { extraData } = document;

  return new DynamicUIComponent({
    tag: 'generic-input',
    classes: ['ff-licence-input'],
    attrs: {
      'data-qa': 'version-number-input',
      'data-recording-disable': true,
      value: extraData.licence_version || '',
      label: getPhrase('document.type_drivers_licence.version_number', {
        isMandatory: true,
      }),
      disabled: context.disabled ?? false,
      mask: context.validation?.mask ?? null,
      placeholder: getPhrase('document.type_drivers_licence.version_number'),
      id: 'version-number',
      removeSpaces: true,
    },
    listeners: {
      input: callback,
    },
  });
};

export const idNumberValidation = (): LicenceValidation => {
  const enhancedValidation = feature('enhancedValidation');
  return {
    mask: 'X'.repeat(8),
    minLength: 8,
    maxLength: 8,
    regex: enhancedValidation ? /^[a-z\d]{8}$/i : undefined,
  };
};
export const versionNumberValidation = (): LicenceValidation => {
  const enhancedValidation = feature('enhancedValidation');
  return {
    mask: '###',
    minLength: 3,
    maxLength: 3,
    regex: enhancedValidation ? /^\d{3}$/i : undefined,
  };
};
