





import { Component, Vue } from "vue-property-decorator";

@Component({
  name: "NewZealandPassport",
})
export default class NewZealandPassport extends Vue {}
