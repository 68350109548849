







import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { Document, IconRadioInput, UIBlueprint } from "@frankieone/shared";
import { isEmpty, checkStringLength } from "@/utils/validator";
import { validateMedicare } from "@/utils/validators/validateMedicare";
import { checkDateExpiry } from "@/utils/validators/date";
import { Context, mkMedicareBlueprint } from "@/documents/medicare/blueprint";
import { getMedicareImage } from "@/utils/documentFactory";

@Component({
  components: {
    IconRadioInput,
  },
})
export default class MedicareInput extends Vue {
  @Prop({ required: true }) value: Document;

  expiryBuffer = "";

  created() {
    this.expiryBuffer = this.value.idExpiry ?? "";
  }

  get blueprintContext(): Context {
    return {
      getDocument: () => this.value,
      setDocument: (document: Document | null) => this.$emit("input", document),
      getPhrase: this.getPhrase,
      getExpiryBuffer: () => this.expiryBuffer,
      setExpiryBuffer: (value) => (this.expiryBuffer = value),
    };
  }

  get blueprint(): UIBlueprint {
    return mkMedicareBlueprint(this.blueprintContext);
  }

  @Watch("value", { immediate: true, deep: true })
  onIsAllFilledChanged() {
    this.$nextTick(() => this.emitIsAllFilled());
  }

  emitInput(doc: Document) {
    this.$emit("input", doc);
  }

  get medicareImage(): VueComponent {
    return getMedicareImage(this.value.idSubType);
  }

  emitIsAllFilled() {
    this.$emit("isAllFilled", this.isAllFieldsFilled);
  }

  get isAllFieldsFilled(): boolean {
    const { idSubType, idNumber } = this.value;
    if (!idSubType || !idNumber) return false;
    const idNumberTrim = idNumber.replace(/\s/g, "");

    return (
      this.checkPosition &&
      !isEmpty(this.value.idSubType) &&
      this.validateExpiry &&
      checkStringLength(idNumberTrim, 10)
    );
  }

  get checkPosition(): boolean {
    return !isEmpty(this.value.extraData?.reference);
  }

  get isIdNumberValid(): boolean {
    const { idNumber } = this.value;

    const isValid = validateMedicare(idNumber);

    return isValid;
  }

  get validateExpiry(): boolean {
    const { idExpiry } = this.value;

    const isValid = checkDateExpiry("complete", idExpiry, { iso: true });

    return isValid;
  }

  async mounted() {
    await this.$root.dispatchEvent({ eventName: "SCREEN:MEDICARE_INPUT" });
  }
}
