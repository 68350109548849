import store from "@/store";
import { ConfigurationWrapper } from "@frankieone/shared";
import addScript from "@/utils/addScript";

export const initialiseEnableDeviceCharacteristics = async (
  configuration: ConfigurationWrapper<any>,
  token: string
): Promise<void> => {
  if (configuration.get("enableDeviceCharacteristics").v()) {
    const oneSdkUrl =
      "https://assets.frankiefinancial.io/one-sdk/v1.3-beta/oneSdk.umd.js";
    const deviceCheckDetails = store.state.session.deviceCheckDetails;
    // the activity type in KYC is "SIGNUP"
    // @TODO use <const>ActivityType from OneSDK
    deviceCheckDetails.activityType = "SIGNUP";
    await addScript(oneSdkUrl);
    const oneSdk = await window.OneSdk({
      session: { token },
    });
    // the activity type in KYT is "REGISTRATION"
    const device = oneSdk.component("device", {
      activityType: "REGISTRATION",
    });
    device.on(
      "vendor_sdk_loaded",
      () => (deviceCheckDetails.checkType = "DEVICE")
    );
    device.on(
      "session_data_generated",
      ({ session }) =>
        (deviceCheckDetails.checkSessionKey = session.vendorSessionID)
    );
    device.start();
  }
};
