














import { Component, Vue, Prop } from "vue-property-decorator";
import { Document } from "@frankieone/shared";

@Component({
  name: "DocumentTypeInput",
  components: {},
})
export default class DocumentTypeInput extends Vue {
  @Prop({ default: () => [] }) disabledTypes: string[];

  @Prop() value: Document["idType"];

  @Prop() acceptedDocuments: any[];

  getLabel(phraseOrCustom: string) {
    if (phraseOrCustom.includes(".") && this.getPhrase(phraseOrCustom)) {
      return this.getPhrase(phraseOrCustom);
    }
    return phraseOrCustom;
  }

  setSelectedType(documentType: Document["idType"]) {
    this.$emit("input", documentType);
  }

  isDisabledType(type) {
    return this.disabledTypes.includes(type);
  }

  get documents(): any[] {
    return this.acceptedDocuments;
  }

  async mounted() {
    await this.dispatchEvent({ eventName: "SCREEN:DOCUMENT_TYPE_SELECT" });
  }
}
