// based on UX requirments, shared validator just does basic checks
// test id - 2123 45670 3
export function validateMedicare(medicare): boolean {
  let isValid = false;

  if (medicare && medicare.length === 10) {
    const matches = medicare.match(/^(\d{8})(\d)/);

    if (!matches) {
      return false;
    }

    const base = matches[1];
    const checkDigit = matches[2];
    const weights = [1, 3, 7, 9, 1, 3, 7, 9];

    let sum = 0;
    for (let i = 0; i < weights.length; i += 1) {
      sum += parseInt(base[i], 10) * weights[i];
    }

    isValid = sum % 10 === parseInt(checkDigit, 10);
  }

  return isValid;
}
