







import {
  Context,
  hasExpiryDate,
  idNumberValidation,
  mkPassportBlueprint,
} from "@/documents/passport/blueprint";
import { getPassportImage } from "@/utils/documentFactory";
import { checkStringLength } from "@/utils/validator";
import { checkDateExpiry } from "@/utils/validators/date";
import { Document, UIBlueprint } from "@frankieone/shared";
import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import { resolveAcceptedCountriesForDocType } from "@/utils/resolveAcceptedCountries";
import { isValidAustralianPassportNumber } from "@/utils/validators/passportNumber";
import ExpiryDateMixin from "./ExpiryDateMixin.vue";

@Component({
  components: {},
})
export default class PassportInput extends Mixins(ExpiryDateMixin) {
  @Prop({ required: false, default: () => ["AUS"] })
  acceptedCountries: Document["country"][];

  @Prop() documentConfig: TPassportConfig;

  expiryBuffer = "";

  get blueprint(): UIBlueprint {
    return mkPassportBlueprint(this.blueprintContext);
  }

  get blueprintContext(): Context {
    return {
      getDocument: () => this.value,
      setDocument: (document: Document | null) => this.$emit("input", document),
      getExpiryBuffer: () => this.expiryBuffer,
      setExpiryBuffer: (value) => (this.expiryBuffer = value),
      getPhrase: this.getPhrase,
      getShowExpiryDate: () => this.showExpiryDate,
      getAcceptedCountries: () => this.acceptedCountriesFromConfiguration,
    };
  }

  get acceptedCountriesFromConfiguration(): string[] {
    const documentTypes = this.$store.getters.config("documentTypes");

    return resolveAcceptedCountriesForDocType(
      documentTypes,
      "PASSPORT",
      this.acceptedCountries as string[]
    );
  }

  get validateExpiry(): boolean {
    const { idExpiry } = this.value;

    const isValid = checkDateExpiry("complete", idExpiry, { iso: true });

    return !this.showExpiryDate || isValid;
  }

  get validateIdNumber(): boolean {
    const { idNumber, country } = this.value;
    const { minLength, maxLength } = idNumberValidation(country);
    return checkStringLength(idNumber, minLength, maxLength);
  }

  get errorState() {
    return typeof this.isValidOrErrorMessage === "string";
  }

  get isValidOrErrorMessage() {
    if (!this.isAllFieldsFilled) return false;
    const { country, idNumber } = this.value
    if (country !== 'AUS') {
      // I think these country codes should be a typescript const or an enum, not just a string,
      // so that we know all the possible country codes
      return true
    }
    const defaultErrorMessage = this.getPhrase(
      "passport_input_screen.australian_passport_number_invalid_message"
    );
    if (idNumber && isValidAustralianPassportNumber(idNumber)) {
      return true
    }
    return defaultErrorMessage;
  }

  get showExpiryDate(): boolean {
    return hasExpiryDate(this.value.country, this.documentConfig?.idExpiry);
  }

  get isAllFieldsFilled(): boolean {
    return this.validateIdNumber && this.validateExpiry;
  }

  @Watch("isValidOrErrorMessage", { immediate: true })

  onIsAllFilledChanged() {
    this.$nextTick(() => this.$emit("isAllFilled",  this.isValidOrErrorMessage === true));
  }

  get passportImage() {
    return getPassportImage(this.value.country ?? "");
  }

  async mounted() {
    await this.$root.dispatchEvent({ eventName: "SCREEN:PASSPORT_INPUT" });
  }
}
