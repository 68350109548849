












/* eslint-disable no-lone-blocks */

import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { FrankieIcon, SupportingDocument } from "@frankieone/shared";

@Component({
  components: { FrankieIcon },
})
export default class DocumentReviewInput extends Vue {
  @Prop({
    required: true,
  })
  supportingDocument: SupportingDocument;

  fileName = "";

  @Watch("supportingDocument", { immediate: true, deep: true })
  updateFileName(newDoc) {
    this.fileName = newDoc?.file?.scanName;
  }

  get title() {
    return this.supportingDocument.label;
  }

  get type() {
    return this.supportingDocument.type;
  }

  convertLabel(label: string): string {
    return label[0] + label.slice(1).toLowerCase().replaceAll("_", " ");
  }

  clicked() {
    this.$emit("click");
  }
}
