





import { Component, Vue } from "vue-property-decorator";

@Component({
  name: "MedicareGreen",
})
export default class MedicareGreen extends Vue {}
