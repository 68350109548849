
















import { Applicant, UIBlueprint } from "@frankieone/shared";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import {
  Context,
  isDOBComplete,
  mkDOBBlueprint,
  onCreated,
} from "@/personal/dateOfBirth/blueprint";
import {
  getCurrentAgeFromDate,
  getFormattedDate,
  isValidDateOfBirth,
} from "@/utils/validators/date";

@Component({
  name: "DateOfBirthInput",
})
export default class DateOfBirthInput extends Vue {
  @Prop() value: Applicant["dateOfBirth"];

  @Prop() applicant: Applicant;

  @Prop() acceptedAgeRange: [number, number];

  @Prop() dobType: TDOBTypeSupport;

  dobTypeSwitchValue = null as TDOBTypeSupport | null;

  initialApplicantSnapshot = "";

  dateBuffer = "--";

  @Watch("dobTypeSwitched")
  onDobTypeChanged(newDobType) {
    const applicant = this.applicant.clone();
    const resetDateOfBirth = () => {
      applicant.dateOfBirth = "";
      delete applicant.extraData["dob.Buddhist"];
      this.$emit("input", "");
      this.dateBuffer = "--";
    };

    if (newDobType !== this.applicantDobType) resetDateOfBirth();
    if (newDobType === "buddhist") {
      applicant.extraData.buddhist_solar_dob = true;
    }
    if (newDobType === "gregorian") {
      delete applicant.extraData.buddhist_solar_dob;
    }
    this.$emit("update:applicant", applicant);
  }

  get applicantDobType(): TDOBTypeSupport | null {
    // applicant either has a defined dob type, or null
    const applicantHasBuddhistDOB = Boolean(
      this.applicant.extraData["dob.Buddhist"]
    );
    if (applicantHasBuddhistDOB) return "buddhist";

    const applicantHasGregorianDOB = Boolean(this.applicant.dateOfBirth);
    if (applicantHasGregorianDOB) return "gregorian";

    return null;
  }

  get configuredDobType(): TDOBTypeSupport {
    const applicantHasBuddhistDOB = this.applicantDobType === "buddhist";
    return applicantHasBuddhistDOB ? "buddhist" : this.dobType;
  }

  get blueprintContext(): Context {
    return {
      getBuffer: () => this.dateBuffer,
      setBuffer: (v: string) => (this.dateBuffer = v),
      getPhrase: this.getPhrase,
      getData: () => ({ applicant: this.applicant }),
      setData: (d) => {
        this.$emit("update:applicant", d.applicant);
        this.$emit("input", d.applicant.dateOfBirth);
      },
      getAgeRange: () => this.acceptedAgeRange,
      getDOBType: () => this.dobTypeSwitched,
    };
  }

  get dobTypeSwitched(): TDOBTypeSupport {
    return this.dobTypeSwitchValue ?? this.configuredDobType;
  }

  isDobType(type: TDOBTypeSupport): boolean {
    return this.dobTypeSwitchValue === type;
  }

  get blueprint(): UIBlueprint {
    return mkDOBBlueprint(this.blueprintContext);
  }

  get isAllFilled(): boolean {
    return isDOBComplete(this.dateBuffer, this.dobTypeSwitched);
  }

  get errorState(): boolean {
    return typeof this.isValidOrErrorMessage === "string";
  }

  get isValidOrErrorMessage(): string | boolean {
    const defaultErrorMessage = this.getPhrase(
      "date_of_birth_input_screen.error_message_label"
    );

    if (!this.isAllFilled) return false;
    if (!isValidDateOfBirth(this.dateBuffer ?? "", this.dobTypeSwitched))
      return defaultErrorMessage;

    const age = this.calculatedAge;
    const [minAge, maxAge] = this.acceptedAgeRange;
    if (age < 0) return defaultErrorMessage;
    if (age > maxAge) return defaultErrorMessage;
    if (age < minAge) {
      const minAgePhrase = this.getPhrase(
        "date_of_birth_input_screen.minimum_age_error_label"
      );
      const message = minAgePhrase.format(minAge.toString());
      return message;
    }
    return true;
  }

  get calculatedAge(): number {
    return getCurrentAgeFromDate(this.value ?? "");
  }

  get currentDob() {
    const dobInDisplayCalendar =
      this.dobTypeSwitched === "buddhist"
        ? this.applicant.extraData["dob.Buddhist"]
        : this.applicant.dateOfBirth;

    return getFormattedDate(
      dobInDisplayCalendar as string,
      this.dobTypeSwitched
    );
  }

  get calculatedAgeMessage() {
    const formattedCurrentDobMessage = `<span>${this.currentDob}</span>`;
    const formattedCurrentAgeMessage = `<span>${this.calculatedAge.toString()}</span>`;
    const dobPhrase = this.getPhrase(
      "date_of_birth_input_screen.message_label"
    );
    const message = dobPhrase.format(
      formattedCurrentDobMessage,
      formattedCurrentAgeMessage
    );
    // 'Your birthday is the #[span {{currentDob}}].#[br] Making you #[span {{ calculatedAge }} years old]';
    return message.replace(/,/g, ',<br class="responsive">');
  }

  @Watch("isValidOrErrorMessage", { immediate: true })
  onIsAllFilledChanged() {
    this.$emit("isAllFilled", this.isValidOrErrorMessage === true);
  }

  created() {
    this.dobTypeSwitchValue = this.applicantDobType ?? this.dobType;
    onCreated(this.blueprintContext);
  }
}
