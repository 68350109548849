




import { Component, Vue } from "vue-property-decorator";

@Component({
  name: "SingaporeNationalId",
})
export default class SingaporeNationalId extends Vue {}
