
import { Component, Vue, Prop } from "vue-property-decorator";
import { Document } from "@frankieone/shared";

import moment from "moment";

const DEFAULT_IN_FORMAT = "DD/MM/YYYY";
const DEFAULT_OUT_FORMAT = "YYYY-MM-DD";
@Component({})
export default class ExpiryDateMixin extends Vue {
  expiryBuffer = "";

  @Prop({ required: true }) value: Document;

  setIdExpiry(value) {
    const { outFormat } = this;
    const { inFormat } = this;
    const dateObject = moment(value, inFormat);
    let expiry: string;
    if (inFormat.length > value.length || !dateObject.isValid()) {
      this.expiryBuffer = value;
      expiry = "";
    } else {
      expiry = dateObject.format(outFormat);
    }
    const document = this.value.clone();
    document.idExpiry = expiry;
    this.emitInput(document);
    this.$nextTick(() => this.emitIsAllFilled());
  }

  get formattedExpiry(): string {
    const { inFormat } = this;
    const { outFormat } = this;
    let formatted: string;
    const idExpiry = this.value?.idExpiry ?? "";
    if (outFormat.length > idExpiry.length) {
      formatted = this.expiryBuffer;
    } else {
      formatted = moment(idExpiry, outFormat).format(inFormat);
    }
    return formatted;
  }

  get inFormat(): string {
    return DEFAULT_IN_FORMAT;
  }

  get outFormat(): string {
    return DEFAULT_OUT_FORMAT;
  }

  emitIsAllFilled() {}

  emitInput(doc: Document) {
    this.$emit("input", doc);
  }
}
