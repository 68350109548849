import moment from "moment";
import {
  Document,
  Address,
  Applicant,
  SupportingDocument,
} from "@frankieone/shared";
import { COMPONENTS, getSlugForDocumentDetailsStepOfType } from "./routes";
import { hasExtraName } from "../../../utils/personalDetails";

export type State = {
  applicant: Applicant;
  acceptedDocumentTypes?: Document["idType"][];
  documents?: Document[];
  addressRequired?: boolean;
  IDRequired?: boolean;
  isPreloaded?: boolean;
  lazyID?: boolean;
  usesGoogleApi?: boolean;
  isMobile?: boolean;
  requestDocumentUploads?: IWidgetConfiguration["documentUploads"];
  supportingDocuments: (SupportingDocument | null)[];
};

export function getInitialPathForThisState(state: State) {
  const {
    acceptedDocumentTypes = [
      "PASSPORT",
      "DRIVERS_LICENCE",
      "NATIONAL_HEALTH_ID",
    ],
    IDRequired: IDRequested = false,
    addressRequired: addressRequested = false,
    documents = [],
    applicant,
    isPreloaded = false,
    lazyID = false,
    usesGoogleApi = false,
    requestDocumentUploads,
  } = state;

  const {
    INPUT_DOCUMENT_TYPE,
    FORM_DOCUMENT,
    INPUT_NAME,
    INPUT_DOB,
    INPUT_ADDRESS_WRAPPER,
    DOCUMENT_UPLOADS,
  } = COMPONENTS;

  const steps: Step[] = [];

  const hasMedicareDoc = documents.some(
    (d) => d.idType === "NATIONAL_HEALTH_ID"
  );
  const firstAddress = applicant.getAddress("RESIDENTIAL1");
  const isMainAddressEmpty = isAddressIncomplete(firstAddress);
  const hasAnyOfAcceptedDocuments = documents.some((doc) =>
    acceptedDocumentTypes.includes(doc.idType)
  );
  const hasCompleteName =
    Boolean(applicant.name.givenName) && Boolean(applicant.name.familyName);
  const hasValidDOB =
    applicant.dateOfBirth && moment(applicant.dateOfBirth).isValid();

  // logic for this is super complicated,
  // so for now if it's configured to capture uploads and is preloading,
  // always show screen to upload.
  // this will cause ppl to potentially need to reupload some documentation, which is not ideal
  const hasAllRequiredUploads = !(isPreloaded && requestDocumentUploads);

  const requiredNoLazyId = !lazyID && IDRequested;

  // const hasMedicareDocWithRequestAddressAndAddressIsEmpty = hasMedicareDoc && addressRequested && isMainAddressEmpty;

  const addDocumentInput = () => {
    steps.push({
      slug: FORM_DOCUMENT,
      label: "ID Document",
      inner: [
        {
          slug: INPUT_DOCUMENT_TYPE,
          label: "Document Type",
          data: {
            preselectedIdType:
              acceptedDocumentTypes.length === 1
                ? acceptedDocumentTypes[0]
                : false,
          },
        },
      ],
    });
  };
  const addDocumentUploads = () => {
    steps.push({
      slug: DOCUMENT_UPLOADS,
      label: "Document Uploads",
    });
  };

  const addNameInput = () => {
    steps.push({ slug: INPUT_NAME, label: "Name" });
  };
  const addDOBInput = () => {
    steps.push({ slug: INPUT_DOB, label: "Date of Birth" });
  };
  const addAddressInput = (autocomplete = usesGoogleApi) => {
    steps.push({
      slug: INPUT_ADDRESS_WRAPPER,
      label: "Address",
      inner: getAddressSteps(autocomplete),
    });
  };

  // if not preloaded, follow regular flow and then go to summary
  // 1 - if (non lazy document and document is required) document selection
  // 2 - name input
  // 3 - dob input
  // 4 - if (request Address) address input
  if (!isPreloaded) {
    if (requiredNoLazyId) addDocumentInput();
    addNameInput();
    addDOBInput();
    if (addressRequested) addAddressInput();
    if (requestDocumentUploads) addDocumentUploads();
  }
  // if preloaded
  else {
    // if id is required, is not lazy (eager id) and no accepted document exists yet, then add document input
    if (requiredNoLazyId && !hasAnyOfAcceptedDocuments) {
      addDocumentInput();
      // if medicare is selected, then we need to include a name input here.
      // this is taken care within the Initial Data Gathering, on id type selection
    }
    // if has medicare, show name input for middle name confirmation
    // if is missing name, show name input
    if (hasMedicareDoc || !hasCompleteName) {
      addNameInput();
    }
    if (!hasValidDOB) {
      addDOBInput();
    }
    if (addressRequested && isMainAddressEmpty) {
      addAddressInput(usesGoogleApi && isAddressEmpty(firstAddress));
    }
    if (!hasAllRequiredUploads) {
      addDocumentUploads();
    }
  }

  return steps;
}

function getAddressSteps(hasGoogleApi: boolean): Step[] {
  const { INPUT_ADDR_AUTO, INPUT_ADDR_MANUAL } = COMPONENTS;

  const autocomplete = { slug: INPUT_ADDR_AUTO, label: "Address Autocomplete" };
  const manual = { slug: INPUT_ADDR_MANUAL, label: "Address Manual" };

  const theNewSteps = [manual];
  if (hasGoogleApi) theNewSteps.splice(0, 0, autocomplete);
  return theNewSteps;
}
type DocumentInnerStepsOptions = {
  idType: Document["idType"];
  acceptedDocumentTypes: Document["idType"][];
  isMobile: boolean;
  includeInitialTypeSelection?: boolean;
};
export function getDocumentInnerStepsForIdType(
  options: DocumentInnerStepsOptions
): Step[] {
  const {
    idType,
    acceptedDocumentTypes,
    isMobile,
    includeInitialTypeSelection = true,
  } = options;
  const { INPUT_DOCUMENT_TYPE, INPUT_DRIVERS_STATE, INPUT_DOCUMENT_DATA } =
    COMPONENTS;
  const idTypeWasSelected = acceptedDocumentTypes!.includes(idType!);
  const isDriversLicense = idType === "DRIVERS_LICENCE";
  const slugForIdType = getSlugForDocumentDetailsStepOfType(idType);

  const steps: Step[] = includeInitialTypeSelection
    ? [{ slug: INPUT_DOCUMENT_TYPE, label: "Document Type" }]
    : [];

  const addDriversState = () => {
    steps.push({
      slug: INPUT_DRIVERS_STATE,
      label: "Driver's Licence State",
      data: {
        slugForIdType,
        idType,
      },
    });
  };
  const addDocumentDetails = () => {
    steps.push({
      slug: INPUT_DOCUMENT_DATA,
      label: "Document Details",
      data: {
        slugForIdType,
        idType,
      },
    });
  };

  // if drivers license and on mobile, state is on a different page, so add it next
  if (isDriversLicense && isMobile) {
    addDriversState();
  }
  // if any id type was selected, add document details next
  if (idTypeWasSelected) {
    addDocumentDetails();
  }

  return steps;
}
type DocumentCountryChangedState = {
  document: Document;
  isPreloaded: boolean;
  documentConfiguration: DocConfigBody | null;
  existingStepsOfCurrentView: Step[];
};

export function getStepsForSpecifiedDocumentCountry(
  state: DocumentCountryChangedState
) {
  const { INPUT_NAME_EXTRA, INPUT_NAME } = COMPONENTS;
  const { document, documentConfiguration, existingStepsOfCurrentView } = state;

  /**
   *
   * There are three different situations where National ID affects the next steps (screens)
   * 1 - National ID from Thailand requires the capture of both English and Native names
   * 2 - The screen for National ID from Indonesia already captures name, so we need to remove the name screens coming next
   * 3 - The default case, used for every other situation, we only display one name screen.
   *
   * For that we first remove all name screens and then
   * 1) If "needs extra name", add screens for both name and extra name
   * 2) If "document DOESN'T already contain name capture", add name screen
   */
  // helpers

  const findInputForInputType = (inputType: COMPONENT): number | null => {
    const findStepSlug = (step: Step) => step.slug === inputType;
    const index = existingStepsOfCurrentView.findIndex(findStepSlug);
    return index === -1 ? null : index;
  };

  // operations
  const removeAllNameInputs = () => {
    const nameInputIndex = findInputForInputType("INPUT_NAME");
    if (nameInputIndex !== null)
      existingStepsOfCurrentView.splice(nameInputIndex, 1);
    const extraInputIndex = findInputForInputType("INPUT_NAME_EXTRA");
    if (extraInputIndex !== null)
      existingStepsOfCurrentView.splice(extraInputIndex, 1);
  };
  const addNameInput = () => {
    const documentFormIndex = findInputForInputType("FORM_DOCUMENT");

    existingStepsOfCurrentView.splice(documentFormIndex! + 1, 0, {
      slug: INPUT_NAME,
      label: "Name",
    });
  };
  const addExtraNameInput = () => {
    const nameInputIndex = findInputForInputType("INPUT_NAME");
    const extraNameStep = { slug: INPUT_NAME_EXTRA, label: "Extra Name" };
    existingStepsOfCurrentView.splice(
      (nameInputIndex as number) + 1,
      0,
      extraNameStep
    );
  };

  // conditions
  const hasDocWithName = (): boolean => {
    const { includesPersonalData = [] } = documentConfiguration ?? {};
    const docIncludesName = includesPersonalData.includes(INPUT_NAME);
    return docIncludesName;
  };
  const needsExtraName = (): boolean => {
    return hasExtraName([document]);
  };

  // execution
  removeAllNameInputs();

  if (needsExtraName()) {
    addNameInput();
    addExtraNameInput();
  } else if (!hasDocWithName()) {
    addNameInput();
  }

  return existingStepsOfCurrentView;
}

export type StepUpdateAfterIdSelectionState = {
  steps: Step[];
  stepIndex: number;
  selectedIdType: Document["idType"];
};
export function updatePrefillingOrLoopStepsAfterIdTypeSelection(
  options: StepUpdateAfterIdSelectionState
): Step[] {
  const { INPUT_NAME } = COMPONENTS;
  const { steps, stepIndex, selectedIdType } = options;

  const nextStep = steps[stepIndex + 1];
  const isMedicare = selectedIdType === "NATIONAL_HEALTH_ID";
  const isNameInputNext = nextStep?.slug === INPUT_NAME;

  const addNameInput = () => {
    steps.push({ slug: INPUT_NAME, label: "Name" });
  };

  // if prefilled and document type is medicare, add name input next (if not exists)
  if (isMedicare && !isNameInputNext) {
    addNameInput();
  }
  // how can we remove name input if user adds medicare and changes their mind, selecting a different doc type?
  // for now name input will always be displayed
  return steps;
}

function isAddressEmpty(a?: Address | null) {
  if (!a) return true;
  if (!a.country) return true;
  // for aus, an address will be empty if it doesnt have street name
  // and is missing either town or state
  // which will cause it to use autocomplete
  if (a.country === "AUS" && !a.streetName && (!a.town || !a.state))
    return true;
  return false;
}

function isAddressIncomplete(a?: Address | null): boolean {
  if (!a) return true;
  if (a.isIncomplete && !a.hasExplicitLongForm()) return true;
  if (!a.country) return true;
  // custom checks for smart ui. Sometimes when data is missing we wan't user to confirm it
  // explicitly set the rules below
  if (a.country === "AUS" && !a.streetNumber) return true;
  if (a.country === "AUS" && !a.streetName) return true;
  if (a.country === "AUS" && !a.town) return true;
  if (a.country === "AUS" && !a.state) return true;
  if (a.country === "AUS" && !a.postalCode) return true;

  return false;
}
