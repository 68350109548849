export const documents: DocumentInputObject[] = [
  {
    type: "DRIVERS_LICENCE",
    title: "document.type_drivers_licence.label",
    subtitle: "document.type_drivers_licence.subtitle",
    icon: "driving-licence",
  },
  {
    type: "PASSPORT",
    title: "document.type_passport.label",
    subtitle: "document.type_passport.subtitle",
    icon: "passport",
  },
  // {
  //   type: 'PASSPORT',
  //   title: 'Foreign Passport (with AUS Visa)',
  //   id: 'PASSPORT:AUS',
  //   subtitle: '',
  //   icon: 'foreignPassport',
  // },
  {
    type: "NATIONAL_HEALTH_ID",
    title: "document.type_medicare.label",
    subtitle: "document.type_medicare.subtitle",
    icon: "medicare",
  },
  {
    type: "NATIONAL_ID",
    title: "document.type_national_id.label",
    subtitle: "document.type_national_id.subtitle",
    icon: "national-id",
  },
];
