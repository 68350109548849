import { UIBlueprint, DynamicUIComponent } from "@frankieone/shared";
import { Context } from "./manual-blueprint";

export function mkLongFormAddressBlueprint(context: Context): UIBlueprint {
  const { getPhrase, getBuffer, setBuffer, setCompleteAddress } = context;
  const address = getBuffer();
  const setValue = (v: string) => {
    address.longForm = v;
    address.streetName = null;
    address.postalCode = null;
    address.streetType = null;
    address.state = null;
    address.streetNumber = null;
    address.suburb = null;
    setBuffer(address);
    setCompleteAddress(address);
  };
  return new UIBlueprint({
    root: new DynamicUIComponent({
      tag: "div",
      classes: ["ff-address-input-single-line"],
    }),
    children: {
      longForm: new DynamicUIComponent({
        tag: "text-area-input",
        classes: ["ff-input", "long-form"],
        attrs: {
          label: getPhrase("address_manual_input_screen.full_address_label", {
            isMandatory: true,
          }),
          value: address.longForm,
          placeholder: getPhrase(
            "address_manual_input_screen.full_address_placeholder"
          ),
          type: "textarea",
          rows: 4,
        },
        listeners: {
          input: (v: string) => setValue(v),
        },
      }),
    },
  });
}
