












/* eslint-disable no-lone-blocks */

import { documents } from '@/data/documentTypes';
import { hasExpiryDate } from '@/documents/passport/blueprint';
import { getCountryName } from '@/utils/country';
import { findDocumentConfigInList } from '@/utils/documentFactory';
import { medicareOptions } from '@/utils/medicare';
import { Applicant, Document, FrankieIcon, getAustralianStateDetails } from '@frankieone/shared';
import getCountryDetails from '@frankieone/shared/dist/utils/getCountryDetails';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { isValidAustralianPassportNumber } from '@/utils/validators/passportNumber';
import { validateExtraNumber, validateLicenceNumber } from '@/documents/driversLicence/blueprint';
import { getFormattedDate } from '@/utils/validators/date';

type Fields = {
  label: string;
  value: string | null;
  width?: string; // auto
  validation?: true | string; // true
}[];

@Component({
  components: { FrankieIcon },
})
export default class DocumentReviewInput extends Vue {
  @Prop({
    required: true,
  })
  document: Document;

  @Prop({
    required: true,
  })
  applicant: Applicant;

  get documentFields(): Fields {
    let fields: Fields = [];

    // eslint-disable-next-line default-case
    switch (this.document.idType) {
      case 'DRIVERS_LICENCE':
        {
          const countryIs = (c: string) => this.document.country === c;

          fields = [
            {
              label: this.getPhrase('document.number'),
              value: this.document.idNumber,
              validation:
                validateLicenceNumber(this.document) ||
                this.getPhrase('drivers_licence_input_screen.licence_number.error'),
            },
          ];

          // NZL: version number
          const isNZL = countryIs('NZL');
          if (isNZL)
            fields.push({
              label: this.getPhrase('document.type_drivers_licence.version_number'),
              value: this.document.extraData.licence_version?.toString() ?? '-',
            });

          const isAUS = countryIs('AUS');
          const shouldContainCardNumber = this.documentNumberConfig.includes(this.document.region!);
          // AUS: Card Number
          if (isAUS && shouldContainCardNumber) {
            fields.push({
              label: this.getPhrase('document.type_drivers_licence.card_number'),
              value: this.document.extraData.document_number?.toString() ?? '-',
              validation:
                validateExtraNumber(this.document) ||
                this.getPhrase('drivers_licence_input_screen.document_number.error'),
            });
          }
          // AUS: State
          if (isAUS)
            fields.push({
              label: this.getPhrase('document.type_drivers_licence.state'),
              value: this.state,
            });
        }
        break;
      case 'PASSPORT':
        {
          const country = this.document.country;
          const idNumber = this.document.idNumber;
          const isAUS = country === 'AUS';
          const hasValidAUSPassportNumber = Boolean(idNumber && isValidAustralianPassportNumber(idNumber));

          fields = [
            {
              label: this.getPhrase('document.number'),
              value: this.document.idNumber,
              width: 'auto',
              validation:
                !isAUS ||
                hasValidAUSPassportNumber ||
                this.getPhrase('passport_input_screen.australian_passport_number_invalid_message'),
            },
            {
              label: this.getPhrase('document.type_passport.country'),
              value: this.country,
              width: 'auto',
            },
          ];
          if (hasExpiryDate(this.document.country, this.passportConfig?.idExpiry))
            fields.push({
              label: this.getPhrase('document.type_passport.expiry'),
              value: this.document.idExpiry,
              width: 'auto',
            });
        }
        break;
      case 'NATIONAL_HEALTH_ID':
        {
          const mapIdSubtype = (type) => medicareOptions.find((o) => o.value === type)?.label!;
          const mappedColour = mapIdSubtype(this.document.idSubType);

          fields = [
            {
              label: this.getPhrase('document.type_medicare.number'),
              value: this.document.idNumber,
              width: '100%',
            },
            {
              label: this.getPhrase('document.type_medicare.colour'),
              value: mappedColour,
              width: 'auto',
            },
            {
              label: this.getPhrase('document.type_medicare.position'),
              value: this.document.extraData.reference as string,
              width: 'auto',
            },
            {
              label: this.getPhrase('document.type_medicare.expiry'),
              value: getFormattedDate(this.document.idExpiry!, 'gregorian', 'DD/MM/yyyy'),
              width: 'auto',
            },
            {
              label: this.getPhrase('document.type_medicare.name'),
              value: this.nameShowOnMedicareCard,
              width: 'auto',
            },
          ];
        }
        break;
      case 'NATIONAL_ID': {
        if (this.document.country === 'THA') {
          fields = [
            {
              label: this.getPhrase('document.type_national_id.identification_number'),
              value: this.document.idNumber,
              width: '100%',
            },
            {
              label: this.getPhrase('document.type_national_id.laser_code'),
              value: this.document?.extraData?.laser_code as string,
              width: '100%',
            },
          ];
        } else if (this.document.country === 'SGP') {
          fields = [
            {
              label: this.getPhrase('document.type_national_id.identification_number'),
              value: this.document.idNumber,
              width: 'auto',
            },
            {
              label: this.getPhrase('document.type_national_id.nationality'),
              value: getCountryName(this.document?.extraData?.nationality_code as string),
              width: 'auto',
            },
          ];
        } else {
          fields = [
            {
              label: this.getPhrase('document.type_national_id.identification_number'),
              value: this.document.idNumber,
              width: '100%',
            },
          ];
        }
        break;
      }
    }

    return fields;
  }

  get validationState(): boolean {
    // Default validation to true if no validation is present
    const isValid = (v: Fields[number]) => (v.validation ?? true) === true;
    return this.documentFields.every(isValid);
  }

  get passportConfig(): TPassportConfig | null {
    const docConfig = this.$store.direct.getters.config('documentTypes');
    return findDocumentConfigInList(docConfig, 'PASSPORT') as TPassportConfig;
  }

  get nameShowOnMedicareCard(): string {
    if (!this.applicant) return '';
    const { familyName, givenName } = this.applicant.name;
    const middleName = this.document.extraData.display_middle_name;
    return [givenName, middleName, familyName].filter(Boolean).join(' ');
  }
  get documentNumberConfig(): string[] {
    const statesThatRequireDocNumber = this.$feature('statesThatRequireDocNumber') as unknown as string[];
    return statesThatRequireDocNumber;
  }
  get documentTitle(): string | null {
    if (!this.document.idType) return null;
    // const details = getDocumentDetails(this.document.idType);
    const document = documents.find((doc) => doc.type === this.document.idType);
    const getTranslatedTitle = this.getPhrase((document?.title as string) || '');
    return getTranslatedTitle ?? null;
  }

  get state(): string | null {
    const code = this.document.region;
    if (!code) return null;
    const details = getAustralianStateDetails(code);
    return details?.label ?? null;
  }

  get country(): string | null {
    const code: string | null = this.document.country;
    if (!code) return null;
    const details = getCountryDetails(code);
    return details ? details.label : null;
  }

  slugify(field: string): string {
    return field.toLowerCase().replace(/ /gi, '-');
  }

  getFieldClass(field: string | number): string {
    const slug = this.slugify(field as any);
    return `ff-document-${slug}`;
  }

  clicked() {
    this.$emit('click');
  }

  @Watch('validationState', { immediate: true })
  onValidationStateChange(): void {
    this.$emit('update:validationState', this.validationState);
  }
}
