import store from '.';

/**
 * Get the value of a feature flag. This is a convenience method and allows checking feature flags
 * directly, without the need of reading it from a Vue component and "parameter drilling".
 * This is done in a separate file so it's easy to mock it in tests.
 * @param key The feature key to check
 * @returns The value for that feature
 */
export function feature<Type = boolean>(key: string): Type {
  return store.getters.feature(key) as unknown as Type;
}
