













import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  name: "SmartUIImage",
})
export default class SingaporeNationalId extends Vue {
  @Prop({ required: true }) name: string;

  @Prop({ required: false }) fallbackName: string;

  useFallback = false;

  errorState = false;

  get srcUrl(): string | null {
    /**
     * IF IN DEVELOPMENT, this function will try to locally require image files
     * ELSE, this function will link to images deployed in an s3 bucket. See the pipeline script for this repository.
     * Although it should be automated, using s3 images reduced the size of our bundle in more than 70%. TODO: Automate image deployments.
     */
    const name = this.useFallback ? this.fallbackName : this.name;

    if (process.env.NODE_ENV === "development") {
      try {
        // eslint-disable-next-line import/no-dynamic-require, global-require
        return require(`../../images/${name}`);
      } catch (e) {
        this.onLoadingError();
        this.$log.error("development", `Image file ${this.name} not found.`, e);
        if (!this.fallbackName) return null;
        // eslint-disable-next-line import/no-dynamic-require, global-require
        return require(`../../images/${this.fallbackName}`);
      }
    }
    return `https://assets.frankiefinancial.io/onboarding/images_v1/${name}`;
  }

  onLoadingError() {
    if (this.errorState) return;
    if (this.useFallback) this.errorState = true;
    if (this.fallbackName) this.useFallback = true;
    else this.errorState = true;
  }
}
