





import { Component, Vue, Prop } from "vue-property-decorator";

@Component({
  name: "Loading",
})
export default class Loading extends Vue {
  @Prop({ default: 32 }) size: number;

  @Prop({ default: "#ffffff" }) color: string;
}
