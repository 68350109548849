




import { Component, Vue } from "vue-property-decorator";

@Component({
  name: "IndoNationalId",
})
export default class IndoNationalId extends Vue {}
