




import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { AusState, AusVariant } from "@/documents/driversLicence/blueprint";

@Component({
  name: "AUSLicence",
})
export default class AUSLicence extends Vue {
  @Prop() region: AusState;
  @Prop() variant: AusVariant;

  element = "div";

  get fetchAssets() {
    const assets = {
      act: {
        default: () =>
          import(
            /* webpackChunkName: 'act-default' */
            "@/../images/driver-licence/aus/act-default.svg"
          ),
        licence_number: () =>
          import(
            /* webpackChunkName: 'act-licence-number' */
            "@/../images/driver-licence/aus/act-licence-number.svg"
          ),
        document_number: () =>
          import(
            /* webpackChunkName: 'act-card-number' */
            "@/../images/driver-licence/aus/act-card-number.svg"
          ),
        name: () =>
          import(
            /* webpackChunkName: 'act-name' */
            "@/../images/driver-licence/aus/act-name.svg"
          ),
      },
      nsw: {
        default: () =>
          import(
            /* webpackChunkName: 'nsw-default' */
            "@/../images/driver-licence/aus/nsw-default.svg"
          ),
        licence_number: () =>
          import(
            /* webpackChunkName: 'nsw-licence-number' */
            "@/../images/driver-licence/aus/nsw-licence-number.svg"
          ),
        document_number: () =>
          import(
            /* webpackChunkName: 'nsw-card-number' */
            "@/../images/driver-licence/aus/nsw-card-number.svg"
          ),
        name: () =>
          import(
            /* webpackChunkName: 'nsw-name' */
            "@/../images/driver-licence/aus/nsw-name.svg"
          ),
      },
      nt: {
        default: () =>
          import(
            /* webpackChunkName: 'nt-default' */
            "@/../images/driver-licence/aus/nt-default.svg"
          ),
        licence_number: () =>
          import(
            /* webpackChunkName: 'nt-licence-number' */
            "@/../images/driver-licence/aus/nt-licence-number.svg"
          ),
        document_number: () =>
          import(
            /* webpackChunkName: 'nt-card-number' */
            "@/../images/driver-licence/aus/nt-card-number.svg"
          ),
        name: () =>
          import(
            /* webpackChunkName: 'nt-name' */
            "@/../images/driver-licence/aus/nt-name.svg"
          ),
      },
      qld: {
        default: () =>
          import(
            /* webpackChunkName: 'qld-default' */
            "@/../images/driver-licence/aus/qld-default.svg"
          ),
        licence_number: () =>
          import(
            /* webpackChunkName: 'qld-licence-number' */
            "@/../images/driver-licence/aus/qld-licence-number.svg"
          ),
        document_number: () =>
          import(
            /* webpackChunkName: 'qld-card-number' */
            "@/../images/driver-licence/aus/qld-card-number.svg"
          ),
        name: () =>
          import(
            /* webpackChunkName: 'qld-name' */
            "@/../images/driver-licence/aus/qld-name.svg"
          ),
      },
      sa: {
        default: () =>
          import(
            /* webpackChunkName: 'sa-default' */
            "@/../images/driver-licence/aus/sa-default.svg"
          ),
        licence_number: () =>
          import(
            /* webpackChunkName: 'sa-licence-number' */
            "@/../images/driver-licence/aus/sa-licence-number.svg"
          ),
        document_number: () =>
          import(
            /* webpackChunkName: 'sa-card-number' */
            "@/../images/driver-licence/aus/sa-card-number.svg"
          ),
        name: () =>
          import(
            /* webpackChunkName: 'sa-name' */
            "@/../images/driver-licence/aus/sa-name.svg"
          ),
      },
      tas: {
        default: () =>
          import(
            /* webpackChunkName: 'tas-default' */
            "@/../images/driver-licence/aus/tas-default.svg"
          ),
        licence_number: () =>
          import(
            /* webpackChunkName: 'tas-licence-number' */
            "@/../images/driver-licence/aus/tas-licence-number.svg"
          ),
        document_number: () =>
          import(
            /* webpackChunkName: 'tas-card-number' */
            "@/../images/driver-licence/aus/tas-card-number.svg"
          ),
        name: () =>
          import(
            /* webpackChunkName: 'tas-name' */
            "@/../images/driver-licence/aus/tas-name.svg"
          ),
      },
      vic: {
        default: () =>
          import(
            /* webpackChunkName: 'vic-default' */
            "@/../images/driver-licence/aus/vic-default.svg"
          ),
        licence_number: () =>
          import(
            /* webpackChunkName: 'vic-licence-number' */
            "@/../images/driver-licence/aus/vic-licence-number.svg"
          ),
        document_number: () =>
          import(
            /* webpackChunkName: 'vic-card-number' */
            "@/../images/driver-licence/aus/vic-card-number.svg"
          ),
        name: () =>
          import(
            /* webpackChunkName: 'vic-name' */
            "@/../images/driver-licence/aus/vic-name.svg"
          ),
      },
      wa: {
        default: () =>
          import(
            /* webpackChunkName: 'wa-default' */
            "@/../images/driver-licence/aus/wa-default.svg"
          ),
        licence_number: () =>
          import(
            /* webpackChunkName: 'wa-licence-number' */
            "@/../images/driver-licence/aus/wa-licence-number.svg"
          ),
        document_number: () =>
          import(
            /* webpackChunkName: 'wa-card-number' */
            "@/../images/driver-licence/aus/wa-card-number.svg"
          ),
        name: () =>
          import(
            /* webpackChunkName: 'wa-name' */
            "@/../images/driver-licence/aus/wa-name.svg"
          ),
      },
    };
    const region = this.region.toLowerCase();
    return assets[region][this.variant] ?? assets[region].default;
  }

  async setElement() {
    const { default: svg } = await this.fetchAssets();
    this.element = svg;
  }

  @Watch("region", { immediate: true })
  @Watch("variant", { immediate: true })
  async updateSvg() {
    await this.setElement();
  }
}
