export function isEmpty(data: any): boolean {
  if (typeof data === "number" || typeof data === "boolean") {
    return false;
  }
  if (typeof data === "undefined" || data === null) {
    return true;
  }
  if (typeof data.length !== "undefined") {
    return data.length === 0;
  }

  return true;
}

export function checkStringLength(input: any, rangeMin?: number, rangeMax?: number): boolean {
  const min: number = rangeMin ?? 0;
  const max: number = rangeMax ?? min;
  if (typeof input !== "string") return rangeMin === 0;
  const checkIsEmpty = isEmpty(input);
  if (!checkIsEmpty) {
    const { length } = input;
    return length >= min && length <= max;
  }

  return false;
}

export function checkObjectIsEmpty(obj): boolean {
  return !Object.values(obj).some((x) => x !== null && x !== "");
}
