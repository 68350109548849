


















import { CheckSummary } from "@frankieone/shared";
import { Component, Prop, Vue } from "vue-property-decorator";
import {
  ArgumentFindViewExtra,
  findViewForCheckResult,
  mkViewFromViewOutcome,
} from "@/utils/checks";
import COLORS from "@/styles/_colors.sass";
import { type } from "../utils/configurationParser";

const mkCheckSummary = (d: {
  status?: string;
  alert?: string;
  issue?: any;
}) => {
  const { status = "failed", alert = "" } = d;
  return {
    alertList: alert ? [{ term: alert }] : [],
    status: { type: status },
    issues: {},
  } as CheckSummary;
};
@Component({})
export default class Outcome extends Vue {
  @Prop({ default: true }) skipLoopsWhenIdv: boolean;

  creditHeader = false;

  toggleIdv = false;

  async handleCheckOutcome(checkSummary: CheckSummary) {
    // eslint-disable-next-line no-param-reassign
    await this.$store.direct.dispatch.incrementAttemptCount();

    checkSummary.issues.creditHeader = this.creditHeader;
    const checked = this.$store.direct.getters.personalDetailsChecked;
    const hasAvailableDocumentsToReview =
      this.$store.direct.getters.hasAvailableDocsToReview;
    const isPersonalInfoReviewed = Object.values(checked).every(Boolean);
    const creditHeaderWarning = !!checkSummary?.issues?.creditHeader;

    const extra: ArgumentFindViewExtra = {
      isPersonalInfoReviewed,
      hasAvailableDocumentsToReview,
      attemptCount: this.attemptCount,
      maxAttemptCount: this.maxAttemptCount,
      externalIdv: this.externalIdv,
      isCheckingIDs: this.isCheckingIDs,
      isCheckingAddress: this.isCheckingAddress,
      personalInfoReviewed: checked,
      creditHeaderWarning,
    };

    const checkViewOutcome = findViewForCheckResult(checkSummary, extra);
    const view = mkViewFromViewOutcome(checkViewOutcome);
    if (window.Event) {
      const resultSlug = (() => {
        const resultView = checkViewOutcome.nextView ?? checkViewOutcome;
        return resultView.slug;
      })();
      const nextViewSlug = checkViewOutcome.slug;
      const outcome = new CustomEvent("FF_CHECK_RESULT", {
        detail: {
          checkSummary,
          resultSlug,
          nextViewSlug,
          applicant: this.applicantAndDocuments.applicant,
          documents: this.applicantAndDocuments.documents ?? [],
          allPersonalInformationReviewedByUser: isPersonalInfoReviewed,
          allDocumentsReviewedByUser: hasAvailableDocumentsToReview,
          attemptCount: this.attemptCount,
          maxAttemptCount: this.maxAttemptCount,
          externalIdv: this.externalIdv,
          isCheckingAddress: this.isCheckingAddress,
          isCheckingIDs: this.isCheckingIDs,
          nextView: checkViewOutcome,
        },
      });
      window.dispatchEvent(outcome);
    }

    this.addView(view);
    this.nextStep();
  }

  created() {
    if (this.externalIdv) {
      const pendingOption = this.outcomeOptions.find((opt) =>
        opt.label.match(/pending/i)
      )!;
      this.toggleIdv = true;
      this.handleCheckOutcome(pendingOption.value as CheckSummary);
    }
  }

  get applicantAndDocuments() {
    return this.$store.direct.getters.personalAndDocuments;
  }

  get isCheckingIDs(): boolean {
    return this.$store.direct.getters.config("requestID", type.bool);
  }

  get isCheckingAddress(): boolean {
    return this.$store.direct.getters.config("requestAddress", type.bool);
  }

  get outcomeOptions(): {
    value: CheckSummary;
    label: string;
    description: string;
  }[] {
    return [
      {
        label: "Success",
        value: mkCheckSummary({ status: "passed" }),
        description:
          "Presented to the user on successful verification of their identity.",
      },
      {
        label: "Review (Partial match found)",
        value: mkCheckSummary({ alert: "partial" }),
        description:
          "Some of the user's data has been verified. They are presented with the option to check their ID or try another ID.",
      },
      {
        label: "Review (No matches found)",
        value: mkCheckSummary({ alert: "404" }),
        description:
          "The user is prompted to check all of their personal information including their ID. If this fails, they are then prompted to add another ID document.",
      },
      {
        label: "Application Pending",
        value: mkCheckSummary({ status: "refer" }),
        description:
          "This screen is presented to a user who has successfully verified their identity, but the incoming application has been flagged as suspicious due to a PEP/Sanction or other check type which needs to be resolved in the Frankie portal.",
      },
      {
        label: "Too many tries",
        value: mkCheckSummary({ alert: "too-many" }),
        description:
          "This screen is presented once the user has reached the total number of allowed attempts, and their identity still can not be verified.",
      },
    ];
  }

  get externalIdv(): boolean {
    const { config } = this.$store.direct.getters;
    const hasIdScanVerification = config("idScanVerification", type.bool);
    return hasIdScanVerification;
  }

  get maxAttemptCount(): number {
    return this.$store.direct.getters.config("maxAttemptCount", type.number);
  }

  get attemptCount(): number {
    return this.$store.direct.state.checks.attemptCount;
  }

  addView(data: string | { routeName: string; routeData: any }) {
    this.$store.direct.dispatch.addView(data);
  }

  getIconForOutcomeLabel(label: string) {
    type Icon = {
      name: string;
      round: boolean;
      clickable: boolean;
      backgroundColor: string;
      size: number;
    };
    const defaultData: Icon = {
      name: "",
      round: true,
      clickable: false,
      backgroundColor: "",
      size: 24,
    };
    const config: [string, Partial<Icon>][] = [
      [
        "success",
        { name: "pass-status", backgroundColor: COLORS.COLOR__PASSED },
      ],
      [
        "partial",
        {
          name: "needs-attention-status",
          backgroundColor: COLORS.COLOR__WARN_HARD,
        },
      ],
      [
        "no matches",
        {
          name: "needs-attention-status",
          backgroundColor: COLORS.COLOR__WARN_HARD,
        },
      ],
      [
        "pending",
        {
          name: "needs-attention-status",
          backgroundColor: COLORS.COLOR__WARN_HARD,
        },
      ],
      [
        "too many",
        { name: "fail-status", backgroundColor: COLORS.COLOR__FAILED },
      ],
    ];
    const matchedEntry = config.find((entry) => {
      const [matcher] = entry;
      const match = label.match(new RegExp(matcher, "i"));
      return match;
    });
    if (!matchedEntry) return {};
    const icon = {
      ...defaultData,
      ...matchedEntry[1],
    };
    return icon;
  }

  nextStep() {
    return this.$store.direct.dispatch.nextStep();
  }
}
