import { feature } from '@/store/features';

export function isValidAustralianPassportNumber(str: string): boolean {
  /**
   * Min: 8, Max: 9 characters string
   * Either one or two alpha characters followed by seven numeric characters. No spaces.
   */
  if (str.length < 8 || str.length > 9) {
    return false;
  }
  const enhancedValidation = feature('enhancedValidation');
  if (!enhancedValidation) return true;

  const regex = /^[a-z]{1,2}\d{7}$/i;
  return regex.test(str);
}
