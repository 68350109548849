











import { Component, Vue } from "vue-property-decorator";
import OutcomeMessage from "@/components/OutcomeMessage.vue";
import ErrorIcon from "@/assets/svgs/Error.vue";

@Component({
  name: "Failure",
  components: {
    OutcomeMessage,
    ErrorIcon,
  },
})
export default class Failure extends Vue {
  get errorCode(): string {
    const { error } = this.$store.direct.getters;
    if (!error) return "";
    return error.message;
  }

  get errorText(): string {
    return (
      this.getPhrase(`errors.${this.errorCode}.text`) ||
      this.getPhrase("errors.default.text")
    );
  }

  get explanationText(): string {
    return (
      this.getPhrase(`errors.${this.errorCode}.explanation`) ||
      this.getPhrase("errors.default.explanation")
    );
  }

  get ctaText() {
    return (
      this.getPhrase(`errors.${this.errorCode}.cta`) ||
      this.getPhrase("errors.default.cta")
    );
  }

  get ctaUrl() {
    return (
      this.getPhrase(`errors.${this.errorCode}.url`) ||
      this.getPhrase("errors.default.url")
    );
  }

  redirect() {
    if (!this.ctaUrl) window.location.reload();
    window.location.href = this.ctaUrl;
  }

  async mounted() {
    await this.dispatchEvent({ eventName: "SCREEN:ERROR" });
  }
}
