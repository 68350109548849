import { Document, SupportingDocument, ChannelString} from "@frankieone/shared";
import { defineModule } from "direct-vuex";
import deepcopy from "deepcopy";
import { get } from "lodash";
import { Getter, Action } from "@/store/types";
import { moduleActionContext, moduleGetterContext } from "@/store";
import { type } from "@/utils/configurationParser";

const mkSessionActionContext = (original: any) =>
  moduleActionContext(original, mod);
const mkSessionGetterContext = (original: any) =>
  moduleGetterContext(original, mod);

export interface DocumentStoreModule {
  state: {
    documentsList: Document[];
    supportingDocuments: (SupportingDocument | null)[];
  };
  getters: {
    selectedDocuments: Getter<Document[]>;
    latestDocument: Getter<Document | null>;
    getDocumentOfType: Getter<
      (t: Document["idType"], specifier?: string) => Document | null
    >;
    hasAvailableDocsToReview: Getter<boolean>;
    acceptedDocumentTypes: Getter<Document["idType"][]>;
    supportingDocuments: Getter<SupportingDocument[]>;
    supportingDocumentUploadFields: Getter<DocumentUpload[]>;
  };
  actions: {
    addNewDocument: Action<Document | { document: Document; leading: boolean }>;
    updateDocument: Action<{ document: Document; index: number }>;
    resetDocuments: Action<void>;
    addSupportingDocument: Action<{
      document: SupportingDocument | null;
      index?: number;
    }>;
    removeSupportingDocument: Action<number>;
    initialiseSupportingDocumentsList: Action<number>;
  };
}
/**
 * STATE
 */

const initialState = {
  documentsList: [],
  supportingDocuments: [],
};

const moduleState: DocumentStoreModule["state"] = deepcopy(initialState);
const STATE: DocumentStoreModule["state"] = moduleState;
/**
 * GETTERS
 */
const GETTERS: DocumentStoreModule["getters"] = {
  selectedDocuments: (state) => state.documentsList,
  supportingDocuments: (state) => state.supportingDocuments,
  latestDocument: (state) => {
    const documents = state.documentsList;
    if (documents.length === 0) return null;
    const lastIndex = documents.length - 1;
    return documents[lastIndex];
  },
  // specifier can be of dot-notation as well
  getDocumentOfType: (state) => (theType, specifier?: string) => {
    const doc = state.documentsList.find((d) => d.idType === theType);
    if (doc && specifier && get(doc, specifier, null)) return doc;
    else if (doc && !specifier) return doc;
    return null;
  },
  hasAvailableDocsToReview: (...args) => {
    const { state, rootGetters } = mkSessionGetterContext(args);
    const documents = state.documentsList.filter((d) => Boolean(d.idType));
    const latestDocument = documents[documents.length - 1];
    const isLatestDocumentChecked = latestDocument?.verified?.manual;
    const allDocTypes = rootGetters.acceptedDocumentTypes;
    return documents.length < allDocTypes.length || !isLatestDocumentChecked;
  },
  acceptedDocumentTypes: (...args) => {
    const { rootGetters } = mkSessionGetterContext(args);
    const mapper = (
      val: Document["idType"] | UserDocConfig
    ): TSupportedDocuments | string => {
      if (typeof val === "string") return val as TSupportedDocuments;
      return (val as UserDocConfig).type;
    };
    const types = rootGetters.config(
      "documentTypes",
      type.array(mapper)
    ) as TSupportedDocuments[];

    return types as TSupportedDocuments[];
  },
  supportingDocumentUploadFields: (...args) => {
    const { rootGetters } = mkSessionGetterContext(args);
    const { uploads } = rootGetters.config("documentUploads") || {};
    return uploads || ([] as DocumentUpload[]);
  },
};
/**
 * ACTIONS
 */
const isDocument = (d: any): d is Document => d instanceof Document;
const ACTIONS: DocumentStoreModule["actions"] = {
  addNewDocument(context, options) {
    const { state } = mkSessionActionContext(context);
    const document = isDocument(options) ? options : options.document;
    const leading = isDocument(options) ? false : options.leading;
    const count = state.documentsList.length;
    if (typeof document.country !== "string") document.country = "AUS";
    if (leading) {
      state.documentsList.forEach((document) => {
        document.extraData["widget-index"] =
          (document.extraData["widget-index"] as number) + 1;
      });
    }
    const index = leading ? 0 : count;
    document.extraData["widget-index"] = index;
    state.documentsList.splice(index, 0, document);
  },
  updateDocument: (context, { document, index }) => {
    const { state } = mkSessionActionContext(context);
    if (typeof document.extraData["widget-index"] !== "number")
      document.extraData["widget-index"] = index;
    state.documentsList.splice(index, 1, document);
  },
  initialiseSupportingDocumentsList: (context, length) => {
    const { state } = mkSessionActionContext(context);
    state.supportingDocuments = new Array(length).fill(null);
  },
  addSupportingDocument: (context, options) => {
    /**
     * THIS METHOD REQUIRES SUPPORTINGDOCUMENTS to be initialised first!!
     */
    const { state } = mkSessionActionContext(context);
    const { supportingDocuments } = state;
    if (!supportingDocuments?.length)
      throw new Error("Initialise supportingDocuments first!");

    // for backwards compatibility, options might be "document" itself
    let document: SupportingDocument | null;
    let index: number | undefined;
    let currentTimestamp = new Date().toISOString()
    //if options has a document, we use this, else we use options itself as the document
    if (typeof options.document !== "undefined") {
      document = options.document;
      //documents can be null at this point so we check first then update modifed object
      if(document){
        document.modified = {
          channel: ChannelString.SMART_UI,
          userId: "",
          by: "Smart UI customer",
          timestamp: currentTimestamp
        };
      }
      index = options.index;
    } else {
      document = options as unknown as SupportingDocument;
    }
    // ensure all documents have an index in smartUiDocOrder first and use it as source of truth
    // index needs to either be passed as parameter, or in smartUiDocOrder
    // if index is passed as parameter, store/replace smartUiDocOrder with it
    const indexIsDefined = (
      index: number | null | undefined
    ): index is number => typeof index === "number";
    if (document && indexIsDefined(index)) document.smartUiDocOrder = index;
    // if document is defined and smartUiDocOrder is still not defined, fail
    if (document && !indexIsDefined(document.smartUiDocOrder))
      throw new Error(
        "addSupportingDocument: supporting document requires an index parameter"
      );
    // if document is null and index is not defined, fail
    if (!document && !indexIsDefined(index))
      throw new Error(
        "addSupportingDocument: explicit index parameter is required when assigning supporting document to 'null'"
      );
    // now indexes were asserted, capture it in a final variable "theIndex"
    const theIndex = document ? document.smartUiDocOrder! : index!;
    // if supportingDocument in the index is undefined, list wasn't initialised properly. Fail.
    if (typeof supportingDocuments[theIndex] === "undefined")
      throw new Error(
        `Supporting document in index ${theIndex} was never initialised`
      );
    // insert document | null into position
    state.supportingDocuments.splice(theIndex, 1, document);
  },
  removeSupportingDocument: (context, index: number) => {
    const { state } = mkSessionActionContext(context);
    const existingDocument = state.supportingDocuments[index];
    if (existingDocument) {
      existingDocument.file = null;
      state.supportingDocuments.splice(index, 1, existingDocument);
    }
  },
  resetDocuments({ state }) {
    const newState = deepcopy(initialState);
    Object.assign(state, newState);
  },
};

const mod = defineModule({
  state: STATE,
  getters: GETTERS,
  actions: ACTIONS,
} as DocumentStoreModule);
export default mod;
