






import { Component, Vue } from "vue-property-decorator";

@Component({
  name: "MedicareYellow",
})
export default class MedicareYellow extends Vue {}
