





















import { Component, Vue, Prop } from "vue-property-decorator";

type IconData = {
  name?: string;
  round?: boolean;
  backgroundColor?: string;
  color?: string;
};
@Component({
  components: {},
})
export default class OutcomeMessage extends Vue {
  @Prop() iconName: string;

  @Prop() icon: IconData;

  get iconAttrs(): IconData | false {
    const attrs = this.icon ?? {};
    const {
      name: attrName,
      round: attrRound,
      backgroundColor: attrBG,
      color: attrColor,
    } = attrs;
    const name = this.iconName ?? attrName;
    const round = attrRound ?? true;
    return (
      !!name && {
        name,
        round,
        backgroundColor: attrBG,
        color: attrColor,
      }
    );
  }

  click() {
    this.$emit("click");
  }
}
