






import { Component, Vue } from "vue-property-decorator";

@Component({
  name: "ThaiNationalIdBack",
})
export default class ThaiNationalIdBack extends Vue {}
