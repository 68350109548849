export const medicareOptions = [
  {
    value: "G",
    label: "Green",
    icon: "medicareGreen",
    bgColor: "#BCF2D1",
  },
  {
    value: "B",
    label: "Blue",
    icon: "medicareBlue",
    bgColor: "#CEE4FF",
  },
  {
    value: "Y",
    label: "Yellow",
    icon: "medicareYellow",
    bgColor: "#FFEFB8",
  },
];
