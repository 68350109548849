






import { Component, Vue } from "vue-property-decorator";

@Component({
  name: "ThaiNationalIdFront",
})
export default class ThaiNationalIdFront extends Vue {}
