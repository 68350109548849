






import { Component, Vue } from "vue-property-decorator";

@Component({
  name: "MedicareBlue",
})
export default class MedicareBlue extends Vue {}
