import { render, staticRenderFns } from "./PersonalForm.vue?vue&type=template&id=2256d0e9&lang=pug&"
import script from "./PersonalForm.vue?vue&type=script&lang=ts&"
export * from "./PersonalForm.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  ,true
)

export default component.exports