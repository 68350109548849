






import { Component, Vue } from "vue-property-decorator";

@Component({
  name: "ThaiNationalIdFrontName",
})
export default class ThaiNationalIdFrontName extends Vue {}
