import store from "@/store";
import { ConfigurationWrapper } from "@frankieone/shared";

const validate = (input: string, format: RegExp): boolean => format.test(input);

// The email address must contain at least one character before the @ symbol.
// The email address must contain at least one . character after the @ symbol.
// The characters before and after the . character must be alphanumeric characters, hyphens, or periods.
// The email should be able to support a plus sign in the email address  
const emailFormat: RegExp = /^(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;

// E.164 numbers are formatted [+] [country code] [subscriber number including area code].
// It can have a maximum of fifteen digits.
const phoneFormat: RegExp = /^\+[1-9]\d{1,14}$/;

export const initialisePreload = (
  configuration: ConfigurationWrapper<any>
): void => {
  const { email = null, phone = null } = configuration.get("preload").v();

  const hasEmail: boolean = email !== null;
  const isValidEmail: boolean = validate(email ? email.toLowerCase() : email, emailFormat);
  if (hasEmail && isValidEmail) {
    store.state.personal.applicant.email = {
      documentId: null,
      idNumber: email,
    };
  }
  if (hasEmail && !isValidEmail) {
    console.warn(`Invalid email is received: ${JSON.stringify(email)}`);
  }

  const hasPhone: boolean = phone !== null;
  const isValidPhone: boolean = validate(phone, phoneFormat);
  if (hasPhone && isValidPhone) {
    store.state.personal.applicant.phoneNumber = {
      documentId: null,
      idNumber: phone,
    };
  }
  if (hasPhone && !isValidPhone) {
    console.warn(`Invalid phone number is received: ${JSON.stringify(phone)}`);
  }
};
