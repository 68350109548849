






import { Component, Vue } from "vue-property-decorator";

@Component({
  name: "SingaporeNationalIdName",
})
export default class SingaporeNationalIdName extends Vue {}
