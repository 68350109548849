import { Context } from "./manual-blueprint";
import { mkGenericAddressBlueprint } from "./generic-split-blueprint";

export function mkSGPAddressBlueprint(context: Context) {
  const genericBlueprint = mkGenericAddressBlueprint(context);

  genericBlueprint.removeChild("town");
  genericBlueprint.removeChild("suburb");
  genericBlueprint.removeChild("state");

  return genericBlueprint;
}
