const addScript = (src: string): Promise<void> => {
  return new Promise((resolve, reject) => {
    if (!document) reject();
    const s = document.createElement("script");
    s.setAttribute("src", src);
    s.onload = () => {
      resolve();
    };
    document.body.appendChild(s);
  });
};

export default addScript;
