



















































/* eslint-disable no-lone-blocks */
import ClickOutside from 'vue-click-outside';
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Applicant, Document, BottomDrawer, SupportingDocument } from '@frankieone/shared';
import Loading from '@/assets/svgs/Loading.vue';
// import BottomDrawer from '../components/BottomDrawer.vue';
import ReviewInput from '../components/inputs/ReviewInput.vue';
import ConsentInput from '../components/inputs/ConsentInput.vue';

@Component({
  components: {
    ReviewInput,
    ConsentInput,
    Loading,
    BottomDrawer,
  },
  directives: { ClickOutside },
})
export default class SummaryForm extends Vue {
  @Prop() consentGiven: boolean;

  @Prop() applicant: Applicant;

  @Prop() documents: Document[];

  @Prop() supportingDocuments: SupportingDocument[];

  @Prop() supportingDocumentUploadFields: DocumentUpload[];

  @Prop() dobType: TDOBTypeSupport;

  @Prop() acceptedDocTypes: { type: Document['idType'] }[];

  @Prop() acceptedAgeRange: [number, number];

  @Prop() acceptedCountries: string[];

  @Prop() displayMiddleNameValue: string | null;

  @Prop({ required: false, default: () => ['first', 'middle', 'last'] })
  fieldNames: FieldName[];

  @Prop() consentText: string;

  @Prop() hasMedicareDoc: boolean;

  @Prop() isLoading: boolean;

  isEditMode = false;

  isConsentPopupOpen = false;

  validationState = true;

  closeConsentPopup() {
    this.isConsentPopupOpen = false;
  }

  openConsentPopup() {
    this.isConsentPopupOpen = true;
  }

  setIsEditMode(v: boolean) {
    this.isEditMode = v;
    this.$emit('update:isEditMode', v);
  }

  updateMedicareMiddleName($event) {
    this.$emit('update:middleNameValue', $event);
  }

  updateApplicant(applicant: Applicant) {
    this.$emit('update:applicant', applicant);
  }

  updateDocuments(documents: Document[]) {
    this.$emit('update:documents', documents);
  }

  updateSupportingDocuments(supportingDocuments: SupportingDocument[]) {
    this.$emit('update:supporting_documents', supportingDocuments);
  }

  updateValidationState(validationState: boolean) {
    this.validationState = validationState;
  }

  get display() {
    const fields: string[] = [];
    fields.push('ApplicantReviewInput');
    fields.push('DocumentReviewInput');
    if (this.supportingDocumentUploadFields?.length) {
      fields.push('DocumentUploadsReview');
    }
    return fields;
  }

  get buttonText() {
    if (!this.isConsentPopupOpen) return this.getPhrase('common.next_button_cta');
    const namespace = 'review_details_screen';
    const key = this.isLoading ? 'submit_button_idle' : 'submit_button_cta';
    return this.getPhrase([namespace, key].join('.'));
  }
  get documentNumberConfig(): string[] {
    const statesThatRequireDocNumber = this.$feature('statesThatRequireDocNumber') as unknown as string[];
    return statesThatRequireDocNumber;
  }
  get missingElements() {
    //Drivers Licence Document Number
    return this.documents?.some((document) => {
      return (
        document.idType === 'DRIVERS_LICENCE' &&
        this.documentNumberConfig.includes(document.region!) &&
        !document.extraData.document_number
      );
    })
      ? [
          this.getPhrase('error_label.missing').replace(
            '{fieldName}',
            this.getPhrase('document.type_drivers_licence.card_number'),
          ),
        ]
      : [];
  }

  get isButtonEnabled(): boolean {
    // Button is enabled if:
    // 1) consent is given / consent popup is open
    // since this button is supposed to trigger the consent popup, we want to make sure that the button is enabled when popup is not open
    const isConsentGiven = this.consentGiven || !this.isConsentPopupOpen;
    // 2) data is valid / no missing data. TODO: add logic for missingElements into the logic for validationState
    const isValid = this.missingElements.length === 0 && this.validationState;
    // 3) we're not currently loading / idle
    const isNotIdle = !this.isLoading;

    return isConsentGiven && isValid && isNotIdle;
  }
}
