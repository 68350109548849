










import { Component, Vue } from "vue-property-decorator";
import { Browser } from "../assets/svgs";

@Component({
  components: { Browser },
})
export default class Unauthorized extends Vue {
  get size() {
    const { isDesktop, isTablet } = this;
    if (isDesktop || isTablet) {
      return {
        width: 240,
        height: 205,
      };
    }
    return {
      width: 170,
      height: 145,
    };
  }
}
