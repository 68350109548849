import { IStringOrNull, Address } from "@frankieone/shared";
import countries from "@/data/country.json";

export function getFormattedCountryCode(
  value: IStringOrNull,
  inputFormat: string,
  outputFormat: string
): string {
  const filteredCountries = countries.filter(
    (country) => country[inputFormat] === value
  );

  return filteredCountries[0]?.[outputFormat] || "";
}
export function mkAddress(type: Address["addressType"]) {
  const address = new Address();
  address.addressType = type;
  address.country = null; // changed this from AUS to null to allow for internationalisation with less hacky solutions
  return address;
}
