
































import { Component, Vue } from "vue-property-decorator";
import { type } from "../utils/configurationParser";

@Component({
  components: {},
})
export default class Welcome extends Vue {
  // method
  get configuredContent(): string | boolean {
    const welcomeScreen = this.$store.direct.getters.config(
      "welcomeScreen",
      type.nBool
    );
    const htmlContent = this.$store.direct.getters.config(
      "welcomeScreen.htmlContent",
      type.nStrBool
    );
    if (welcomeScreen === false || htmlContent === false) return false;
    if (typeof htmlContent === "string") return htmlContent;

    return true;
  }

  get minAge(): number {
    const [min] = this.$store.direct.getters.config(
      "ageRange",
      type.array(type.number)
    );
    return min;
  }

  async mounted() {
    await this.dispatchEvent({ eventName: "SCREEN:WELCOME" });
  }

  get ctaText(): string {
    const defaultLabel = "Start Identity Verification";
    const configured = this.$store.direct.getters.config(
      "welcomeScreen.ctaText",
      type.nStrBool
    );
    if (configured === true) return defaultLabel;
    if (configured === false) return defaultLabel;
    if (configured === null) return defaultLabel;
    return configured;
  }

  nextClicked() {
    return this.$store.direct.dispatch.nextStep();
  }
}
