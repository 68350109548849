






import { Component, Vue } from "vue-property-decorator";

@Component({
  name: "ThaiNationalIdFrontEnglishName",
})
export default class ThaiNationalIdFrontEnglishName extends Vue {}
