









import { Component, Vue } from "vue-property-decorator";
import OutcomeMessage from "@/components/OutcomeMessage.vue";
import { type } from "../utils/configurationParser";

@Component({
  name: "CreditHeaderSuccess",
  components: {
    OutcomeMessage,
  },
})
export default class CreditHeaderSuccess extends Vue {
  get ctaText(): string {
    const configured = this.$store.direct.getters.config(
      "successScreen.ctaText",
      type.string
    );
    return configured;
  }

  get ctaUrl(): string | null {
    const configured = this.$store.direct.getters.config(
      "successScreen.ctaUrl",
      type.nString
    );
    return configured;
  }

  nextStep() {
    if (this.ctaText && this.ctaUrl) {
      window.location.href = this.ctaUrl;
    }
  }
}
