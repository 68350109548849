import {
  breakDateToObject,
  DateObject,
  transformDateToGregorian,
} from "@frankieone/shared";
import moment from "moment";

export function isValidDateOfBirth(
  str: string,
  calendar: TDOBTypeSupport
): boolean {
  if (calendar === "gregorian") {
    const obj = moment(str);
    return obj.isValid();
  }
  if (calendar === "buddhist") {
    const greg = transformDateToGregorian(str, "buddhist");
    return isValidDateOfBirth(greg, "gregorian");
  }
  return false;
}

export function checkDateExpiry(
  type: string,
  value: string | null,
  options: { iso?: boolean } = {}
): boolean {
  const { iso = false } = options;

  let format = "";

  switch (type) {
    case "year":
      format = "YYYY";
      break;
    case "year-month":
      format = iso ? "YYYY-MM-01" : "MM/YYYY";
      break;
    default:
      format = iso ? "YYYY-MM-DD" : "DD/MM/YYYY";
      break;
  }

  return (
    moment(value, format, true).isValid() &&
    moment(value, format, true).isAfter()
  );
}

export function getCurrentAgeFromDate(date: string): number {
  const toDate = moment(date);
  const now = moment();

  return now.diff(toDate, "years");
}

export function getFormattedDate(
  date: string,
  calendar: "buddhist" | "gregorian",
  format = "Do MMMM YYYY"
): string {
  if (calendar === "gregorian") return formatGregorianDate(date, format);
  if (calendar === "buddhist") return formatBuddhistDate(date, format);
  return "";
}
const formatBuddhistDate = (date: string, format: string): string => {
  if (!isValidDateOfBirth(date, "buddhist")) return "";

  const dateObject = breakDateToObject(date);

  const getNonZero = (f: keyof DateObject) =>
    dateObject[f] === "00" ? null : dateObject[f];
  const fields = [
    getNonZero("dd"),
    getNonZero("mm"),
    getNonZero("yyyy"),
  ].filter(Boolean) as string[];

  if (fields.length === 3) {
    const gregorianStr = transformDateToGregorian(date, "buddhist");
    const { yyyy: gregorianYear } = breakDateToObject(gregorianStr) as {
      yyyy: string;
    };
    return formatGregorianDate(gregorianStr, format).replace(
      gregorianYear,
      fields[2]
    );
  }

  return fields[0];
};
const formatGregorianDate = (date: string, format: string): string => {
  if (isValidDateOfBirth(date, "gregorian")) return moment(date).format(format);
  return "";
};
