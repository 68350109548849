




































import {
  FileUploadClient,
  ISupportingDocument,
  DocumentType,
  Scan,
  SupportingDocument,
} from "@frankieone/shared";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import FileUpload from "@/components/inputs/FileUpload.vue";

@Component({
  name: "DocumentUploadsForm",
  components: {
    FileUpload,
  },
})
export default class DocumentUploadsForm extends Vue {
  @Prop({ required: true }) supportingDocumentUploadFields: DocumentUpload[];

  @Prop() supportingDocuments: (SupportingDocument | null)[];

  @Prop() isEdit: boolean;

  errorMessage: string;

  isUploadComplete(doc: SupportingDocument | null) {
    return !!doc && !!doc.file && !!doc.type;
  }

  isCompleteUpTo(index: number) {
    if (index < 0) return true;
    if (index >= this.supportingDocuments.length) return false;

    const slice = this.supportingDocuments.slice(0, index + 1);
    return slice.every(this.isUploadComplete);
  }

  get complete() {
    const hasType = (d: SupportingDocument | null) => d && d.type;
    const hasUploadedFile = (d: SupportingDocument | null) =>
      d && d.file?.fileUploadUuid;
    const hasExistingFile = (d: SupportingDocument | null) =>
      d && d.file?.scanId;
    const hasUploadedOrExistingFile = (d: SupportingDocument | null) =>
      hasUploadedFile(d) || hasExistingFile(d);
    return this.supportingDocuments.every(
      (d) => hasType(d) && hasUploadedOrExistingFile(d)
    );
  }

  @Watch("complete", { immediate: true })
  sendComplete() {
    this.$emit("isAllFilled", this.complete);
  }

  get fileUploadClient(): FileUploadClient {
    return this.$store.direct.getters.frankie!.getFileUploadClient();
  }

  get isButtonShown(): boolean {
    return !this.isEdit;
  }

  setDocumentTypeValue(type, index) {
    const configuration = this.supportingDocumentUploadFields[index];
    const typeConfig = (
      configuration.types as TDocumentUploadTypeObject[]
    ).find((typeConfig) => typeConfig.type === type);
    const originalDocumentObject = this.supportingDocuments[index];
    const payload: ISupportingDocument = {
      type,
      label: typeConfig?.label ?? "",
      smartUiDocOrder: index,
      file: originalDocumentObject?.file ?? null,
    };
    const document = originalDocumentObject
      ? Object.assign(originalDocumentObject, payload)
      : new SupportingDocument(payload);

    this.$emit("supportingDocumentAdded", document);
    this.sendComplete();
  }

  handleRemoveFileUpload(idx) {
    this.$emit("supportingDocumentRemoved", idx);
    this.$nextTick(() => this.sendComplete());
  }

  handleFileUpload(file: Scan, index: number) {
    this.errorMessage = "";
    // dummy payload since constructor requires it
    const payload: ISupportingDocument = {
      type: "" as DocumentType,
      label: "",
      smartUiDocOrder: index,
      file,
    };
    const originalDocumentObject = this.supportingDocuments.find(
      (doc) => doc?.smartUiDocOrder === index
    );
    const document = originalDocumentObject ?? new SupportingDocument(payload);

    this.$set(document, "file", file);
    document.smartUiDocOrder = index;
    this.$emit("supportingDocumentAdded", document);
    this.sendComplete();
  }

  emitSubmit() {
    this.$emit("submit");
  }

  convertLabel(label: string): string {
    return label[0] + label.slice(1).toLowerCase().replaceAll("_", " ");
  }

  findDocumentType(idx: number) {
    return this.supportingDocuments[idx]?.type;
  }

  getOptions(field: { types: any[] }) {
    return field.types.map((type: any) => ({
      label: type.label,
      value: type.type,
    }));
  }
}
